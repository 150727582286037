import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Spinner from 'react-bootstrap/Spinner';


const Success = () => {

    const api_url = process.env.REACT_APP_API_URL;


    const { id } = useParams();
    const [data, setData] = useState([]);
    // const [pending, setPending] = useState(false);

    const getData = () => {
        axios.get(`${api_url}/getSuccessData/${id}`).then((response) => {
            setData(response.data.data)
            // setPending(true);
        }).catch((err) => {
            // setPending(true);
            console.log(err)
        });

    }

    // (function () {
    //     if (window.localStorage) {
    //         if (!localStorage.getItem('firstLoad')) {
    //             localStorage['firstLoad'] = true;
    //             window.location.reload();
    //         }
    //         else
    //             localStorage.removeItem('firstLoad');
    //     }
    // })();

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.localStorage) {
            if (!localStorage.getItem('firstLoad')) {
                localStorage['firstLoad'] = true;
                window.location.reload();
            }
            else
                localStorage.removeItem('firstLoad');
        }

    }, []);






    // function createData(name, value) {
    //     return { name, value};
    // }

    // const rows = [
    //     createData('Frozen yoghurt', 159),

    // ];


    // getSuccessData/1

    return (
        <>

            {/* {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>} */}

            <section className="checkout-area" style={{ paddingBottom: '45px' }}>
                <div className="container">
                    <div className="success-animation text-center">
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>

                    <div className='row m-0'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <h6>Personal Details</h6>
                            <TableContainer >
                                <Table aria-label="simple table" className='table-borderless' >
                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">First Name</TableCell>
                                            <TableCell align="left">{data[0]?.first_name}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">Last Name</TableCell>
                                            <TableCell align="left">{data[0]?.last_name}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">Email</TableCell>
                                            <TableCell align="left">{data[0]?.email}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">Phone</TableCell>
                                            <TableCell align="left">{data[0]?.phone}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">Country</TableCell>
                                            <TableCell align="left">{data[0]?.country ? data[0].country : "-"}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">State</TableCell>
                                            <TableCell align="left">{data[0]?.state}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">City</TableCell>
                                            <TableCell align="left">{data[0]?.city}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">Address</TableCell>
                                            <TableCell align="left">{data[0]?.address}</TableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="left">Zipcode</TableCell>
                                            <TableCell align="left">{data[0]?.zipcode}</TableCell>
                                        </TableRow>


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='d-flex flex-column'>
                                <div className='pb-5'>
                                    <h6>Product Details</h6>
                                    <TableContainer >
                                        <Table aria-label="simple table" className='table-borderless' >
                                            <TableBody>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" align="left">Product</TableCell>
                                                    <TableCell align="left">{data[0]?.product_id}</TableCell>
                                                </TableRow>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" align="left">Booking Id</TableCell>
                                                    <TableCell align="left">{id}</TableCell>
                                                </TableRow>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" align="left">Order Status</TableCell>
                                                    <TableCell align="left">{data[0]?.order_status == 0 ? "Pending" : data[0]?.order_status == 1 ? "cancelled" : "Completed"}</TableCell>
                                                </TableRow>
                                                {/* order_status */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div>
                                    <h6>Payment details</h6>
                                    <TableContainer >
                                        <Table aria-label="simple table" className='table-borderless' >
                                            <TableBody>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" align="left">Price</TableCell>
                                                    <TableCell align="left">{data[0]?.total_price}</TableCell>
                                                </TableRow>
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {data[0]?.transaction_id ? <>
                                                        <TableCell component="th" scope="row" align="left">Transaction Id</TableCell>
                                                        <TableCell align="left">{data[0]?.transaction_id}</TableCell> </> : null}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </section >

        </>
    )
}

export default Success