import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'
import { BiLoaderCircle } from 'react-icons/bi';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import HTMLRenderer from 'react-html-renderer'
import { Helmet } from "react-helmet";



const Therapies = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [therapies, setTherapies] = useState([]);
    const [heroData, setHeroData] = useState([]);
    const [banner, setBanner] = useState('');
    const [pending, setPending] = useState(false)
    const [dataPerPage, setDataPerPage] = useState(6);
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');

    const getData = () => {

        axios.get(`${api_url}/getBanner/5`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].image)
            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });


        axios.get(`${api_url}/getseo/4`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })


        axios.get(`${api_url}/getTherapies`).then((response) => {
            setTherapies(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);


    // console.log(dataPerPage)

    return (
        <>


            <Helmet>
                <link rel="canonical" href={canonical} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>


            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}


            <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(/assets/banner/${banner}` }}>
                <div className="container">
                    <div className="page-banner-content-two">
                        <h1>Therapies</h1>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Therapies</li>
                        </ul>
                    </div>
                    {/* <div className="page-banner-content">
                            <h2>Therapies</h2>
                            <ul>
                                <li>
                                <Link to="/">Home</Link>
                                </li>
                                <li>
                                <Link to="/therapies">Therapies</Link>
                                </li>
                                <li>Therapies</li>
                            </ul>
                        </div> */}
                </div>
            </div>

            <section className="success-story-area ptb-100">
                <div className="container">
                    <div className="success-story-image">
                        {therapies?.filter(data => data.id == 1).map((therapies) => {
                            return (
                                <video src={`assets/video/${therapies.youtube_url}`} controls width="100%" height="auto" alt="image" style={{ display: "block", visibility: "visible" }} />
                            )
                        })}
                    </div>

                    <section className="blog-area pt-100 pb-100">
                        <div className="container">
                            <div className="row">
                                {therapies?.slice(0, dataPerPage).map((therapies) => {

                                    return (
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-blog-item">
                                                <div className="blog-image">
                                                    <Link to={`/therapies/${therapies.id}/${therapies.title.replace(/\s/g, '').toLowerCase()}`}><img src={`assets/therapies/${therapies.image}`} alt="image" style={{ display: "block", visibility: "visible" }} /></Link>
                                                </div>

                                                <div className="">
                                                    <h3>
                                                        <Link to={`/therapies/${therapies.id}`}>{therapies.title}</Link>
                                                    </h3>
                                                    <p>
                                                        <HTMLRenderer html={therapies.content.substring(0, 180)} />...</p>
                                                    <div className="blog-btn">
                                                        <Link to={`/therapies/${therapies.id}/${therapies.title.replace(/\s/g, '').toLowerCase()}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                            {therapies?.length > dataPerPage ?
                                <div className="book-store-btn">
                                    <Button onClick={() => setDataPerPage(dataPerPage + 3)} className="default-btn">Load More <i><BiLoaderCircle /></i></Button>
                                </div> : null}
                        </div>
                    </section>

                </div>
            </section>

        </>
    )
}

export default Therapies