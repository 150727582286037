import React, { useState, useRef, useEffect } from 'react';

import axios from 'axios';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SignatureCanvas from 'react-signature-canvas'
import logo from '../assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Paypal from '../common/payment/Paypal';


const ConfidentialForm = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [banner, setBanner] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [homephone, setHomePhone] = useState('');
    const [workphone, setWorkPhone] = useState('');
    const [mobphone, setMobPhone] = useState('');
    const [commonconcern, setCommonConcern] = useState([{ value: 'Anxiety/Stress' }]);
    const [dob, setDob] = useState('');
    const [date, setDate] = useState('');
    const [address, setAddress] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [marital, setMarital] = useState('');
    const [contact, setContact] = useState('');
    const [occupation, setOccupation] = useState('');
    const [visitAddress, setVisitAddress] = useState('');
    const [room, setRoom] = useState('');
    const [change, setChange] = useState('');
    const [client, setClient] = useState('');
    const [hyponsis, setHyponsis] = useState('');
    const [help, setHelp] = useState('');
    const [dateLast, setDateLast] = useState('');
    const [client_signature, setclient_signature] = useState('');
    const [pending, setPending] = useState(true);
    const [isPay, setIsPay] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('');
    const [client_Id, setClient_Id] = useState('');


    const formSchema = yup.object().shape({
        name: yup.string().required(),
        date: yup.string().required(),
        DOB: yup.string().required(),
        age: yup.string().required(),
        gender: yup.string().required(),
        marital: yup.string().required(),
        home_number: yup.string().required(),
        address: yup.string().min(10).required(),
        work_number: yup.string().min(10).required(),
        mob_number: yup.string().min(10).required(),
        email: yup.string().email().required(),
        Contact: yup.string().min(10).required(),
        occupation: yup.string().required(),
        visitaddress: yup.string().required(),
        commonConcerns: yup.array().min(1).of(yup.string().required()).required(),
        room: yup.string().required(),
        change: yup.string().required(),
        client: yup.string().required(),
        hyponsis: yup.string().required(),
        help: yup.string().required(),
        check: yup
            .bool()
            .oneOf([true], 'You need to accept the terms and conditions'),
        date_last: yup.string().required(),
        signature: yup
            .string()
            .required()
            .matches(
                /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
                "Signature must be png"
            ),
    });

    const sigRef = useRef();
    const handleSignatureEnd = () => {
        if (sigRef.current) {
            const dataURL = sigRef.current.toDataURL();
            setclient_signature(dataURL);
            return dataURL;
        }

    };

    

    const clear = () => {
        sigRef.current.clear();
        reset({
            signature: ''
        })
    }


    const getData = () => {
        axios.get(`${api_url}/getamountsetting`).then((response) => {

            setAmount(response.data.data[0].minimun_amt)
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

        axios.get(`${api_url}/getAdminSetting1`).then((response) => {
            
            setClient_Id(response.data.data[0].paypal_client)
          }).catch((err) => {
           
            console.log(err);
          })
    }

    useEffect(() => {
        getData();
    }, []);



    let commonConcerns = [
        'Anxiety/Stress', 'General Fears', 'Smoking', 'Insomnia', 'Fear of Public Speaking', 'Sports Performance', 'Chronic Pain', 'Lack of Motivation'
        , 'Alcohol/Drug Use', 'Depression', 'Low Self Esteem', 'Test Anxiety', 'Weight Issues', 'Phobic Reactions', 'Unwanted Habits', 'Surgical Anxiety',
        'Relationship Issues', 'Goal Setting'
    ]

    const HandleConcorn = (e) => {

        let checkedData = [...commonconcern];

        if (e.checked) {
            checkedData = [...commonconcern, { value: e.value }]

        } else {
            let Index = commonconcern.findIndex(data => data.value == e.value)
            checkedData.splice(Index, 1)
        }

        let myData = checkedData
        setCommonConcern(myData);


    }
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors } = {},
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(formSchema),
    });


    const notify = () => toast.success("Form Submit SuccessFully", { position: "top-center" });

    const verified = () => {
        setIsPay(true);
    }

    const onSubmit = (tnx_id, amount) => {
        setPending(true);
        axios.post(`${api_url}/confidentials`, {
            name: name,
            address: address,
            date: date,
            dob: dob,
            age: age,
            gender: gender,
            marital_status: marital,
            home_phone: homephone,
            work_phone: workphone,
            mobile_phone: mobphone,
            mode_of_contact: contact,
            occuption: occupation,
            primary_reason: visitAddress,
            common_concerns: commonconcern?.map(data => data.value).toString(),
            hypnotherapy_rooms: room,
            change_msg: change,
            client_name: client,
            hypnotist_agreement: hyponsis,
            help: help,
            date2: dateLast,
            client_signature: client_signature,
            email: email,
            tnx_id: tnx_id,
            amount: amount,
        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setIsPay(false);
                setPending(false);
                setAmount('')
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    return (
        <>
            {pending ?
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div> : null}
            <article>
                <div className='title text-center'>
                    <img style={{ borderRadius: "50%" }} src={'https://tphr.giwdevelopment.com/assets/logo/logo.png'} width={100} height={99} alt="image" />
                    <h1 className='text-center pb-5'>THE PATHWAY HYPNOTHERAPY ROOMS</h1>
                </div>
                <ToastContainer />
                <div className="copyright-area mb-5" style={{ backgroundColor: 'rgb(32 37 41)' }}>
                    <div className="container">
                        <div className="copyright-area-content">
                            <h1 className='heading text-white'>Confidential</h1>
                        </div>
                    </div>
                </div>

                <div className='content-d pb-4'>
                    <h2 className='heading'>Client Information</h2>
                </div>

                <form id="contactForm" className='row m-0'
                    onSubmit={handleSubmit(verified)}
                >
                    <div className="form-group col-6">
                        <label>Name</label>
                        <input type="text" id="name" className="form-control" placeholder="Name"


                            {...register('name', {
                                required: true,
                                onChange: (e) => setName(e.target.value)

                            })}
                        />
                        {errors.name && errors.name.type && <div className="help-block with-errors">Please Enter your Name</div>}

                    </div>


                    <div className="form-group col-6">
                        <label>Date</label>
                        <input type="date" id="date" className="form-control" placeholder="Date"


                            {...register('date', {
                                required: true,
                                onChange: (e) => setDate(e.target.value)

                            })}
                        />
                        {errors.date && errors.date.type && <div className="help-block with-errors">Please Enter Date</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>Address</label>
                        <textarea className="form-control" id="address" cols="30" rows="2" placeholder="Address"


                            {...register('address', {
                                required: true,
                                onChange: (e) => setAddress(e.target.value)
                            })}
                        ></textarea>
                        {errors.address && errors.address.type && <div className="help-block with-errors">Please Enter Address</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>DOB</label>
                        <input type="date" id="DOB" className="form-control" placeholder="DOB"


                            {...register('DOB', {
                                required: true,
                                onChange: (e) => setDob(e.target.value)
                            })}
                        />
                        {errors.DOB && errors.DOB.type && <div className="help-block with-errors">Please Enter DOB</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Age</label>
                        <input type="number" id="age" className="form-control" placeholder="age"
                            {...register('age', {
                                required: true,
                                onChange: (e) => setAge(e.target.value)
                            })} />
                        {errors.age && errors.age.type && <div className="help-block with-errors">Please Enter Age</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Gender</label>
                        <Form.Select aria-label="Default select example" className='form-control'
                            {...register('gender', {
                                required: true,
                                onChange: (e) => setGender(e.target.value)
                            })}>
                            <option value=''>Gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="3">Other</option>
                        </Form.Select>
                        {errors.gender && errors.gender.type && <div className="help-block with-errors">Please Select Gender Type</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Marital Status</label>
                        <Form.Select aria-label="Default select example" className='form-control'
                            {...register('marital', {
                                required: true,
                                onChange: (e) => setMarital(e.target.value)
                            })}>
                            <option value=''>Marital Status</option>
                            <option value="1">Single</option>
                            <option value="2">Married</option>
                            <option value="3">Widowed</option>
                            <option value="4">Divorced</option>

                        </Form.Select>
                        {errors.marital && errors.marital.type && <div className="help-block with-errors">Please Marital Status</div>}
                    </div>

                    <div className="form-group col-4">
                        <label>Home Phone</label>
                        <input type="text" id="home_number" className="form-control" placeholder="Phone"


                            {...register('home_number', {
                                required: true,
                                onChange: (e) => setHomePhone(e.target.value)
                            })}
                        />
                        {errors.home_number && errors.home_number.type && <div className="help-block with-errors">Please Enter Home Phone Number</div>}
                    </div>

                    <div className="form-group col-4">
                        <label>Work Phone</label>
                        <input type="text" id="work_number" className="form-control" placeholder="Phone"


                            {...register('work_number', {
                                required: true,
                                onChange: (e) => setWorkPhone(e.target.value)
                            })}
                        />
                        {errors.work_number && errors.work_number.type && <div className="help-block with-errors">Please Enter Work Phone Number</div>}
                    </div>

                    <div className="form-group col-4">
                        <label>Mobile Phone</label>
                        <input type="text" id="mob_number" className="form-control" placeholder="Phone"


                            {...register('mob_number', {
                                required: true,
                                onChange: (e) => setMobPhone(e.target.value)
                            })}
                        />
                        {errors.mob_number && errors.mob_number.type && <div className="help-block with-errors">Please Enter Mobile Phone Number</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Email</label>
                        <input type="email" id="email" className="form-control" placeholder="Email"

                            {...register('email', {
                                required: true,
                                onChange: (e) => setEmail(e.target.value)
                            })}
                        />
                        {errors.email && errors.email.type && <div className="help-block with-errors">Please Enter your Email</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Preferred Mode of Contact</label>
                        <input type="text" id="Contact" className="form-control" placeholder="Contact"

                            {...register('Contact', {
                                required: true,
                                onChange: (e) => setContact(e.target.value)
                            })}
                        />
                        {errors.Contact && errors.Contact.type && <div className="help-block with-errors">Please Enter Mode of Contact</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>Occupation</label>
                        <textarea className="form-control" id="occupation" cols="30" rows="2" placeholder="Occupation"


                            {...register('occupation', {
                                required: true,
                                onChange: (e) => setOccupation(e.target.value)
                            })}
                        ></textarea>
                        {errors.occupation && errors.occupation.type && <div className="help-block with-errors">Please Enter Occupation</div>}
                    </div>


                    <div className="form-group col-12">
                        <label>What is your primary reason or desired outcome for today’s visit?</label>
                        <textarea className="form-control" id="address" cols="30" rows="2" placeholder="Primary reason"


                            {...register('visitaddress', {
                                required: true,
                                onChange: (e) => setVisitAddress(e.target.value)
                            })}
                        ></textarea>
                        {errors.visitaddress && errors.visitaddress.type && <div className="help-block with-errors">Please Enter Today's Visit</div>}
                    </div>

                    <div className='heading pt-4'>
                        <h2>Below is a list of common concerns that lead people to seek professional assistance. Please check all that apply to you.</h2>
                    </div>


                    {commonConcerns?.map((data) => {
                        return (
                            <div key={data} className='col-3'>
                                <FormControlLabel
                                    value={data}
                                    // id={data}
                                    control={<Checkbox className='text-dark' />}
                                    label={data}
                                    labelPlacement='end'
                                    // onChange={(e) => HandleConcorn(e.target)}
                                    {...register('commonConcerns', {
                                        required: true,
                                        onChange: (e) => HandleConcorn(e.target)
                                    })}

                                />

                            </div>
                        )
                    })}

                    {errors.commonConcerns && <div className="help-block with-errors">Please select common Concerns</div>}

                    <div className="form-group col-12">
                        <label>How did you learn of The Pathway Hypnotherapy Rooms?</label>
                        <textarea className="form-control" id="room" cols="30" rows="2" placeholder="learn of The Pathway Hypnotherapy Rooms"

                            {...register('room', {
                                required: true,
                                onChange: (e) => setRoom(e.target.value)
                            })}
                        ></textarea>
                        {errors.room && errors.room.type && <div className="help-block with-errors">Please Enter what do you learn form the pathway hypnotherapy</div>}
                    </div>


                    <div className="form-group col-12">
                        <label>What do you want to change?</label>
                        <textarea className="form-control" id="chnage" cols="30" rows="5" placeholder="What do you want to change?"

                            {...register('change', {
                                required: true,
                                onChange: (e) => setChange(e.target.value)
                            })}
                        ></textarea>
                        {errors.change && errors.change.type && <div className="help-block with-errors">Please Enter what Do You change</div>}
                    </div>


                    <div className='heading pt-4'>
                        <h2>Please ensure that you take the time to read this contract carefully before signing.</h2>
                    </div>

                    <div className="form-group col-12">
                        <label>Name of client</label>
                        <input type="text" id="client" className="form-control" placeholder="name"

                            {...register('client', {
                                required: true,
                                onChange: (e) => setClient(e.target.value)
                            })}
                        />
                        {errors.client && errors.client.type && <div className="help-block with-errors">Please Enter Client Name</div>}
                    </div>

                    <div className='heading pt-4'>
                        <h2>Remedial Hypnotist Agreement</h2>
                    </div>

                    <div className="form-group col-12">
                        <label>I hereby agree to provide hypnosis session/s to</label>
                        <input type="text" id="hyponsis" className="form-control" placeholder="name"

                            {...register('hyponsis', {
                                required: true,
                                onChange: (e) => setHyponsis(e.target.value)
                            })}
                        />
                        {errors.hyponsis && errors.hyponsis.type && <div className="help-block with-errors">Please Enter Hypnosis Session</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>in order to help with</label>
                        <input type="text" id="help" className="form-control" placeholder="order to help with"

                            {...register('help', {
                                required: true,
                                onChange: (e) => setHelp(e.target.value)
                            })}
                        />
                        {errors.help && errors.help.type && <div className="help-block with-errors">Please Enter What We Help You</div>}
                    </div>

                    <h4>issue</h4>

                    <div className='issue_d'>
                        <p>
                            I agree to treat all Clients in an ethical manner and use my training and experience in the best way possible in order to help you to overcome your issues. I will conduct our sessions in areas in which I am competent. My sessions will always be aimed at promoting your well-being and maintaining respect and dignity for you the client. I will abide by the Code of Ethics of The National Hypnotherapy Society.
                        </p>
                        <p>The number of sessions will be determined by your progress and by mutual consent.</p>

                        <ul>
                            <li>
                                <strong>Confidentiality:</strong>Our sessions are confidential within the client/therapist relationship, and I will not release any information to anyone without written authorization from you unless I believe that there is a safeguarding issue to myself, you the client, members of your family or members of the public nor in contravention of any legal action (i.e. criminal, coroner or civil court cases where a court order is made demanding disclosure) or legal requirement (e.g. Children’s Acts.)
                                <br></br>
                                Confidentiality is very important to me. Therefore, your details will always be kept secure. However, your notes may be shared at times with my supervisor. All personal details will be anonymized.
                                <br></br>
                                By signing this contract, you are agreeing to your notes being shared as described above.

                            </li>
                            <li>
                                <strong>Data Protection Statement:</strong>All personal data collected by The Pathway Hypnotherapy Rooms will only be used in conjunction aimed at promoting your well-being. All client notes and records are kept secure and confidential and the use of computer records remains within the terms of the Data Protection Act. All manual records will be kept locked away when not in use and those held on computers will always be password protected.
                            </li>

                            <li>
                                <strong>Qualifications:</strong>I am fully qualified as a Hypnotist and I am a member of The National Hypnotherapy Society that holds the first and only current hypnotherapy register to accredited by the Professional Standards Authority under its Accredited Registers programme. An Accredited Register is the result of the programme set up by the Department of Health and administered by the Professional Standards Authority who are an independent body, accountable to Parliament.
                                <br></br>
                                I am a registrant member of The National Hypnotherapy Society and agree to provide their contact details if you see a reason to bring about any complaint against me.
                            </li>
                            <li>
                                <strong>Contact between sessions: </strong>If there is an urgent need to speak to me please contact the number above and I will contact you at my earliest convenience. If our paths should cross outside of our sessions then, I will not acknowledge you as in doing so will be in breach of our confidentiality agreement.
                                <br></br>
                                Notice: Hypnosis focuses on your imagination and subconscious to help bring about positive changes to your thoughts or behavior. Together I and you will decide on the desired changes to work on prior to the hypnosis occurring. Hypnosis is not the practice of medicine or psychotherapy. If you have an ongoing medical illness, mental disability or mental illness, please consult a medical doctor, psychiatrist or psychologist licensed by The General Medical Council.

                            </li>
                        </ul>

                    </div>


                    <div className='client_agreement' >
                        <h2>CLIENT AGREEMENT</h2>
                        <p>The service fees and payment schedule for your programme of sessions will be confirmed within your consultation confirmation letter.</p>
                        <p>Visa, MasterCard. Bank Transfer & cash are all acceptable forms of payment.</p>
                        <p>Our sessions are very important and therefore it is necessary for you to attend all pre-booked ones. However, if in the likely hood that you are unable to attend then please inform me at your earliest convenience so that we can rebook another appointment as quickly as possible which will ensure that we continue with your treatment course minus further delays.</p>
                    </div>


                    <div>

                        <h2 className='pb-3'>Any cancellation for which less than 48 hours’ notice has been given shall be charged the full session fee.</h2>

                        <h6>If I decide to cancel all future agreed sessions, I will notify The Pathway Hypnotherapy Rooms one week in advance.</h6>

                        <span>
                            <strong>I acknowledge that in order to be successful in the change I want, I must accept that the following tenets are important to the process:</strong>
                        </span>

                        <ul>
                            <li>
                                I understand that my health and well-being depend on how well I care for myself physically, emotionally, intellectually and spiritually.
                            </li>
                            <li>
                                I accept that my thoughts, feelings, and desires directly determine the course of my life and my relationships.
                            </li>
                            <li>
                                I recognize that blaming myself or others serves no purpose.
                            </li>
                            <li>
                                I acknowledge that I am responsible for my experience of life as I make the choices and take the actions which shape my life.
                            </li>
                            <li>
                                I agree to be an active participant in my hypnotherapy process and see myself as an equal partner in the success of the process. I can demonstrate this by being on time for my sessions and being fully present.
                            </li>
                        </ul>
                    </div>


                    <div className='Commitment'>
                        <h2>
                            My Commitment to You
                        </h2>
                        <span>
                            I agree to use my abilities and expertise to facilitate such changes as are mutually agreed to be in your best interest. I will offer you my undivided attention during our scheduled sessions. I am professionally committed to assisting you in using your inner resources to achieve your desired outcomes in the shortest possible time.
                        </span>
                        <span>
                            I agree that there is no guarantee that my problem will be ‘cured’.
                        </span>
                    </div>


                    <FormControlLabel
                        value=''
                        control={<Checkbox className='text-dark'

                        />}
                        label='I agree with the Client Contract'
                        labelPlacement='end'

                        {...register('check', {
                            required: true,

                        })}
                    />
                    {/* {errors.check && */}
                    <div className="help-block with-errors">{errors.check?.message}</div>
                    {/* // } */}
                    <div className="form-group col-12">
                        <label>Date</label>
                        <input type="date" id="date_last" className="form-control" placeholder="Date"
                            // value={name}

                            {...register('date_last', {
                                required: true,
                                onChange: (e) => setDateLast(e.target.value)

                            })}
                        />
                        {errors.date_last && errors.date_last.type && <div className="help-block with-errors">Please Enter Date</div>}
                    </div>

                    <div className="form-group ">
                        <label>Signature</label>
                        <div>


                            <Controller
                                name="signature"
                                control={control}
                                render={({ field }) => (
                                    <SignatureCanvas penColor='black'
                                        backgroundColor='white'
                                        canvasProps={{ className: 'signature' }}
                                        ref={sigRef}
                                        onEnd={() => field.onChange(handleSignatureEnd())}

                                    // {...register('signature', {
                                    //     required: true,
                                    //     onChange: (e) => setDateLast(e.target.value)

                                    // })}

                                    />
                                )}
                            />
                            <div className="help-block with-errors">{errors?.signature?.message}</div>
                            <div className='col-md-1 form_btn_d m-3'>
                                {isPay === false ?
                                    <Button type="button" className='default-btn' style={{ backgroundColor: 'black' }}
                                        onClick={clear}
                                    >Clear</Button> : null}
                            </div>
                        </div>
                    </div>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                    <div className='col-md-1 form_btn_d m-3'>

                        {isPay === false ?
                            <Button type="submit" className='default-btn' style={{ backgroundColor: 'black' }}  >Submit
                                <i><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z"></path></svg></i>
                            </Button>
                            : null}

                        {isPay === true ?
                            <Paypal client_Id={client_Id} amount={amount} onBooking={onSubmit} setTransactionId={setTransactionId} />
                            : null}

                    </div>
                </form>


                {/* <div className='content-d pb-5 text-center'>
                    <h1 className='heading'>Confidential</h1>
                </div> */}


                <div className="copyright-area" style={{ backgroundColor: 'rgb(32 37 41)' }}>
                    <div className="container">
                        <div className="copyright-area-content">
                            <p style={{ color: '#fff' }}>
                                Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved

                            </p>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}

export default ConfidentialForm