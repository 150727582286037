import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import Rating from '@mui/material/Rating';
import { Autoplay, Pagination } from "swiper";
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import ClientSlider from '../common/ClientSlider';
import Spinner from 'react-bootstrap/Spinner';
import { createImageFromInitials, getRandomColor } from '../common/Utils';
import { Helmet } from "react-helmet";


const ClientReviews = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [clients, setClients] = useState([]);
  const [clientVideo, setClientVideo] = useState([]);
  // const [heroData, setHeroData] = useState([]);
  const [banner, setBanner] = useState('');
  const [pending, setPending] = useState(false);

  const [seoContent, setSeoContent] = useState([]);
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');
  const navigate = useNavigate();
  const [featureImage, setFeatureImage] = useState('');
  let imgSrc = "";

  const getData = () => {

    axios.get(`${api_url}/getclient/2`).then((response) => {
      setClients(response.data.data);
      setPending(true)
    }).catch((err) => {
      console.log(err)
    });

    axios.get(`${api_url}/getclient/1`).then((response) => {
      setClientVideo(response.data.data);
      setPending(true)
    }).catch((err) => {
      console.log(err)
    });

    axios.get(`${api_url}/getseo/7`).then((response) => {
      setSeoContent(response.data.data);
      console.log(response.data.data);
    }).catch((err) => {
      console.log(err);
    })

    axios.get(`${api_url}/getBanner/2`).then((response) => {
      // console.log(response.data.data);
      setBanner(response.data.data[0].image)
      // setHeroData(response.data.data)
    }).catch((err) => {
      console.log(err)
    });

    


  };

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
    if (window.location.hash.includes('##')) {
      navigate('/');
  }
  }, [])

  const handleSEO = () => {
    seoContent?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
      setFeatureImage(seo.feature_image);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [seoContent]);



  return (
    <>


<Helmet>
<link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
      </Helmet>

      {pending ? null :
        <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
          <Spinner animation="border" variant="dark" className='my_spinner' />
        </div>}

      <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
        <div className="container">
          <div className="page-banner-content-two">
            <h2>Client Reviews</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Client Reviews</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="reviews-area pt-100">
        <div className="container">
          <div className="clients-slides owl-carousel owl-theme">
            <div className="section-title">
              <h1>Client Video Feedback</h1>
            </div>

            {/* <Swiper
              slidesPerView={2}
              centeredSlides={true}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="qualification_swiper pb-5"
            > */}
            <div className='d-grid gap-4' style={{ gridTemplateColumns: '1fr 1fr' }}>
              {clientVideo?.filter(video => video.video != "").map((video) => {
                return (
                  <>
                    {/* <SwiperSlide> */}

                    <div>
                      <video src={`assets/video/${video.video}`} className="w-100" controls height={600} style={{ objectFit: 'cover' }} />
                      <span>
                        <h5>{video.name}</h5>
                        {/* <p>
                          {video.content}
                        </p> */}
                      </span>
                      {/* </SwiperSlide> */}
                    </div>
                  </>
                )
              })}
            </div>
            {/* </Swiper> */}

          </div>
        </div>
      </section>

      {/* <ClientSlider clients={clients} /> */}

      <section className="reviews-area ptb-100">
        <div className="container">
          <div className="clients-slides owl-carousel owl-theme">
            <div className="section-title">
              <h2>Client Reviews</h2>
            </div>

            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={{
                dynamicBullets: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper pb-5"
              breakpoints={{
                30: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
              }}
            >

              {clients?.map((data) => {
                return (
                  <SwiperSlide>
                    <div className="clients-item view-client_">
                      <div className="row">
                        <div className="col-lg-2">
                          <div className="reviews-image">
                            <img
                              
                              src={
                                imgSrc.length <= 0
                                  ? createImageFromInitials(500, data.name, getRandomColor())
                                  : imgSrc
                              }
                              alt='image'
                            />
                            {/* <img src={`assets/clients/${data.image}`} alt="image" /> */}
                          </div>
                        </div>

                        <div className="col-lg-10">
                          <div className="item">
                            <div className="title">

                            </div>

                            <div className="single-feedback">
                              <h3>{data.title}</h3>

                              <p>{data.content}</p>
                            </div>

                            <div className="title-info">
                              <h5>{data.name}</h5>

                              <Rating name="half-rating-read" defaultValue={data.star} precision={0.5} readOnly />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}

            </Swiper>

          </div>
        </div>


      </section>

    </>
  )
}

export default ClientReviews