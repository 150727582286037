import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm, useFormContext, FormProvider, Controller } from "react-hook-form";
import { Button, Form } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import { useState } from 'react';
// import PhoneInput from 'react-phone-number-input/react-hook-form-input'
// import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
// import PhoneInput from 'react-phone-number-input'
// import flags from 'react-phone-number-input/flags';
import TextField from '@mui/material/TextField';
import Clock from 'react-live-clock';
import emptyCart from '../../assets/emptyCart.jpg'
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import { BsSearch } from 'react-icons/bs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import timeZoneConverter from 'time-zone-converter'
import { useEffect } from 'react';
import axios from 'axios';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { FaInfo } from 'react-icons/fa';
import TableRow from '@mui/material/TableRow';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Select from 'react-select'
import { Typeahead } from 'react-bootstrap-typeahead';
// import Multiselect from 'multiselect-react-dropdown';
import Spinner from 'react-bootstrap/Spinner';
import ClickAwayListener from '@mui/material/ClickAwayListener';
// 
import { Helmet } from "react-helmet";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Paypal from '../payment/Paypal';


export default function Stepper() {

  const api_url = process.env.REACT_APP_API_URL;

  const [consultant, setConsultant] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [timezone, setTimezone] = useState('Europe/London');
  const [expanded, setExpanded] = React.useState('');
  const [value, setValue] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [isConfirm, setIsConfirm] = useState('');
  const [slotDate, setSlotDate] = useState('');
  const [slotTime, setSlotTime] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [otp, setOtp] = useState('');
  const [slotLockTime, setSlotLockTime] = useState('');
  const [minimumAmount, setMinimumAmount] = useState('');
  const [isVerify, setIsVerify] = useState(false)
  // const [slotUnlockTime, setSlotUnlockTime] = useState('');
  // const [selectedTimezone, setSelectedTimezone] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // );
  const [cancelId, setCancelId] = useState('');

  const [isMySlot, setIsMySlot] = useState(null)

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [offset, setOffset] = useState();
  // const [timezone, setTimezone] = useState('');
  const [consultantId, setConsultantId] = useState('8');
  const [slotId, setSlotId] = useState('');
  const [allSlotIds, setAllSlotIds] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [otpShow, setOtpShow] = useState(false)
  const [showCancel, setShowCancel] = useState(false);
  const [pending, setPending] = useState(false);
  const [cancel_reason, setCancel_reason] = useState('');
  const [service_type, setService_type] = useState('');
  const [GMT_offset, setGMT_offset] = useState('');

  const handleCloseCancel = () => {
    setShowCancel(false);
    setCancelId('');
  }
  const handleShowCancel = (id) => {
    setShowCancel(true);
    setCancelId(id);
  }

  const [id, setId] = useState('');
  const [slotIds, setSlotIds] = useState('');
  const [dateSlot, setDateSlot] = useState('');
  const [timeSlot, setTimeSlot] = useState('');
  // const [statusMark, setStatusMark] = useState('');

  const [showReschedule, setShowReschedule] = useState(false);

  const handleCloseReschedule = () => {
    setShowReschedule(false);
    setId('');
    setSlotIds('');
    setDateSlot('');
    setTimeSlot('');
  }

  const handleShowReschedule = (unique_id, slot_id) => {
    setShowReschedule(true);
    setId(unique_id);
    setSlotIds(slot_id);
    // setDateSlot(date_slot);
    // setTimeSlot(time_slot);
    // setStatusMark(mark_s);

  }

  // steps
  const [curPage, setCurPage] = useState(1);
  const [productPerPage, setProductPerPage] = useState(6);
  const indexOfLastProduct = curPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;
  const curProducts = timeSlots?.slice(indexOfFirstProduct, indexOfLastProduct);
  const [client_Id, setClient_Id] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [mySlot, setMySlot] = useState([])


  const lockSlot = (ids, id) => {
    setSlotId(id);
    setAllSlotIds(ids);
    setExpanded('panel2')

  }

  const unLockSlot = (slotId) => {
    setExpanded('panel1')
    setSlotId('');
    setAllSlotIds("");
    setOtpShow(false);


  }

  let nnPage = Math.floor((timeSlots?.length + productPerPage - 1) / productPerPage);


  const paginate = useCallback((pageNumber) => {
    setCurPage(pageNumber);

  }, [productPerPage],)

  // const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = (nnPage === NaN ? 0 : nnPage);



  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    paginate(curPage + 1)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    paginate(curPage - 1)
  };


  const getData = () => {
    axios.get(`${api_url}/allconsultants`).then((response) => {
      setPending(true);
      setConsultant(response.data.data);
    }).catch((err) => {
      setPending(true)
      console.log(err)
    });

    axios.get(`${api_url}/alltimeZones`).then((response) => {

      setTimeZones(response.data.data);
      setPending(true)
    }).catch((err) => {
      setPending(true)
      console.log(err)
    });

    axios.get(`${api_url}/getAdminSetting1`).then((response) => {
      setPending(true);
      setClient_Id(response.data.data[0].paypal_client)
    }).catch((err) => {
      setPending(true);
      console.log(err);
    })

  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
    // if(localStorage.getItem("lockId")){

    // }
  }, []);


  const handleConsultant = (type) => {
    setService_type(type);
    getSlots(consultantId, timezone, type);

  }


  const handleTimeZone = (timeZone) => {
    // setSelectedTimezone(timeZone);
    // setOffset(timeZone.offset)
    // getSlots(consultantId, timeZone);
  }


  const [openTool, setOpenTool] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenTool(false);
  };

  const handleTooltipOpen = () => {
    setOpenTool(true);
  };


  const onChange = (e) => {
    setPhone(e)
  }

  const getSlots = (consultant, time_zone, type) => {
    setPending(false)
    axios.post(`${api_url}/timeSlots`, {
      consultant: consultant,
      time_zone: time_zone,
      service_type: type,
    }, {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200 && response.data.data) {
        setTimeSlots(response.data.data);
        setPending(true)
      } else {
        setTimeSlots([]);
        setPending(true)
      }
      setIsConfirm('');
      setExpanded('panel1')


    }).catch((err) => {
      console.log(err)
      setPending(true)
    });

  }


  const {
    // Either pass a `control` property to the component
    // or wrap it in a `<FormProvider/>`.
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm()

  const onSubmit = () => {
    setOtpShow(false)
    setPending(false)
    axios.post(`${api_url}/slotBookMail`, {
      slot_id: allSlotIds,
      fname: name,
      lname: lastName,
      email: email,
      // phone: phone,
      // description: description,
      // dateSlotShow: slotDate,
      // timeSlotShow: slotTime

    }, {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200 && response.data.message == "Otp sent successfully...") {
        setOtpShow(true)
        setPending(true)
      } else {
        unLockSlot(allSlotIds)
        setPending(true)
      }

    }).catch((err) => {
      unLockSlot(allSlotIds)
      console.log(err);
      setPending(true)
    })
  }


  const notifyOtp = () => toast.error("please enter correct otp.. ", {
    position: "top-center",
    autoClose: 5023,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyErr = (err) => toast.error(`${err}`, {
    position: "top-center",
    autoClose: 5023,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const successRefundNotify = () => toast.success("Refund Successful", { position: "top-center" });

  const verifyOtp = () => {
    setPending(false)
    axios.post(`${api_url}/otpCheck`, {
      slot_id: allSlotIds,
      otp: otp,

    }, {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200 && response.data.message == "Otp verified and minimum ammout.") {
        setMinimumAmount(response.data.data[0].minimun_amt);
        if ((response.data.userData.length > 0)) {
          if ((response.data.userData[0].type == 1)) {
            setIsVerify(false)
            onBooking('', '');
          } else {
            setIsVerify(true);
          }
        } else {
          setIsVerify(true);
        }

      }
    }).catch((err) => {
      notifyOtp();
      setMinimumAmount('');
      setIsVerify(false)
      setPending(true)
    })
  }


  const handleRefund = (id) => {
    setPending(true);
    axios.post(`${api_url}/paymentRefundApoint`, {
      unique_id: id,

    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        successRefundNotify();
        setPending(true);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
    }).catch((err) => {
      setPending(true)
      console.log(err);
    })
  }

  const onBooking = (t_id, amount) => {
    // slotBookMail
    setPending(false)
    axios.post(`${api_url}/bookSlot`, {
      slot_id: slotId,
      slot_ids: allSlotIds,
      service_type: service_type,
      fname: name,
      lname: lastName,
      email: email,
      phone: phone,
      description: description,
      dateSlotShow: slotDate,
      timeSlotShow: slotTime,
      amount: amount,
      tnx_id: t_id,
      user_timezone: timezone,
      GMT_offset: GMT_offset

    }, {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        // localStorage.setItem("lockId", '');
        // localStorage.setItem("unlockTime", '')
        setBookingId(response.data.unique_id)
        setExpanded('panel3');
        setSlotId('');
        setMinimumAmount('')
        setIsVerify(false);
        setPending(true)
        setName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setDescription('');
        setOtp("");
        reset({
          name: '',
          lname: '',
          email: '',
          phone: '',
          address: '',
          message: '',
          otp: '',
        })
        // setSlotUnlockTime('')

        // setTimeout(() => {
        //   window.location.reload(false)
        // }, 1500);
      }

    }).catch((err) => {
      if (err.response.data.res == 1) {
        notifyErr("Slot already booked. Please choose another slot.");
        handleRefund(err.response.data.uniqueId);

      } else if (err.response.data.res == 2) {
        notifyErr("Slot not booked.Something went wrong.")
      } else if (err.response.data.res == 3) {
        notifyErr("Slot already booked. Please choose another slot.");
        setExpanded("");
      }
      setPending(true)
      console.log(err);
    })
  }


  // const handleChange = (panel) => (event, newExpanded) => {
  //   console.log(event.expanded, newExpanded, panel)
  //   setExpanded(newExpanded ? panel : false);
  // };

  const handleChange = (panel) => (event, newExpanded) => {
    console.log(event.expanded, newExpanded, panel)
    setExpanded(newExpanded ? panel : false);
  };

  // let date = new Date()
  // const newDateTime = timeZoneConverter(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes() + 1}:${date.getSeconds()}`, 5.5, selectedTimezone.offset, 'YYYY/MM/DD HH:mm:ss')
  // console.log(newDateTime) // '2018/10/11 06:00:00'

  const methods = useForm();

  // const handleSelectedSlot = (slot_id) => {
  //   setSlotId(slot_id);
  //   lockSlot();
  //   // handleShow();
  //   // onChange={handleChange('panel2')}
  // }

  // console.log(selectedTimezone, Intl.DateTimeFormat().resolvedOptions().timeZone)



  useEffect(() => {
    // setTimezone('Europe/London');
    // setConsultantId('')
    setIsConfirm('');
    setExpanded('');
    setTimeSlots([]);
    setService_type('');


  }, [timezone])




  const handleSlot = (id, date, time) => {
    setIsConfirm(`continue_${id}`)
    setSlotDate(date);
    setSlotTime(time);
  }


  const handleSlots = () => {
    setPending(false)
    setIsMySlot(null)
    axios.get(`${api_url}/slotSearch/${searchInput}`).then((response) => {

      if (response.status === 200 && response.data.message == "Data not available.") {
        setIsMySlot(false)
        setMySlot([])
        setPending(true)
      } else {
        setMySlot(response.data.data)
        setIsMySlot(true)
        setPending(true)
      }
    }).catch((err) => {
      setIsMySlot(null)
      console.log(err)
      setPending(true)
    });
  }


  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();




  // slotCancel
  const slotCancel = () => {
    setPending(false)
    axios.post(`${api_url}/slotCancelReq`, {
      unique_id: cancelId,
      cancel_reason: cancel_reason,
      // status_mark: 3,
    }, {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        handleCloseCancel();
        window.location.reload(false);
      }
      // console.log(response.data.data);

      // setMySlot(response.data.data)
    }).catch((err) => {
      setPending(true)
      console.log(err)
    });
  }

  // reschedule
  const slotReschedule = () => {

  }


  return (
    <>

      <Helmet>
        <title>Appointment | The Pathway Hypnotherapy Rooms</title>
        <meta name="description" content="Check out The Pathway Hypnotherapy Rooms." />
        <meta name="keywords" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="hweindia" />
        <meta property="og:title" content="Appointment | The Pathway Hypnotherapy Rooms" />
        <meta property="og:description" content="Check out The Pathway Hypnotherapy Rooms.
" />
      </Helmet>



      {pending ? null :
        <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
          <Spinner animation="border" variant="dark" className='my_spinner' />
        </div>}

      <div className='container p-5'>
        <ToastContainer />
        <Tabs
          defaultActiveKey="slotBooking"
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="slotBooking" title="Booking">
            <div className='mt-5'>
              <section>
                <div className='row m-0 pb-5'>
                  <Form.Group className='col-lg-4 col-md-6 col-sm-12 col-12'>
                    <Form.Label>Select Your timezone</Form.Label>
                    {/* <TimezoneSelect
                value={selectedTimezone}
                onChange={(e) => handleTimeZone(e)}
                timezones={{
                  ...allTimezones,
                  // 'America/Lima': 'Pittsburgh',
                  // 'Europe/Berlin': 'Frankfurt',
                }}
              /> */}

                    {/* <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={timeZones[0]}
                // isDisabled={isDisabled}
                // isLoading={isLoading}
                isClearable={true}
                // isRtl={isRtl}
                isSearchable={true}
                options={timeZones}
              /> */}

                    <Typeahead className='p-0'
                      // clearButton
                      id="selections-example"
                      // ref={ref}
                      // defaultSelected={}
                      labelKey={(option) => `${option?.tine_zone}`}
                      options={timeZones}
                      placeholder={`Europe/London`}
                      onChange={(selected) => {
                        // handleTimeZone(selected[0]?.tine_zone)
                        setTimezone(selected[0]?.tine_zone);
                        setGMT_offset(selected[0]?.GMT_offset);

                        // console.log(selected)
                      }}

                    />


                    {/* <Multiselect
                displayValue="tine_zone"
                onKeyPressFn={function noRefCheck() { }}
                onRemove={function noRefCheck() { }}
                onSearch={function noRefCheck() { }}
                onSelect={function noRefCheck() { }}
                options={timeZones}
                selectionLimit={1}
              /> */}


                  </Form.Group>

                  {/* <Form.Group className='col-lg-4 col-md-6 col-sm-12 col-12'>
              <Form.Label>Search</Form.Label>
            
              <Form.Control type="search" placeholder="search" />

            </Form.Group> */}
                  <Form.Group className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <Form.Label>Consultant</Form.Label>
                    <Form.Select aria-label="Default select example"
                      value={consultantId}
                      onChange={(e) => setConsultantId(e.target.value)}
                    >
                      {/* <option value="">Select</option> */}
                      {consultant?.map((data) => {
                        return (
                          <option key={data.id} value={data.id}>{data.name}</option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <Form.Label>Service Type</Form.Label>
                    <Form.Select aria-label="Default select example"
                      value={service_type}
                      onChange={(e) => handleConsultant(e.target.value)}
                    >
                      <option value="" selected disabled>Select</option>
                      {/* {consultant?.map((data) => {
                        return ( */}
                      <option value="5">Free Consultation (15min)</option>
                      <option value="10">Hypno Gastric Band - (1.30hrs)</option>
                      <option value="11">Hypno Birthing & Pregnancy- (1.30hrs)</option>
                      <option value="9">Menopause- (2hrs)</option>
                      <option value="7">Overcoming Addiction (1hr)</option>
                      <option value="8">Overcoming Gambling  (1hr)</option>
                      <option value="6">Smoking Cessation (1hr)</option>
                      <option value="1">The Control System Consultation (2hrs)</option>
                      <option value="2">The Control System (1hr)</option>
                      <option value="3">Weight Release Programme 1st Session (2hrs)</option>
                      <option value="4">Weight Release Sessions Further Sessions (1hr)</option>





                      {/* Menopause- 2hrs */}
                      {/*  */}

                      {/* )
                      })} */}
                    </Form.Select>
                  </Form.Group>

                </div>
              </section>
              <Accordion expanded={expanded === 'panel1'} style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='d-flex justify-content-center align-items-center gap-3'><span className='step_'>1</span>Choose Appointment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    <Paper variant="outlined" square className='p-4'>

                      <div>
                        {curProducts?.length > 0 ?
                          <MobileStepper
                            variant=""
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                              <Button
                                size="small"
                                className='Add_btn_'
                                onClick={() => handleNext()}
                                disabled={activeStep === maxSteps - 1}
                              >
                                Next
                                {/* {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )} */}
                              </Button>
                            }
                            backButton={
                              <Button size="small" className='Add_btn_' onClick={() => handleBack()} disabled={activeStep === 0}>
                                {/* {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )} */}
                                Back
                              </Button>
                            } /> : null}
                        {curProducts?.length <= 0 ? <center><h5 style={{ paddingTop: '20px' }}>Slot not available..</h5></center> : null}

                        {/* <div className="time-picker-container web_side">
                          {curProducts?.map((data) => {
                            return (
                              <>
                                {data.time_slots.length > 0 ?
                                  <div className="time-picker">
                                    <div className="row time-picker-header">
                                     
                                      <ul className="date-slot">
                                        <li className="date-slot-wrapper">
                                          <div className="date-slot-item">
                                            <span className="date-slot-day">{data.date}</span>
                                            <span className="date-slot-date">{data.day}</span>
                                          </div>
                                        </li>
                                      </ul>
                                      
                                    </div>
                                  </div>
                                  : null}
                              </>
                            )
                          })}
                        </div> */}
                        <div className="time-picker-container">
                          {/* .filter((data) => {
                            if (service_type == 5) {
                              return data.day != "Saturday"
                            } else{
                              return data
                            }
                          }) */}
                          {curProducts?.map((data) => {
                            return (
                              <>
                                {data.time_slots.length > 0 ?
                                  <div className="time-picker">
                                    <div className="row time-picker-header">
                                      <ul className="date-slot">
                                        <li className="date-slot-wrapper">
                                          <div className="date-slot-item">
                                            <span className="date-slot-day">{data.date}</span>
                                            <span className="date-slot-date">{data.day}</span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="row">
                                      <ul className="time-slot">
                                        {data.time_slots?.map((time) => {

                                          return (
                                            <>
                                              <li className="time-slot-item"
                                                onClick={() => handleSlot(time.id, data.date, time.convert_slot_time)}
                                              // () => setIsConfirm(`continue_${time.id}`)
                                              >
                                                {/* {timeZoneConverter(`${data.date} ${time.slot_time}`, `${time.offset}`, selectedTimezone.offset, 'HH:mm')} */}
                                                <span><b>{time.convert_slot_time}  -  {time.time_end}</b></span>
                                                {isConfirm === `continue_${time.id}` ? <Button onClick={() => lockSlot(time.all_ids, time.id)} className='d-flex mt-2 mx-auto' style={{ color: 'rgb(170, 185, 156)', backgroundColor: '#212529', borderColor: '#212529' }}>Continue</Button> : null}
                                                { }
                                              </li>
                                            </>
                                          )
                                        })}
                                        {/* <li className="time-slot-item">{timeZoneConverter(`${data.date} ${data.time_slots[0].slot_time}`, 5.5, selectedTimezone.offset, 'YYYY/MM/DD HH:mm:ss')}</li> */}
                                      </ul>
                                    </div>
                                  </div> : null
                                }
                              </>
                            )
                          })}
                        </div>
                      </div >
                    </Paper>
                  </Typography>

                </AccordionDetails >
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >

                  <Typography className='d-flex justify-content-center align-items-center gap-3'><span className='step_'>2</span>Your Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    <Button onClick={() => unLockSlot(allSlotIds)} className='Add_btn_'>Prev</Button>

                    <FormProvider {...methods}>
                      <Form onSubmit={handleSubmit(otpShow && service_type != 5 ? verifyOtp : service_type != 5 ? onSubmit : () => onBooking('', ''))} className='row m-0'>

                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                          <Form.Label>Name *</Form.Label>
                          <Form.Control type="text" placeholder="Enter first name"
                            value={name}

                            {...register('name', {
                              required: true,
                              onChange: (e) => setName(e.target.value)

                            })}
                          />
                          {errors.name && errors.name.type && <div className="help-block with-errors text-danger">please enter first name</div>}
                        </Form.Group>

                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                          <Form.Label>Last Name *</Form.Label>
                          <Form.Control type="text" placeholder="Enter last name"
                            value={lastName}

                            {...register('lname', {
                              required: true,
                              onChange: (e) => setLastName(e.target.value)

                            })}
                          />
                          {errors.lname && errors.lname.type && <div className="help-block with-errors text-danger">please enter last name</div>}
                        </Form.Group>

                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                          <Form.Label>Email *</Form.Label>
                          <Form.Control type="email" placeholder="Enter email"
                            value={email}

                            {...register('email', {
                              required: true,
                              onChange: (e) => setEmail(e.target.value)

                            })}
                          />
                          {errors.email && errors.email.type && <div className="help-block with-errors text-danger">please enter email</div>}
                        </Form.Group>

                        <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                          <Form.Label>Phone *</Form.Label>

                          {/* <PhoneInput
                          className='form-control'
                          international
                          name="phone"
                          placeholder="Enter phone number"
                          value={phone}
                          {...register('phone', {
                            required: true,
                          })} /> */}


                          <PhoneInput
                            // country={'us'}
                            inputProps={
                              {
                                ...register('phone', {
                                  required: true,
                                  onChange: (e) => onChange(e.target.value)

                                })
                              }
                            }
                            value={phone}
                            onChange={(e) => onChange(e)}
                          />

                          {errors.phone && errors.phone.type && <div className="help-block with-errors text-danger">please enter phone</div>}
                        </Form.Group>

                        <Form.Group className="col-12 mb-3">
                          <Form.Label>Message *</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            style={{ height: '100px' }}

                            value={description}

                            {...register('message', {
                              required: true,
                              onChange: (e) => setDescription(e.target.value)

                            })}

                          />
                          {errors.message && errors.message.type && <div className="help-block with-errors text-danger">please enter message</div>}
                        </Form.Group>



                        {otpShow ?
                          <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                            <Form.Label>Otp *</Form.Label>
                            <Form.Control type="text" placeholder="Enter otp"
                              value={otp}

                              {...register('otp', {
                                required: otpShow ? true : false,
                                onChange: (e) => setOtp(e.target.value)

                              })}
                            />

                            {errors.otp && errors.otp.type && <div className="help-block with-errors text-danger">please enter otp </div>}
                            <span className='text-success'>Otp send on your email</span>
                          </Form.Group>
                          : null}


                        {isVerify === false ?
                          <>
                            <div className='mr-auto'>
                              <button type="" className="default-btn">Book Appointment<i><BsFillHandIndexThumbFill /></i></button>
                            </div>
                          </> : null}

                        {isVerify === true ?
                          <>
                            <span className='text-success m-3'>Minimum amount £{minimumAmount} to be paid for this appointment</span>
                            <div className='mr-auto'>
                              <Paypal client_Id={client_Id} amount={minimumAmount} onBooking={onBooking} setTransactionId={setTransactionId} />
                            </div>
                          </>
                          : null}


                        {/* <div className='mr-auto'>
                          <button type="" className="default-btn">Verify Otp<i><BsFillHandIndexThumbFill /></i></button>
                        </div> */}

                        {/* setOtp */}

                        {/* {} */}

                        {/* <Paypal amount={subTotal} onBooking={onBooking} setTransactionId={setTransactionId} /> */}

                        {/* <div>
                  <Button type="submit" className='Add_btn_'>Submit</Button>
                </div> */}

                      </Form>
                    </FormProvider>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}

                style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='d-flex justify-content-center align-items-center gap-3'><span className='step_'>3</span>Appointment Booked</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    <div className="success-animation text-center">
                      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                      <h3 style={{ color: 'rgb(170, 185, 156)' }} className='pt-3 text-center'>Slot Booked Successfully</h3>
                      <p>Your slot id is - {bookingId}</p>

                      <Button className='Add_btn_' onClick={() => window.location.reload(false)}>Book Another Slot</Button>

                    </div>
                  </Typography>
                </AccordionDetails >
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="reschedule" title="Search(Cancel)">
            <div className='mt-5'>
              <p><b>Cancellation Policy :- </b>
                <ul className='cancel_policy_'>
                  <li>Any cancellation for which less than 48 hours’ notice has been given shall be charged the full session fee.</li>
                  <li>Once CONTROL session 1 has been completed you will not be entitled to any refund.</li>
                  <li>Clients arriving intoxicated will be turned away and charged the full session fee.</li>
                </ul>
              </p>
              <Form.Group className='d-flex align-items-center'>
                {/* <Form.Label>Search</Form.Label> */}
                <Form.Control className='slot_search p-3' type="search" placeholder="Search By Appointment Id"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}

                />
                <Button className='slot_search_btn Add_btn_'
                  onClick={() => handleSlots()}
                ><BsSearch /></Button>
              </Form.Group>
            </div>

            {/* {isMySlot === true? : isMySlot === false } */}

            {isMySlot == true ?
              mySlot?.map((data) => {
                return (
                  <>
                    <section className='search_section  mt-4'>
                      <Card sx={{ display: 'flex', alignItems: 'baseline', padding: '20px', justifyContent: 'space-between' }}>

                        <Table aria-label="simple table" className='table-borderless' >
                          <TableBody>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Name</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.name}</TableCell>
                            </TableRow>

                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Email</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.email}</TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Phone</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.phone}</TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Appointment Status</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.status}</TableCell>
                            </TableRow>
                            {data.cancel_request > 0 ?
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="left">Cancel Status</TableCell>
                                <TableCell align="left">:</TableCell>
                                <TableCell align="left">{data.cancel_request == 1 ? 'Requested' : data.cancel_request == 2 ? 'Accepted' : data.cancel_request == 3 ? 'Rejected' : null}</TableCell>
                              </TableRow>
                              : null}

                            {data.refund_status > 0 ?
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="left">Refund Status</TableCell>
                                <TableCell align="left">:</TableCell>
                                <TableCell align="left">{data.refund_status == 1 ? 'Pending' : data.refund_status == 2 ? 'Refunded' : null}</TableCell>
                              </TableRow>
                              : null}
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Date</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.dateSlotShow}</TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Time</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.timeSlotShow}</TableCell>
                            </TableRow>
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left">Description</TableCell>
                              <TableCell align="left">:</TableCell>
                              <TableCell align="left">{data.description}</TableCell>
                            </TableRow>

                          </TableBody>
                        </Table>
                        <CardActions className='d-flex gap-2 align-items-center'>
                          {/* <Button className='Add_btn_'
                            onClick={() => handleShowReschedule(data.unique_id, data.slot_ids)}
                          >Reschedule</Button> */}
                          {/* {data.cancel_request > 0 || data.cancelBtn == "hide" ? null : */}
                          <Button className='d-flex mx-auto' disabled={data.cancel_request > 0 || data.cancelBtn == "hide" ? true : false} style={{ color: 'rgb(170, 185, 156)', backgroundColor: '#212529', borderColor: '#212529' }}
                            onClick={data.cancel_request > 0 || data.cancelBtn == "hide" ? null : () => handleShowCancel(data.unique_id)}
                          >Cancel</Button>
                          {/* } */}

                          <div className='click_Tool'>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openTool}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Booking can not be cancelled in 24 hours of your appointment."
                                >

                                  <IconButton onClick={handleTooltipOpen} style={{ color: '#000000', background: '#a9b89e' }}>
                                    <FaInfo />
                                  </IconButton>

                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                          </div>
                          <div className='hover_Tool'>
                            <Tooltip title="Booking can not be cancelled in 24 hours of your appointment.">
                              <IconButton style={{ color: '#000000', background: '#a9b89e' }}>
                                <FaInfo />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </CardActions>
                      </Card>
                    </section>
                  </>
                )
              })
              : isMySlot == false ?
                <div className='m-5 d-flex justify-content-center align-items-center flex-column text-center'>
                  <img src={emptyCart} width={184} height={184} alt="image" />
                  <h5>Slot Not Found..</h5>
                </div>
                : null}

          </Tab>

        </Tabs>
      </div>



      {/* cancel modal */}
      <Modal show={showCancel} onHide={handleCloseCancel}>
        <Form onSubmit={handleSubmit1(slotCancel)}>
          <Modal.Header closeButton>
            <Modal.Title>Slot Cancel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure? Do you want to cancel this slot!
            <Form.Group className="col-12 my-3">
              {/* <Form.Label>Cancel Reason</Form.Label> */}
              <Form.Control
                as="textarea"
                placeholder="Leave a reason here"
                style={{ height: '100px' }}

                value={cancel_reason}

                {...register1('cancel_reason', {
                  required: true,
                  onChange: (e) => setCancel_reason(e.target.value)

                })}

              />
              {errors1.cancel_reason && errors1.cancel_reason.type && <div className="help-block with-errors text-danger">please enter reason here</div>}
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCancel}>
              Close
            </Button>
            <Button type="submit" className='Add_btn_' >
              Yes, Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* reschedule modal */}
      <Modal show={showReschedule} onHide={handleCloseReschedule}>
        <Modal.Header closeButton>
          <Modal.Title>Slot Reschedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure? Do you want to Reschedule this slot!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseReschedule}>
            Close
          </Button>
          <Button className='Add_btn_' onClick={slotReschedule}>
            Yes, Reschedule
          </Button>
        </Modal.Footer>
      </Modal>

    </>



  );
}