import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { HiPhone, HiMailOpen } from 'react-icons/hi';



const ShortcutIcons = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${api_url}/adminProfile`).then((response) => {
            console.log(response.data);
            setData(response.data.data)
        }).catch((err) => {
            console.log(err);
        });
    }, [])




    const [showScrollBtn, setShowScrollBtn] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 50) {
            setShowScrollBtn(true)
        }
        else if (scrolled <= 50) {
            setShowScrollBtn(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div className="option-item m-0" style={showScrollBtn ? { opacity: '1' } : { opacity: '0' }}>
                <div className="cart-btn" style={{ position: "fixed", top: '150px', right: '20px', zIndex: '999999' }} >
                    <ul className='d-flex flex-column gap-2' style={{listStyle: 'none'}}>
                        <li>
                            <a className='d-flex contact_icons' href={`tel:${data[0]?.phone}`}><HiPhone style={{ fontSize: '30px' }} /></a>
                        </li>
                        <li>
                            <a className='d-flex contact_icons' href={`mailto:glen@thepathwayhypnotherapyrooms.co.uk`}><HiMailOpen style={{ fontSize: '30px' }} /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
        //     <Button style={{borderRadius: '0px', bottom: "40px"}} className={showScrollBtn ? 'scrollTop show_btn' : 'scrollTop'}
        //     onClick={() => {
        //       window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        //     }}

        //   >
        //     <TfiAngleDoubleUp />

        //   </Button>
    )
}

export default ShortcutIcons