import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

const ViewForm4 = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const { id } = useParams();
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get(`${api_url}/getSingleHappinessDetails/${id}`).then((response) => {
            setPending(false);
            setData(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);




    return (
        <>
             <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Happiness Programme Details</span>
                            <section className="card m-3">

                                <div className="card-body">
                                    <ToastContainer />
                                    <form className="row g-3" autocomplete='off'>
                                        {data?.map((Data) => {
                                            return (
                                                <>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" disabled className="form-control" value={Data.name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" disabled className="form-control" value={Data.email} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Phone</label>
                                                        <input type="text" disabled className="form-control" value={Data.phone} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Module</label>
                                                        <input type="text" disabled className="form-control" value={Data.module} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Happiness Program</label>
                                                        <input type="text" disabled className="form-control" value={Data.happiness_programm} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Acceptance</label>
                                                        <input type="text" disabled className="form-control" value={Data.acceptance} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Addiction</label>
                                                        <input type="text" disabled className="form-control" value={Data.addiction} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Alcoholism</label>
                                                        <input type="text" disabled className="form-control" value={Data.alcoholism} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Allergies</label>
                                                        <input type="text" disabled className="form-control" value={Data.allergies} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Anger Issues</label>
                                                        <input type="text" disabled className="form-control" value={Data.anger_issues} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Anxiety</label>
                                                        <input type="text" disabled className="form-control" value={Data.anxiety} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Bad Habits</label>
                                                        <input type="text" disabled className="form-control" value={Data.bad_habits} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Bed Wetting</label>
                                                        <input type="text" disabled className="form-control" value={Data.bed_wetting} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Bereavement</label>
                                                        <input type="text" disabled className="form-control" value={Data.bereavement} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Blushing</label>
                                                        <input type="text" disabled className="form-control" value={Data.blushing} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Body Confidence</label>
                                                        <input type="text" disabled className="form-control" value={Data.body_confidence} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Body Dysmorphia</label>
                                                        <input type="text" disabled className="form-control" value={Data.body_dysmorphia} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Bruxism</label>
                                                        <input type="text" disabled className="form-control" value={Data.bruxism} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Confidence</label>
                                                        <input type="text" disabled className="form-control" value={Data.confidence} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Claustrophobia</label>
                                                        <input type="text" disabled className="form-control" value={Data.claustrophobia} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Creating New Habits</label>
                                                        <input type="text" disabled className="form-control" value={Data.creating_new_habits} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Depression</label>
                                                        <input type="text" disabled className="form-control" value={Data.depression} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Drugs</label>
                                                        <input type="text" disabled className="form-control" value={Data.drugs} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Eating Disorder</label>
                                                        <input type="text" disabled className="form-control" value={Data.eating_disorder} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Eetophobia</label>
                                                        <input type="text" disabled className="form-control" value={Data.emetophobia} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Exercise</label>
                                                        <input type="text" disabled className="form-control" value={Data.exercise} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Fears</label>
                                                        <input type="text" disabled className="form-control" value={Data.fears} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Feeling Safe World</label>
                                                        <input type="text" disabled className="form-control" value={Data.feeling_safe_world} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Fibromyalgia</label>
                                                        <input type="text" disabled className="form-control" value={Data.fibromyalgia} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Focus</label>
                                                        <input type="text" disabled className="form-control" value={Data.focus} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Forgiveness</label>
                                                        <input type="text" disabled className="form-control" value={Data.forgiveness} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Grief</label>
                                                        <input type="text" disabled className="form-control" value={Data.grief} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Guilt</label>
                                                        <input type="text" disabled className="form-control" value={Data.guilt} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Headaches</label>
                                                        <input type="text" disabled className="form-control" value={Data.headaches} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Health Anxiety</label>
                                                        <input type="text" disabled className="form-control" value={Data.health_anxiety} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Living Abundant Life</label>
                                                        <input type="text" disabled className="form-control" value={Data.living_abundant_life} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Lbs</label>
                                                        <input type="text" disabled className="form-control" value={Data.ibs} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Insomnia Sleep</label>
                                                        <input type="text" disabled className="form-control" value={Data.insomnia_sleep} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Intrusive Thoughts</label>
                                                        <input type="text" disabled className="form-control" value={Data.intrusive_thoughts} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label"> Jealously</label>
                                                        <input type="text" disabled className="form-control" value={Data.jealously} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Limiting Beliefs</label>
                                                        <input type="text" disabled className="form-control" value={Data.limiting_beliefs} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Memory Learning</label>
                                                        <input type="text" disabled className="form-control" value={Data.memory_learning} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Menopause</label>
                                                        <input type="text" disabled className="form-control" value={Data.menopause} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Migraines</label>
                                                        <input type="text" disabled className="form-control" value={Data.migraines} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Misophonia</label>
                                                        <input type="text" disabled className="form-control" value={Data.misophonia} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Motivation</label>
                                                        <input type="text" disabled className="form-control" value={Data.motivation} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Nail Biting</label>
                                                        <input type="text" disabled className="form-control" value={Data.nail_biting} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Nightmares Night Terrors</label>
                                                        <input type="text" disabled className="form-control" value={Data.nightmares_night_terrors} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Panic Attacks</label>
                                                        <input type="text" disabled className="form-control" value={Data.panic_attacks} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Pain Relief</label>
                                                        <input type="text" disabled className="form-control" value={Data.pain_relief} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Performance</label>
                                                        <input type="text" disabled className="form-control" value={Data.performance} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Phobias</label>
                                                        <input type="text" disabled className="form-control" value={Data.phobias} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Positivity</label>
                                                        <input type="text" disabled className="form-control" value={Data.positivity} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Procrastination</label>
                                                        <input type="text" disabled className="form-control" value={Data.procrastination} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Public Speaking</label>
                                                        <input type="text" disabled className="form-control" value={Data.public_speaking} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Regret</label>
                                                        <input type="text" disabled className="form-control" value={Data.regret} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Relationshipse</label>
                                                        <input type="text" disabled className="form-control" value={Data.relationships} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Restless Leg Syndrome</label>
                                                        <input type="text" disabled className="form-control" value={Data.restless_leg_syndrome} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Self Awareness</label>
                                                        <input type="text" disabled className="form-control" value={Data.self_awareness} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Self Esteem</label>
                                                        <input type="text" disabled className="form-control" value={Data.self_esteem} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label"> Sexuality</label>
                                                        <input type="text" disabled className="form-control" value={Data.sexuality} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label"> Sexual Disfunction</label>
                                                        <input type="text" disabled className="form-control" value={Data.sexual_disfunction} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Shaking Hands</label>
                                                        <input type="text" disabled className="form-control" value={Data.shaking_hands} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Shame</label>
                                                        <input type="text" disabled className="form-control" value={Data.shame} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Shopping Confidence</label>
                                                        <input type="text" disabled className="form-control" value={Data.shopping_confidence} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Sleep Apnoea</label>
                                                        <input type="text" disabled className="form-control" value={Data.sleep_apnoea} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Snoring</label>
                                                        <input type="text" disabled className="form-control" value={Data.snoring} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Social Anxiety</label>
                                                        <input type="text" disabled className="form-control" value={Data.social_anxiety} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Sports Performance</label>
                                                        <input type="text" disabled className="form-control" value={Data.sports_performance} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Stammering</label>
                                                        <input type="text" disabled className="form-control" value={Data.stammering} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Stress Relief Management</label>
                                                        <input type="text" disabled className="form-control" value={Data.stress_relief_management} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Ticks</label>
                                                        <input type="text" disabled className="form-control" value={Data.ticks} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Tinnitus</label>
                                                        <input type="text" disabled className="form-control" value={Data.tinnitus} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Trauma</label>
                                                        <input type="text" disabled className="form-control" value={Data.trauma} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Trichotillomania</label>
                                                        <input type="text" disabled className="form-control" value={Data.trichotillomania} />
                                                    </div>
                                                   
                                                    <div className="col-md-6">
                                                        <label className="form-label">Amount</label>
                                                        <input type="text" disabled className="form-control" value={Data.amount} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Transaction Id</label>
                                                        <input type="text" disabled className="form-control" value={Data.tnx_id} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Refund Status</label>
                                                        <input type="text" disabled className="form-control" value={Data.refund_status == 1 ? 'Initiate' : Data.refund_status == 2 ? 'Complete' : 'Pending'} />
                                                    </div>

                                                </>
                                            )
                                        })}

                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default ViewForm4;