import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import { Button } from 'react-bootstrap';
import logo from '../assets/logo.png';
import SignatureCanvas from 'react-signature-canvas';
import RadioGroup from '@mui/material/RadioGroup';
import { ToastContainer, toast } from 'react-toastify';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Paypal from '../common/payment/Paypal';


const WeighReleaseQuestionnaire = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [approxweight, setApproxWeight] = useState('');
    const [goal, setGoalWeight] = useState('');
    const [ideal, setIdealWeight] = useState('');
    const [gain, setGainWeight] = useState('');
    const [emotions, setEmotions] = useState('');
    const [breakfast, setBreakFast] = useState('');
    const [midmorn, setMidMorn] = useState('');
    const [lunch, setLunch] = useState('');
    const [midafter, setMidAfter] = useState('');
    const [meal, setMeal] = useState('');
    const [supper, setSuper] = useState('');
    const [other, setOther] = useState('');
    const [snack, setSnack] = useState('');
    const [nighteat, setNightEat] = useState('');
    const [overeat, setOverEat] = useState('');
    const [daydrink, setDayDrink] = useState('');
    const [fizzy, setFizzyDrink] = useState('');
    const [dayunit, setDayUnit] = useState('');
    const [weekunit, setWeekUnit] = useState('');
    const [daywater, setDayWater] = useState('');
    const [foodshoop, setFoodShop] = useState('');
    const [prepare, setPrepareFood] = useState('');
    const [leavefood, setLeaveFood] = useState('');
    const [finishfood, setFinishFood] = useState('');
    const [enjoyment, setEnjoyment] = useState([]);
    const [suggestionlist, setSuggestionList] = useState([]);
    const [suggestion, setSuggest] = useState('');
    const [overweight, setOverWeight] = useState('');
    const [force, setForced] = useState('');
    const [reward, setReward] = useState('');
    const [forget, setForget] = useState('');
    const [hungry, setHungry] = useState('');
    const [nothungry, setNotHungry] = useState('');
    const [example1, setExample1] = useState('');
    const [eatsomeone, setEatSomeone] = useState('');
    const [example2, setExample2] = useState('');
    const [constantly, setConstantly] = useState('');
    const [problematic, setProblematic] = useState('');
    const [whom, setWhom] = useState('');
    const [relation, setRelation] = useState('');
    const [sleep, setSleep] = useState('');
    const [lead, setLead] = useState('');
    const [sitting, setSitting] = useState('');
    const [sport, setSport] = useState('');
    const [question, setQuestion] = useState('');
    const [pleasesay, setPleaseSay] = useState('');
    const [convenient, setConveninent] = useState('');
    const [drugs, setDrugs] = useState('');
    const [sideEffects, setSideEffects] = useState('');
    const [consult, setConsults] = useState('');
    const [date, setDate] = useState('');
    const [client_signature, setclient_signature] = useState('');
    const [isPay, setIsPay] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [amount, setAmount] = useState('')
    const [client_Id, setClient_Id] = useState('');



    const formSchema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        phone: yup.string().min(10).required(),
        goal: yup.string().required(),
        weight: yup.string().required(),
        ideal: yup.string().required(),
        gain: yup.string().required(),
        emotion: yup.string().required(),
        breakfast: yup.string().required(),
        midmorn: yup.string().required(),
        lunch: yup.string().required(),
        midafter: yup.string().required(),
        meal: yup.string().required(),
        super: yup.string().required(),
        other: yup.string().required(),
        snack: yup.string().required(),
        nighteat: yup.string().required(),
        overeat: yup.string().required(),
        daydrink: yup.string().required(),
        fizzy: yup.string().required(),
        dayunit: yup.string().required(),
        weekunit: yup.string().required(),
        daywater: yup.string().required(),
        foodshop: yup.string().required(),
        prepare: yup.string().required(),
        leavefood: yup.string().required(),
        finish: yup.string().required(),
        enjoyment: yup.array().min(1).of(yup.string().required()).required(),
        suggestion: yup.array().min(1).of(yup.string().required()).required(),
        suggest: yup.string().required(),
        overweight: yup.string().required(),
        example1: yup.string().required(),
        example2: yup.string().required(),
        whom: yup.string().required(),
        relation: yup.string().required(),
        sleep: yup.string().required(),
        pleasesay: yup.string().required(),
        question: yup.string().required(),
        concenient: yup.string().required(),
        date: yup.string().required(),
        force: yup.string().required(),
        reward: yup.string().required(),
        forget: yup.string().required(),
        hungry: yup.string().required(),
        nothungry: yup.string().required(),
        eatsomeone: yup.string().required(),
        constantly: yup.string().required(),
        problematic: yup.string().required(),
        lead: yup.string().required(),
        sitting: yup.string().required(),
        sport: yup.string().required(),
        drugs: yup.string().required(),
        sideEffects: yup.string().required(),
        consult: yup.string().required(),
        // check: yup
        //     .bool()
        //     .oneOf([true], 'You need to accept the terms and conditions'),

        signature: yup
            .string()
            .required()
            .matches(
                /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
                "Signature must be png"
            ),
    });



    const getData = () => {
        axios.get(`${api_url}/getamountsetting`).then((response) => {

            setAmount(response.data.data[0].minimun_amt)
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

        axios.get(`${api_url}/getAdminSetting1`).then((response) => {
            
            setClient_Id(response.data.data[0].paypal_client)
          }).catch((err) => {
           
            console.log(err);
          })
    }

    useEffect(() => {
        getData();
    }, []);


    const sigRef = useRef();
    const handleSignatureEnd = () => {
        if (sigRef.current) {
            const dataURL = sigRef.current.toDataURL();
            setclient_signature(dataURL);
            return dataURL;

        }

    };

    const clear = () => {
        sigRef.current.clear();
        reset({
            signature: ''
        })
    }


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    let smoke = [
        'On waking', 'At breakfast', 'After meals', 'Driving', 'With tea/coffee',
        'On the phone', 'At work', 'In bed', 'Other (Write Below)'
    ]
    let data1 = [
        'Yes', 'No'
    ]
    let data2 = [
        'Yes', 'No'
    ]

    let importreason = [
        'Controlled by Cigarettes', 'Coughs and Colds', 'Money / Expense of Smoking', 'Breathing', 'Children / Grandchildren', 'Death and Dying'
        , 'Smell of Smoking', 'Pressure from others', 'Current Health Problems', 'Inconvenience', 'Currently Healthy but Concerned about Future'
        , 'Anti-Social'
    ]



    const handleEnjoyment = (e) => {

        let checkedData = [...enjoyment];

        if (e.checked) {
            checkedData = [...enjoyment, { value: e.value }]

        } else {
            let Index = enjoyment.findIndex(data => data.value == e.value)
            checkedData.splice(Index, 1)
        }

        let myData = checkedData
        setEnjoyment(myData);



    }

    const handleSuggestion = (e) => {

        let checkedData = [...suggestionlist];

        if (e.checked) {
            checkedData = [...suggestionlist, { value: e.value }]

        } else {
            let Index = suggestionlist.findIndex(data => data.value == e.value)
            checkedData.splice(Index, 1)
        }

        let myData = checkedData
        setSuggestionList(myData);


    }


    const verified = () => {
        setIsPay(true);
    }


    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors } = {},
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(formSchema),
    });
    const notify = () => toast.success("Form Submit SuccessFully", { position: "top-center" });
    const onSubmit = (tnx_id, amount) => {
        setPending(true);
        axios.post(`${api_url}/weight`, {
            name: name,
            email: email,
            phone: phone,
            approx_weight: approxweight,
            goal_weight: goal,
            ideal_weight: ideal,
            what_changed: gain,
            what_emotions: emotions,
            breakfast: breakfast,
            mid_morning: midmorn,
            lunch: lunch,
            mid_morn_after: midafter,
            evening_meal: meal,
            Supper: supper,
            other: other,
            snack: snack,
            night_eat: nighteat,
            overeat: overeat,
            fizzy: fizzy,
            alcohol_day: dayunit,
            alcohol_week: weekunit,
            approx_water: daywater,
            food_shopping: foodshoop,
            cook: prepare,
            plate: leavefood,
            finish_food: finishfood,
            enjoy_food_list: enjoyment?.map(data => data.value).toString(),
            suggestion_list: suggestionlist?.map(data => data.value).toString(),
            suggestion: suggestion,
            other_overweight: overweight,
            forced: force,
            reward: reward,
            forget: forget,
            hungry: hungry,
            not_hungry: nothungry,
            example1: example1,
            eat_someone: eatsomeone,
            example2: example2,
            meal: constantly,
            problematic: problematic,
            whom: whom,
            relationship: relation,
            sleep: sleep,
            lead: lead,
            sitting: sitting,
            sport: sport,
            question: question,
            please_say: pleasesay,
            convenient: convenient,
            drugs: drugs,
            side_effects: sideEffects,
            consult: consult,
            date: date,
            client_signature: client_signature,
            amount: amount,
            tnx_id: tnx_id,

        }, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setIsPay(false);
                setPending(false);
                setAmount('')
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    return (
        <>

            <article>
                <div className='title text-center'>
                    <img style={{ borderRadius: "50%" }} src={'https://tphr.giwdevelopment.com/assets/logo/logo.png'} width={100} height={99} alt="image" />
                    <h1 className='text-center pb-5'>THE PATHWAY HYPNOTHERAPY ROOMS</h1>
                </div>
                <ToastContainer />

                <div className='content-d pb-5'>
                    <h1 className='heading'>Weight Release Questionnaire</h1>
                </div>

                <div>
                    <span>
                        Your responses to the following questions will enable me to construct and effective programme to help you to release the weight that you want. All information is private and confidential.
                    </span>
                </div>

                <form id="contactForm" className='row m-0' onSubmit={handleSubmit(() => verified())}>
                    <div className="form-group col-6">
                        <label>Name</label>
                        <input type="text" id="name" className="form-control" placeholder="Name"
                            {...register('name', {
                                required: true,
                                onChange: (e) => setName(e.target.value)

                            })}
                        />
                        {errors.name && errors.name.type && <div className="help-block with-errors">please enter your name</div>}

                    </div>

                    <div className="form-group col-6">
                        <label>Email</label>
                        <input type="email" id="email" className="form-control" placeholder="Email"
                            {...register('email', {
                                required: true,
                                onChange: (e) => setEmail(e.target.value)

                            })}
                        />
                        {errors.email && errors.email.type && <div className="help-block with-errors">please enter email</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Phone</label>
                        <input type="text" id="phone_number" className="form-control" placeholder="Phone"
                            {...register('phone', {
                                required: true,
                                onChange: (e) => setPhone(e.target.value)

                            })}
                        />
                        {errors.phone && errors.phone.type && <div className="help-block with-errors">please enter phone</div>}
                    </div>




                    <div className="form-group col-6">
                        <label>How much (approximately) do you weight?</label>
                        <input type="number" id="" className="form-control" placeholder="weight"
                            {...register('weight', {
                                required: true,
                                onChange: (e) => setApproxWeight(e.target.value)

                            })} />
                        {errors.weight && errors.weight.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label> What is your goal weight?</label>
                        <input type="number" id="" className="form-control" placeholder="goal weight"
                            {...register('goal', {
                                required: true,
                                onChange: (e) => setGoalWeight(e.target.value)

                            })} />
                        {errors.goal && errors.goal.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>When in your life were you your ideal weight?</label>
                        <input type="muber" id="" className="form-control" placeholder="ideal weight"
                            {...register('ideal', {
                                required: true,
                                onChange: (e) => setIdealWeight(e.target.value)

                            })} />
                        {errors.ideal && errors.ideal.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label> What changed in your life when you began to gain weight?</label>
                        <input type="muber" id="" className="form-control" placeholder="What changed"
                            {...register('gain', {
                                required: true,
                                onChange: (e) => setGainWeight(e.target.value)

                            })} />
                        {errors.gain && errors.gain.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>


                    <div className="form-group col-12">
                        <label> What emotions do you associate with this period in your life? For example, guilt, comfort, punishment, contentment, etc.</label>
                        <input type="text" id="" className="form-control" placeholder="What emotions"
                            {...register('emotion', {
                                required: true,
                                onChange: (e) => setEmotions(e.target.value)

                            })} />
                        {errors.emotion && errors.emotion.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className='col-12'>
                        On an average day, what do you eat and how much?
                    </div>
                    <div className="form-group col-6">
                        <label>a) For breakfast</label>
                        <input type="text" id="" className="form-control" placeholder="how much"
                            {...register('breakfast', {
                                required: true,
                                onChange: (e) => setBreakFast(e.target.value)

                            })} />
                        {errors.breakfast && errors.breakfast.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>b) Mid-morning</label>
                        <input type="text" id="" className="form-control" placeholder="Mid-morning"
                            {...register('midmorn', {
                                required: true,
                                onChange: (e) => setMidMorn(e.target.value)

                            })} />
                        {errors.midmorn && errors.midmorn.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>c) Lunch</label>
                        <input type="text" id="" className="form-control" placeholder="Lunch"
                            {...register('lunch', {
                                required: true,
                                onChange: (e) => setLunch(e.target.value)

                            })} />
                        {errors.lunch && errors.lunch.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>d) Mid-afternoon</label>
                        <input type="text" id="" className="form-control" placeholder="Mid-afternoon"
                            {...register('midafter', {
                                required: true,
                                onChange: (e) => setMidAfter(e.target.value)

                            })} />
                        {errors.midafter && errors.midafter.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-6">
                        <label>e) Evening meal</label>
                        <input type="text" id="" className="form-control" placeholder="Evening meal"
                            {...register('meal', {
                                required: true,
                                onChange: (e) => setMeal(e.target.value)

                            })} />
                        {errors.meal && errors.meal.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>f) Supper</label>
                        <input type="text" id="" className="form-control" placeholder="Supper"
                            {...register('super', {
                                required: true,
                                onChange: (e) => setSuper(e.target.value)

                            })} />
                        {errors.super && errors.super.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>g) Other</label>
                        <input type="text" id="" className="form-control" placeholder="Other"
                            {...register('other', {
                                required: true,
                                onChange: (e) => setOther(e.target.value)

                            })} />
                        {errors.other && errors.other.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Do you snack between meals? If so, which, and what do you snack on?</label>
                        <input type="text" id="" className="form-control" placeholder="snack"
                            {...register('snack', {
                                required: true,
                                onChange: (e) => setSnack(e.target.value)

                            })} />
                        {errors.snack && errors.snack.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Do you ever get up during the night for something to eat?</label>
                        <input type="text" id="" className="form-control" placeholder="get up"
                            {...register('nighteat', {
                                required: true,
                                onChange: (e) => setNightEat(e.target.value)

                            })} />
                        {errors.nighteat && errors.nighteat.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>If you overeat, which of the above foods would you like to cut down on, or cut out altogether?</label>
                        <input type="text" id="" className="form-control" placeholder="overeat"
                            {...register('overeat', {
                                required: true,
                                onChange: (e) => setOverEat(e.target.value)

                            })} />
                        {errors.overeat && errors.overeat.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Approximately how many drinks do you have a day?</label>
                        <input type="text" id="" className="form-control" placeholder="how many"
                            {...register('daydrink', {
                                required: true,
                                onChange: (e) => setDayDrink(e.target.value)

                            })} />
                        {errors.daydrink && errors.daydrink.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Do you drink fizzy or sweetened drinks? If so, how many?</label>
                        <input type="text" id="" className="form-control" placeholder="how many"
                            {...register('fizzy', {
                                required: true,
                                onChange: (e) => setFizzyDrink(e.target.value)

                            })} />
                        {errors.fizzy && errors.fizzy.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    {/*  */}
                    <div className="form-group col-6">
                        <label>Do you drink alcohol? If so, how many units per day</label>
                        <input type="text" id="" className="form-control" placeholder="per day"
                            {...register('dayunit', {
                                required: true,
                                onChange: (e) => setDayUnit(e.target.value)

                            })} />
                        {errors.dayunit && errors.dayunit.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Do you drink alcohol? If so, how many units per week</label>
                        <input type="text" id="name" className="form-control" placeholder="per week"
                            {...register('weekunit', {
                                required: true,
                                onChange: (e) => setWeekUnit(e.target.value)

                            })} />
                        {errors.weekunit && errors.weekunit.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Do you drink water? If so, how many glasses approximately per day?</label>
                        <input type="text" id="" className="form-control" placeholder="per day"
                            {...register('daywater', {
                                required: true,
                                onChange: (e) => setDayWater(e.target.value)

                            })} />
                        {errors.daywater && errors.daywater.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Who does the food shopping in your household?</label>
                        <input type="text" id="foodshop" className="form-control" placeholder="food shopping"
                            {...register('foodshop', {
                                required: true,
                                onChange: (e) => setFoodShop(e.target.value)

                            })} />
                        {errors.foodshop && errors.foodshop.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Who prepares and cooks the food?</label>
                        <input type="text" id="prepare" className="form-control" placeholder="cooks"
                            {...register('prepare', {
                                required: true,
                                onChange: (e) => setPrepareFood(e.target.value)

                            })} />
                        {errors.prepare && errors.prepare.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-6">
                        <label>Do you often leave food on your plate?</label>
                        <input type="text" id="" className="form-control" placeholder="leave food"
                            {...register('leavefood', {
                                required: true,
                                onChange: (e) => setLeaveFood(e.target.value)

                            })} />
                        {errors.leavefood && errors.leavefood.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-6">
                        <label>Do you regularly finish off other people's food? </label>
                        <input type="text" id="" className="form-control" placeholder="finish off"
                            {...register('finish', {
                                required: true,
                                onChange: (e) => setFinishFood(e.target.value)

                            })} />
                        {errors.finish && errors.finish.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>



                    <label>Do you enjoy: (please tick where appropriate)?</label>

                    {['Sweet foods?', 'Savoury foods?', 'Fresh fruit?', 'Fresh vegetables?', 'Starchy foods?', 'Fatty foods?']?.map((data) => {
                        return (
                            <div key={data} className="col-4">
                                <FormControlLabel
                                    value={data}
                                    control={<Checkbox className='text-dark' />}
                                    label={data}
                                    labelPlacement='end'
                                    {...register('enjoyment', {
                                        required: true,
                                        onChange: (e) => handleEnjoyment(e.target)
                                    })}
                                // onChange={(e) => handleEnjoyment(e.target)}
                                />

                            </div>
                        )
                    })}

                    {errors.enjoyment && <div className="help-block with-errors">This field is required</div>}

                    <label className='pt-4'>What suggestions do you feel would be most effective for helping you to achieve your goal weight? (Please tick)</label>


                    {['Stop over-eating', 'Stop snacking between meals', 'Stop drinking alcohol', 'Stop drinking sweet drinks', 'Stop eating junk foods', 'Take more exercise', 'Have more energy']?.map((data) => {
                        return (
                            <div key={data} className="col-4">
                                <FormControlLabel
                                    value={data}
                                    control={<Checkbox className='text-dark' />}
                                    label={data}
                                    labelPlacement='end'
                                    {...register('suggestion', {
                                        required: true,
                                        onChange: (e) => handleSuggestion(e.target)
                                    })}
                                // onChange={(e) => handleSuggestion(e.target)}
                                />
                            </div>
                        )
                    })}

                    {errors.suggestion && <div className="help-block with-errors">This field is required</div>}

                    <div className="form-group pt-4 col-12">
                        <label>What suggestions do you feel would be most effective for helping you to achieve your goal weight? (Other)</label>
                        <input type="text" id="" className="form-control" placeholder="suggestions"
                            {...register('suggest', {
                                required: true,
                                onChange: (e) => setSuggest(e.target.value)

                            })} />
                        {errors.suggest && errors.suggest.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>


                    <div className="form-group col-12">
                        <label>Are, or were, either of your parents, brothers or sister’s overweight? If so, please say which.</label>
                        <input type="text" id="" className="form-control" placeholder="overweight"
                            {...register('overweight', {
                                required: true,
                                onChange: (e) => setOverWeight(e.target.value)

                            })} />
                        {errors.overweight && errors.overweight.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group col-12">
                        <label>Do you remember any instances of being 'forced' to eat up when you were younger? </label>
                        {/* <RadioGroup onChange={(e) => setForced(e.target.value)} style={{ flexDirection: 'inherit' }}>
                            <FormControlLabel value="1" control={<Radio className='text-dark' />} label="Yes" />
                            <FormControlLabel value="2" control={<Radio className='text-dark' />} label="No" />
                        </RadioGroup> */}
                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={force === '1'}
                                className='text-dark'
                                value="1"
                                name="force"
                                {...register('force', {
                                    required: 'This field is required',
                                    onChange: (e) => setForced(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={force === '2'}
                                className='text-dark'
                                value="2"
                                name="force"
                                {...register('force', {
                                    required: 'This field is required',
                                    onChange: (e) => setForced(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.force && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Was food ever used as a reward for doing something good? </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={reward === '1'}
                                className='text-dark'
                                value="1"
                                name="reward"
                                {...register('reward', {
                                    required: 'This field is required',
                                    onChange: (e) => setReward(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={reward === '2'}
                                className='text-dark'
                                value="2"
                                name="reward"
                                {...register('reward', {
                                    required: 'This field is required',
                                    onChange: (e) => setReward(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.reward && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Did you ever eat to forget about something else? </label>
                        {/* <RadioGroup onChange={(e) => setForget(e.target.value)} style={{ flexDirection: 'inherit' }}>
                            <FormControlLabel value="1" control={<Radio className='text-dark' />} label="Yes" />
                            <FormControlLabel value="2" control={<Radio className='text-dark' />} label="No" />
                        </RadioGroup> */}

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={forget === '1'}
                                className='text-dark'
                                value="1"
                                name="forget"
                                {...register('forget', {
                                    required: 'This field is required',
                                    onChange: (e) => setForget(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={forget === '2'}
                                className='text-dark'
                                value="2"
                                name="forget"
                                {...register('forget', {
                                    required: 'This field is required',
                                    onChange: (e) => setForget(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.forget && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Did you often feel hungry as a child?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={hungry === '1'}
                                className='text-dark'
                                value="1"
                                name="hungry"
                                {...register('hungry', {
                                    required: 'This field is required',
                                    onChange: (e) => setHungry(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={hungry === '2'}
                                className='text-dark'
                                value="2"
                                name="hungry"
                                {...register('hungry', {
                                    required: 'This field is required',
                                    onChange: (e) => setHungry(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.hungry && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Do you ever eat when you are not hungry? </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={nothungry === '1'}
                                className='text-dark'
                                value="1"
                                name="nothungry"
                                {...register('nothungry', {
                                    required: 'This field is required',
                                    onChange: (e) => setNotHungry(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={nothungry === '2'}
                                className='text-dark'
                                value="2"
                                name="nothungry"
                                {...register('nothungry', {
                                    required: 'This field is required',
                                    onChange: (e) => setNotHungry(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.nothungry && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If yes, please give an example</label>
                        <input type="text" id="" className="form-control" placeholder="example"
                            {...register('example1', {
                                required: true,
                                onChange: (e) => setExample1(e.target.value)

                            })} />
                        {errors.example1 && errors.example1.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Do you ever eat to please someone else?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={eatsomeone === '1'}
                                className='text-dark'
                                value="1"
                                name="eatsomeone"
                                {...register('eatsomeone', {
                                    required: 'This field is required',
                                    onChange: (e) => setEatSomeone(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={eatsomeone === '2'}
                                className='text-dark'
                                value="2"
                                name="eatsomeone"
                                {...register('eatsomeone', {
                                    required: 'This field is required',
                                    onChange: (e) => setEatSomeone(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.eatsomeone && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If yes, please give an example</label>
                        <input type="text" id="name" className="form-control" placeholder="example"
                            {...register('example2', {
                                required: true,
                                onChange: (e) => setExample2(e.target.value)

                            })} />
                        {errors.example2 && errors.example2.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Are you constantly thinking about the next meal?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={constantly === '1'}
                                className='text-dark'
                                value="1"
                                name="constantly"
                                {...register('constantly', {
                                    required: 'This field is required',
                                    onChange: (e) => setConstantly(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={constantly === '2'}
                                className='text-dark'
                                value="2"
                                name="constantly"
                                {...register('constantly', {
                                    required: 'This field is required',
                                    onChange: (e) => setConstantly(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.constantly && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Do you have any problematic relationships in your life at present?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={problematic === '1'}
                                className='text-dark'
                                value="1"
                                name="problematic"
                                {...register('problematic', {
                                    required: 'This field is required',
                                    onChange: (e) => setProblematic(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={problematic === '2'}
                                className='text-dark'
                                value="2"
                                name="problematic"
                                {...register('problematic', {
                                    required: 'This field is required',
                                    onChange: (e) => setProblematic(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.problematic && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If yes, please state with whom</label>
                        <input type="text" id="" className="form-control" placeholder="whom"
                            {...register('whom', {
                                required: true,
                                onChange: (e) => setWhom(e.target.value)

                            })} />

                        {errors.whom && errors.whom.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If you answered yes, how do you see this relationship improving</label>
                        <input type="text" id="" className="form-control" placeholder="relationship"
                            {...register('relation', {
                                required: true,
                                onChange: (e) => setRelation(e.target.value)

                            })} />
                        {errors.relation && errors.relation.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>How many hours sleep (approximately) do you have per night?</label>
                        <input type="text" id="" className="form-control" placeholder="per night"
                            {...register('sleep', {
                                required: true,
                                onChange: (e) => setSleep(e.target.value)

                            })} />
                        {errors.sleep && errors.sleep.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div>
                        <h2>Exercise</h2>
                    </div>
                    <div className="form-group col-12">
                        <label>Do you lead an active life? </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={lead === '1'}
                                className='text-dark'
                                value="1"
                                name="lead"
                                {...register('lead', {
                                    required: 'This field is required',
                                    onChange: (e) => setLead(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={lead === '2'}
                                className='text-dark'
                                value="2"
                                name="lead"
                                {...register('lead', {
                                    required: 'This field is required',
                                    onChange: (e) => setLead(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.lead && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Does your job involve sitting down a lot?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={sitting === '1'}
                                className='text-dark'
                                value="1"
                                name="sitting"
                                {...register('sitting', {
                                    required: 'This field is required',
                                    onChange: (e) => setSitting(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={sitting === '2'}
                                className='text-dark'
                                value="2"
                                name="sitting"
                                {...register('sitting', {
                                    required: 'This field is required',
                                    onChange: (e) => setSitting(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.sitting && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Are you involved in any sport or regular exercise?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={sport === '1'}
                                className='text-dark'
                                value="1"
                                name="sport"
                                {...register('sport', {
                                    required: 'This field is required',
                                    onChange: (e) => setSport(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={sport === '2'}
                                className='text-dark'
                                value="2"
                                name="sport"
                                {...register('sport', {
                                    required: 'This field is required',
                                    onChange: (e) => setSport(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.sport && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If the answer to the above question is no, can you identify a sport that you would enjoy doing?</label>
                        <input type="text" id="" className="form-control" placeholder="sport"
                            {...register('question', {
                                required: true,
                                onChange: (e) => setQuestion(e.target.value)

                            })} />
                        {errors.question && errors.question.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If yes, please say what this would be</label>
                        <input type="text" id="" className="form-control" placeholder="write"
                            {...register('pleasesay', {
                                required: true,
                                onChange: (e) => setPleaseSay(e.target.value)

                            })} />
                        {errors.pleasesay && errors.pleasesay.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>When would a convenient time for you to do this, be?</label>
                        <input type="text" id="concenient" className="form-control" placeholder="convenient time"
                            {...register('concenient', {
                                required: true,
                                onChange: (e) => setConveninent(e.target.value)

                            })} />
                        {errors.concenient && errors.concenient.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div>
                        <h2>Medication</h2>
                    </div>
                    <div className="form-group col-12">
                        <label>Are you currently taking any drugs or prescribed medication?  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={drugs === '1'}
                                className='text-dark'
                                value="1"
                                name="drugs"
                                {...register('drugs', {
                                    required: 'This field is required',
                                    onChange: (e) => setDrugs(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={drugs === '2'}
                                className='text-dark'
                                value="2"
                                name="drugs"
                                {...register('drugs', {
                                    required: 'This field is required',
                                    onChange: (e) => setDrugs(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.drugs && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If yes, are you aware of any side effects from these that could cause weight gain  </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={sideEffects === '1'}
                                className='text-dark'
                                value="1"
                                name="sideEffects"
                                {...register('sideEffects', {
                                    required: 'This field is required',
                                    onChange: (e) => setSideEffects(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={sideEffects === '2'}
                                className='text-dark'
                                value="2"
                                name="sideEffects"
                                {...register('sideEffects', {
                                    required: 'This field is required',
                                    onChange: (e) => setSideEffects(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.sideEffects && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>If yes, are you willing to consult with your GP to find a more suitable alternative   </label>

                        <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                            <Radio
                                checked={consult === '1'}
                                className='text-dark'
                                value="1"
                                name="consult"
                                {...register('consult', {
                                    required: 'This field is required',
                                    onChange: (e) => setConsults(e.target.value)

                                })}

                            /> Yes
                            <Radio
                                checked={consult === '2'}
                                className='text-dark'
                                value="2"
                                name="consult"
                                {...register('consult', {
                                    required: 'This field is required',
                                    onChange: (e) => setConsults(e.target.value)

                                })}

                            /> No

                        </div>
                        {errors.consult && <div className="help-block with-errors">This field is required</div>}
                    </div>
                    <div className="form-group col-12">
                        <label>Date</label>
                        <input type="date" id="date" className="form-control" placeholder=""
                            {...register('date', {
                                required: true,
                                onChange: (e) => setDate(e.target.value)

                            })} />
                        {errors.date && errors.date.type && <div className="help-block with-errors">Please Fill This Input</div>}
                    </div>

                    <div className="form-group ">
                        <label>Signature</label>
                        <div>



                            <Controller
                                name="signature"
                                control={control}
                                render={({ field }) => (
                                    <SignatureCanvas penColor='black'
                                        backgroundColor='white'
                                        canvasProps={{ className: 'signature' }}
                                        ref={sigRef}
                                        onEnd={() => field.onChange(handleSignatureEnd())}

                                    // {...register('signature', {
                                    //     required: true,
                                    //     onChange: (e) => setDateLast(e.target.value)

                                    // })}

                                    />
                                )}
                            />

                            <div className="help-block with-errors">{errors?.signature?.message}</div>

                            <div className='col-md-1 form_btn_d m-3'>
                                <Button type="button" className='default-btn' style={{ backgroundColor: 'black' }}
                                    onClick={clear}
                                >Clear</Button>
                            </div>
                        </div>
                    </div>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                    <div className='col-md-1 form_btn_d m-3'>

                        {isPay === false ?
                            <Button type="submit" className='default-btn' style={{ backgroundColor: 'black' }}  >Submit
                                <i><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716.075.09.141.175.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0z"></path></svg></i>
                            </Button> : null}

                        {isPay === true ?
                            <Paypal client_Id={client_Id} amount={amount} onBooking={onSubmit} setTransactionId={setTransactionId} />
                            : null}

                    </div>

                </form>





                <div className="copyright-area" style={{ backgroundColor: 'rgb(32 37 41)' }}>
                    <div className="container">
                        <div className="copyright-area-content">
                            <p style={{ color: '#fff' }}>
                                Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved

                            </p>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}

export default WeighReleaseQuestionnaire