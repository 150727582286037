import React, { useEffect, memo, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import Spinner from 'react-bootstrap/Spinner';
// import axios from 'axios';


const Paypal = ({ amount, setTransactionId, onBooking, client_Id }) => {


    const [mySpinner, setMySpinner] = useState(false);

    useEffect(() => {
        setMySpinner(true);
        setTimeout(() => {
            setMySpinner(false);
        }, 3000);
    }, [])

    const initialOptions = {
        intent: "capture",
        "client-id": client_Id,
        // "client-secret": "EOWQy96aCHfbldoq20Ao4jF8InK_lajG5bZI1gvWLEwI2AEur2EYn--CwW04dhmB2Saib7WO1XKdJVPg",
        currency: "GBP",
        components: "buttons,marks,funding-eligibility",
        // "data-client-token": "EOWQy96aCHfbldoq20Ao4jF8InK_lajG5bZI1gvWLEwI2AEur2EYn--CwW04dhmB2Saib7WO1XKdJVPg"
    }

    // const [{ isPending }] = usePayPalScriptReducer();

    return (
        <>

            {/* <div className={mySpinner ? 'spinner_d' : 'd-none'}>
                <Spinner animation="border" className='mySpinner' />
            </div> */}

            <PayPalScriptProvider
                // deferLoading={true}
                options={initialOptions}>
                <PayPalButtons
                    fundingSource="paypal"
                    className="d-flex w-50"
                    style={{
                        layout: 'vertical',
                        color: 'blue',
                        shape: 'rect',
                        label: 'pay',
                        height: 50,
                    }}

                    createOrder={(data, actions) => {
                        console.log(actions, data, "createOrder")
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: `${amount}`,
                                    },
                                },
                            ],
                        });
                    }}

                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            console.log(actions, data, "onApprove")
                            let transaction_Id = details.purchase_units[0].payments.captures[0].id
                            setTransactionId(transaction_Id);
                            setTimeout(() => {
                                onBooking(transaction_Id, amount);
                            }, 1000);

                        });
                    }}
                    onError={(data, errr) => {
                        console.log(data, errr)
                    }}
                />
            </PayPalScriptProvider>

        </>
    )
}

export default memo(Paypal)