import React from 'react';
import { Outlet } from 'react-router-dom';

const Forms = () => {
    return (
        <>
            <main className='forms_'>


                <Outlet />
                
            </main>
        </>
    )
}

export default Forms