import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTables from '../../Datatable/DataTables';
import { FaTrashAlt, FaEye } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StarBorderSharp } from '@mui/icons-material';
// import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
const Form4 = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);


    const getProduct = () => {
        axios.get(`${api_url}/getAllHappinessDetails`).then((response) => {
            setData(response.data.data);

            console.log(response.data.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }


    const successRefundNotify = () => toast.success("Refund Successful", { position: "top-center" });
    const successCancel = () => toast.success("Product Cancel Successfully", { position: "top-center" });
    const errMsg = (err) => toast.error(`${err}`, { position: "top-center" });


    const handleRefund = (id) => {
        setPending(true);
        axios.post(`${api_url}/formFourRefund`, {
            id: id,

        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                successRefundNotify();
                setPending(false);
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            errMsg("Something went wrong");
            console.log(err);
        })
    }

    const handleCancel = (id) => {
        setPending(true);
        axios.post(`${api_url}/cancelFormFour`, {
            id: id,

        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                successCancel();
                setPending(false);
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            errMsg(err.data.message);
            console.log(err);
        })
    }





    const tableData = {
        columns: [
            {
                name: '#',
                // width: '50px',
                cell: (row, index) => index + 1
            },

            {
                name: 'Name',
                selector: 'name',
                width: "150px",
            },

            {
                name: 'Phone Number',
                selector: 'phone',
                width: "150px",
            },

            {
                name: 'Email',
                selector: 'email',
                width: "150px",
            },
            {
                name: 'Modules',
                selector: 'module',
                width: "150px",
            },
            {
                name: 'Happiness Program',
                selector: 'happiness_programm',
                width: "150px",
            },
            {
                name: 'Amount',
                selector: 'amount',
                width: "150px",
            },
            {
                name: "Cancel",
                selector: row => <>
                    {row.cancel_status == 0 ?
                        <Button className='bg-danger border-0' onClick={() => handleCancel(row.id)}>Cancel</Button>
                        : row.cancel_status == 1 ? <span className='bg-danger text-white p-3'>Canceled</span> : null}
                </>,
                width: '150px',
            },

            {
                name: "Refund",
                selector: row => <>
                    {row.refund_status == 1 ?
                        <Button className='Add_btn_' onClick={() => handleRefund(row.id)}>Refund</Button>
                        : row.refund_status == 2 ? <span className='bg-success text-white p-3'>Refunded</span> : null}
                </>,
                width: '150px',
            },

            {
                name: "Action",
                selector: row => <div className='d-flex' style={{ gap: '10px' }}>
                    <Link to={`/admin/viewform4/${row.id}`} style={{float:"right"}}><Button className='Add_btn_' ><FaEye /></Button></Link> 
                </div>,
                right: true
            },

        ],

    };


    useEffect(() => {
        getProduct();
    }, []);










    return (
        <>
            
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section className="wrapper">

                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Happiness Programme Details</span>
                            <Link to="/form/4" target='_blank' className='text-danger'>click here to open form</Link>
                            <section className="card m-3">




                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />

                                </div>
                            </section>

                        </div>

                    </div>
                </section>
            </section>




        </>
    )
}

export default Form4