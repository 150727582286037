import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import NoImage from '../../../web/components/assets/Image_not_available.png';
const ViewForm3 = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const { id } = useParams();
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get(`${api_url}/getSingleSmoking/${id}`).then((response) => {
            setPending(false);
            setData(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);




    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Smoking</span>
                            <section className="card m-3">

                                <div className="card-body">
                                    <ToastContainer />
                                    <form className="row g-3" autocomplete='off'>
                                        {data?.map((Data) => {
                                            return (
                                                <>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" disabled className="form-control" value={Data.name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" disabled className="form-control" value={Data.email} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Phone</label>
                                                        <input type="text" disabled className="form-control" value={Data.phone} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Ref Id</label>
                                                        <input type="text" disabled className="form-control" value={Data.client_id} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Name</label>
                                                        <input type="text" disabled className="form-control" value={Data.name1} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Marital Status</label>
                                                        <input type="text" disabled className="form-control" value={Data.marital_status == 1 ? 'Single' : Data.marital_status == 2 ? 'Married' : Data.marital_status == 3 ? 'Widowed' : Data.marital_status == 4 ? 'Divorsed' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Date</label>
                                                        <input type="text" disabled className="form-control" value={Data.date1} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">DOB</label>
                                                        <input type="text" disabled className="form-control" value={Data.dob} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Number</label>
                                                        <input type="text" disabled className="form-control" value={Data.phone1} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Occupation</label>
                                                        <input type="text" disabled className="form-control" value={Data.occuption} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Smoke In a Day</label>
                                                        <input type="text" disabled className="form-control" value={Data.cigarettes_count} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Reason Of Smoke</label>
                                                        <input type="text" disabled className="form-control" value={Data.smoke_reason} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Address</label>
                                                        <input type="text" disabled className="form-control" value={Data.address} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Years Of Smoking</label>
                                                        <input type="text" disabled className="form-control" value={Data.years} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">I will Smoke</label>
                                                        <textarea disabled  className="form-control">
                                                        {Data.smoke}
                                                        </textarea>
                                                       
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Stop Reason</label>
                                                        <textarea disabled  className="form-control">
                                                        {Data.stop_reason}
                                                        </textarea>
                                                       
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Stop Smoking Reason</label>
                                                        <input type="text" disabled className="form-control" value={Data.stop_smoke_reason} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Medication</label>
                                                        <input type="text" disabled className="form-control" value={Data.medication} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label"> Care Of a Doctor</label>
                                                        <input type="text" disabled className="form-control" value={Data.doctor} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Doctor Recommend</label>
                                                        <input type="text" disabled className="form-control" value={Data.doctor_recommend} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">About</label>
                                                        <input type="text" disabled className="form-control" value={Data.about} />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label">Amount</label>
                                                        <input type="text" disabled className="form-control" value={Data.amount} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Transaction Id</label>
                                                        <input type="text" disabled className="form-control" value={Data.tnx_id} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Refund Status</label>
                                                        <input type="text" disabled className="form-control" value={Data.refund_status == 1 ? 'Initiate' : Data.refund_status == 2 ? 'Complete' : 'Pending'} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Signature</label>
                                                        <img src={Data.client_signature ? `assets/signatures/${Data.client_signature}` : NoImage} alt="image" width={400} height={100}/>
                                                     
                                                    </div>

                                                </>
                                            )
                                        })}

                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default ViewForm3;