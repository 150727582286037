import { AiFillDashboard, AiOutlineForm } from 'react-icons/ai';
import { IoImageSharp } from 'react-icons/io5';
import { FaMicroblog, FaProductHunt } from 'react-icons/fa';
import { RiPsychotherapyFill } from 'react-icons/ri';
import { MdFmdBad, MdContactPhone, MdGroups2, MdMarkEmailUnread } from 'react-icons/md';
import { SiHypothesis, SiGnuprivacyguard } from 'react-icons/si';
import { BiUserCheck } from 'react-icons/bi';
export const SidebarItems = [
    {
        title: "Dashboard",
        url: "/admin",
        icon: <AiFillDashboard />,
    },
    {
        title: "Appointments",
        url: "/admin/appointments",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Cancel Request",
        url: "/admin/cancel-request",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Enquiries",
        url: "/admin/enquiries",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Products sold",
        url: "/admin/products-sold",
        icon: <FaMicroblog />,
    },
    {
        title: "Consultant",
        url: "/admin/consultants",
        icon: <BiUserCheck />,
    },
    {
        title: "Slots",
        url: "/admin/slots",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Products",
        url: "/admin/product",
        icon: <FaMicroblog />,
    },
    {
        title: "Therapies",
        url: "/admin/therapies",
        icon: <RiPsychotherapyFill />
    },
    {
        title: "Hypnosis",
        url: "/admin/hypnosis",
        icon: <RiPsychotherapyFill />
    },
    {
        title: "The Control system",
        url: "/admin/the-control-system",
        icon: <RiPsychotherapyFill />
    },
    {
        title: "Banner",
        url: "/admin/banner",
        icon: <IoImageSharp />
    },
    {
        title: "Blog",
        url: "/admin/blog",
        icon: <FaMicroblog />,
    },
    {
        title: "About Us",
        url: "/admin/about",
        icon: <MdFmdBad />
    },
    {
        title: "Client Reviews",
        url: "/admin/client",
        icon: <MdGroups2 />
    },
    {
        title: "The Pathway Still",
        url: "/admin/the-pathway-still",
        icon: <RiPsychotherapyFill />
    },
    // {
    //     title: "Consessions",
    //     url: "/admin/consessions",
    //     icon: <SiHypothesis />
    // },
    // Privacy Policy
    {
        title: "Privacy Policy",
        url: "/admin/privacy-policy",
        icon: <SiGnuprivacyguard />
    },
    {
        title: "Confidential Form",
        url: "/admin/form2",
        icon: <AiOutlineForm />
    },
    {
        title: "Smoking Form",
        url: "/admin/form3",
        icon: <AiOutlineForm />
    },
    {
        title: "Happiness Details Form",
        url: "/admin/form4",
        icon: <AiOutlineForm />
    },
    {
        title: "Optional Modules Form",
        url: "/admin/form5",
        icon: <AiOutlineForm />
    },
    {
        title: "Weight Release Form",
        url: "/admin/form6",
        icon: <AiOutlineForm />
    },
]