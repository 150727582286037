
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Backend from './admin/Backend';
import Dashboard from './admin/pages/Dashboard';
import AdminAbout from './admin/pages/About';
import AdminBlog from './admin/pages/Blog';
import AdminBanner from './admin/pages/Banner';
import AdminProduct from './admin/pages/Product';
import AdminClient from './admin/pages/Client';
import AdminContact from './admin/pages/Contact';
import AdminTherapies from './admin/pages/Therapies';
import Adminhyponsis from './admin/pages/Hypnosis';
import AdminProfile from './admin/pages/Profile';
import AdminSetting from './admin/pages/Setting'
import AddHypnosis from './admin/pages/AddHypnosis'
import UpdateHypnosis from './admin/pages/UpdateHypnosis';
import ProductsSold from './admin/pages/ProductsSold';
import Consultant from './admin/pages/ConsultantsAdmin';
import SlotsAdmin from './admin/pages/SlotsAdmin';
import TheControlSystemAdmin from './admin/pages/TheControlSystemAdmin';
import ThePathwayStillAdmin from './admin/pages/ThePathwayStillAdmin';
import ConcessionAdmin from './admin/pages/Consessions';
import PrivacyPolicies from './admin/pages/PrivacyPolicies'

import './web/App.css';
import Frontend from './web/Frontend';
import About from './web/components/pages/About';
import Blog from './web/components/pages/Blog';

import ContactUs from './web/components/pages/ContactUs';
import Home from './web/components/pages/Home';
import Shop from './web/components/pages/Shop';
import SingleBlog from './web/components/pages/SingleBlog';
import Therapies from './web/components/pages/Therapies';
import ThePathwayControlSystem from '../components/web/components/pages/ThePathwayControlSystem';
import ThePathwayStill from '../components/web/components/pages/ThePathwayStill';
import ClientReviews from './web/components/pages/ClientReviews';
import Login from './admin/pages/Login/Login';
import SingleTherapies from './web/components/pages/SingleTherapies';
import Product from './web/components/pages/Product';
import Checkout from './web/components/pages/Checkout';
import ClientContract from './web/components/forms/client-contract';
import Forms from './web/Forms';
import HappinessProgram from './web/components/forms/the-control-system-happiness-programme-details';
import ConfidentialForm from './web/components/forms/ConfidentialForm';
import StopSmoking from './web/components/forms/stop-smoking';
import WeightReleaseProgramme from './web/components/forms/weight-release-happiness-programme-details';
import WeighReleaseQuestionnaire from './web/components/forms/WeighReleaseQuestionnaire';
import Stepper from './web/components/common/stepper/Stepper';
import Concessions from './web/components/pages/Concessions';
import PrivacyPolicy from './web/components/pages/PrivacyPolicy';
import Success from './web/components/common/stepper/Success';
import Appointments from './admin/pages/Appointments';
import Form2 from './admin/pages/Forms/Form2';
import Form3 from './admin/pages/Forms/Form3';
import Form4 from './admin/pages/Forms/Form4';
import Form6 from './admin/pages/Forms/Form6';
import Form5 from './admin/pages/Forms/Form5';
import ViewForm2 from './admin/pages/Forms/ViewForm2';
import ViewForm3 from './admin/pages/Forms/ViewForm3';
import ViewForm4 from './admin/pages/Forms/ViewForm4';
import ViewForm6 from './admin/pages/Forms/ViewForm6';
import ViewForm5 from './admin/pages/Forms/ViewForm5';
import CancelRequest from './admin/pages/CancelRequest';
import Error from '../components/web/components/common/Error';

function App() {


  let auth = localStorage.getItem("auth");
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Frontend />}>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/the-control-system' element={<ThePathwayControlSystem />} />
            <Route exact path='/the-pathway-still' element={<ThePathwayStill />} />
            <Route exact path='/client-reviews' element={<ClientReviews />} />
            <Route exact path='/checkout' element={<Checkout />} />
            <Route exact path='/therapies' element={<Therapies />} />
            <Route exact path='/therapies/:name/:id' element={<SingleTherapies />} />

            <Route exact path='/Success/:id' element={<Success />} />
            <Route exact path='/shop' element={<Shop />} />
            <Route exact path='/shop/:name/:id' element={<Product />} />
            <Route exact path='/blog' element={<Blog />} />
            <Route exact path='/concessions' element={<Concessions />} />
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='/appointment' element={<Stepper />} />
            <Route exact path='/blog/:name/:id' element={<SingleBlog />} />
            <Route exact path='/contact' element={<ContactUs />} />
            <Route path='*' element={<Error />} />


          </Route>

          {auth ?

            <Route exact path='/admin' element={<Backend />}>
              <Route index element={<Dashboard />} />
              <Route exact path='/admin/therapies' element={<AdminTherapies />} />
              <Route exact path='/admin/product' element={<AdminProduct />} />
              <Route exact path='/admin/banner' element={<AdminBanner />} />
              <Route exact path='/admin/blog' element={<AdminBlog />} />
              <Route exact path='/admin/enquiries' element={<AdminContact />} />
              <Route exact path='/admin/hypnosis' element={<Adminhyponsis />} />
              <Route exact path='/admin/client' element={<AdminClient />} />
              <Route exact path='/admin/about' element={<AdminAbout />} />
              <Route exact path='/admin/profile' element={<AdminProfile />} />
              <Route exact path='/admin/setting' element={<AdminSetting />} />
              <Route exact path='/admin/addhypnosis' element={<AddHypnosis />} />
              <Route exact path='/admin/updatehypnosis/:id' element={<UpdateHypnosis />} />
              <Route exact path='/admin/appointments' element={<Appointments />} />
              <Route exact path='/admin/cancel-request' element={<CancelRequest />} />
              <Route exact path='/admin/products-sold' element={<ProductsSold />} />
              <Route exact path='/admin/consultants' element={<Consultant />} />
              <Route exact path='/admin/slots' element={<SlotsAdmin />} />
              <Route exact path='/admin/the-control-system' element={<TheControlSystemAdmin />} />
              <Route exact path='/admin/the-pathway-still' element={<ThePathwayStillAdmin />} />
              <Route exact path='/admin/consessions' element={<ConcessionAdmin />} />
              <Route exact path='/admin/privacy-policy' element={<PrivacyPolicies />} />
              <Route exact path='/admin/form2' element={<Form2 />} />
              <Route exact path='/admin/form3' element={<Form3 />} />
              <Route exact path='/admin/form4' element={<Form4 />} />
              <Route exact path='/admin/form5' element={<Form5 />} />
              <Route exact path='/admin/form6' element={<Form6 />} />
              <Route exact path='/admin/viewform2/:id' element={<ViewForm2 />} />
              <Route exact path='/admin/viewform3/:id' element={<ViewForm3 />} />
              <Route exact path='/admin/viewform4/:id' element={<ViewForm4 />} />
              <Route exact path='/admin/viewform5/:id' element={<ViewForm5 />} />
              <Route exact path='/admin/viewform6/:id' element={<ViewForm6 />} />

            </Route>
            :
            <Route exact path='/admin' element={<Login />} />

          }

          <Route exact path='/form' element={<Forms />}>
            <Route exact path='/form/1' element={<ClientContract />} />
            <Route exact path='/form/2' element={<ConfidentialForm />} />
            <Route exact path='/form/3' element={<StopSmoking />} />
            <Route exact path='/form/4' element={<HappinessProgram />} />
            <Route exact path='/form/5' element={<WeightReleaseProgramme />} />
            <Route exact path='/form/6' element={<WeighReleaseQuestionnaire />} />



          </Route>

        </Routes>
      </BrowserRouter>

    </React.Fragment >

  );
}

export default App;
