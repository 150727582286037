import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from '@mui/material/Avatar';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import PersonIcon from '@mui/icons-material/Person';
// import CollectionsIcon from '@mui/icons-material/Collections';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { SidebarItems } from './pages/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
// import Collapse from '@mui/material/Collapse';
// import StarBorder from '@mui/icons-material/StarBorder';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




export default function Backend() {

  const api_url = process.env.REACT_APP_API_URL;
  console.log("api url:",api_url);
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [action, setAction] = React.useState(true);

  const handleOprnClick = () => {
    setAction(!action);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const navigate = useNavigate();
  const handleClose = (e) => {

    setAnchorEl(null);
  };
  const navigate = useNavigate();


  const handleLogOut = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem('adminAuth');
    navigate('/admin');
    window.location.reload(false);


  }
  const handleProfile = () => {
    navigate('/admin/profile');

  }
  const handleSettings = () => {

    navigate('/admin/setting');


  }
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState('');
  const [confidential, setConfidential] = useState('');
  const [happiness, setHappiness] = useState('');
  const [optional, setOptional] = useState('');
  const [smoking, setSmoking] = useState('');
  const [weight, setWeight] = useState('');
  const [appointmentCount, setAppointmentCount] = useState('');
  const [cancelCount, setCancelCount] = useState('');


  const getData = () => {

    console.log("api url:",api_url);
    axios.get(`${api_url}/adminProfile`).then((response) => {
      setLogo(response.data.data[0].logo);
    }).catch((err) => console.log(err));

    axios.get(`${api_url}/getConfidentialCount`, { headers: { "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05" } })
      .then((response) => setConfidential(response.data.NewConfidential));
   axios.get(`${api_url}/getHappinessCount`, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      //console.log(response.data);
      setHappiness(response.data.NewHappiness);
    })
   axios.get(`${api_url}/getOptionalCount`, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {

      setOptional(response.data.NewOptional);
    })
   axios.get(`${api_url}/getSmokingCount`, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {

      setSmoking(response.data.NewSmoking);
    })
   axios.get(`${api_url}/getWeightCount`, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {

      setWeight(response.data.NewWeight);
    })
   axios.get(`${api_url}/getApointcount`, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {

      setAppointmentCount(response.data.data)

    })

   axios.get(`${api_url}/getCancelcount`, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      setCancelCount(response.data.data);
      // setWeight(response.data.NewWeight);
    })

    // getApointcount
  }

  useEffect(() => {
    getData();
  }, []);


  const handleConfidentialCount = () => {

    if (window.innerWidth < 992) {
      handleDrawerClose();
    }

    axios.post(`${api_url}/confidentialCountStatus`, {
      // id: 0,
    }, {
      method: "POST",
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05",

      }
    }).then((response) => {

      setConfidential('');

    });


  }

  const handleHappinessCount = () => {

    axios.post(`${api_url}/happinessCount`, {
      // id: 0,
    }, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      if (response.status === 200) {
        setHappiness('');
      }
    });

  }

  const handleReadCancelCount = () => {

   axios.get(`${api_url}/readCancelcount`, {
      // id: 0,
    }, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      if (response.status === 200) {
        setCancelCount('');
      }
    });

  }

  const handleReadAppointmentCount = () => {

   axios.get(`${api_url}/readApointcount`, {
      // id: 0,
    }, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      if (response.status === 200) {
        setAppointmentCount('');
      }
    });

  }
  // readCancelcount

  const handleOptionalCount = () => {

    axios.post(`${api_url}/optionalCountStatus`, {
      // id: 0,
    }, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      if (response.status === 200) {
        setOptional('');
      }
    });

  }
  const handleSmokingCount = () => {

    axios.post(`${api_url}/smokingCount`, {
      // id: 0,
    }, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      if (response.status === 200) {
        setSmoking('');
      }
    });

  }
  const handleWeightCount = () => {

    axios.post(`${api_url}/weightCountStatus`, {
      // id: 0,
    }, {
      headers: {
        "token": "7c6900bba1a396dd8db846a386b0cd478a3c4c05"
      }
    }).then((response) => {
      if (response.status === 200) {
        setWeight('');
      }
    });

  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <div className='left_h d-flex align-items-center'>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div"> */}
            {open ? null : <img src={`assets/logo/${logo}`} width="60px" alt="image" />}
            {/* </Typography> */}
          </div>

          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openMenu ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
            >

              <Avatar sx={{ width: 32, height: 32 }} >A</Avatar>


            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >

            <MenuItem onClick={handleProfile}>
              <Avatar /> Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleSettings}>
              <ListItemIcon >
                <Settings fontSize="small" className='text-black' />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout fontSize="small" className='text-black' />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        className="scroll_"
      >
        <DrawerHeader className='justify-content-between'>
          <img src={`assets/logo/${logo}`} width="60px" alt="image" />
          <IconButton onClick={handleDrawerClose} className="nav_bar">
            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: '#fff' }} /> : <ChevronRightIcon />}
          </IconButton>

        </DrawerHeader>
        <Divider />
        <List>
          {SidebarItems.map((data) => (
            <>
              <NavLink end to={`${data.url}`} onClick={data.title == 'Confidential Form' ? handleConfidentialCount : data.title == "Appointments" ? handleReadAppointmentCount : data.title == "Cancel Request" ? handleReadCancelCount : data.title == 'Smoking Form' ? handleSmokingCount : data.title == 'Happiness Details Form' ? handleHappinessCount : data.title == 'Optional Modules Form' ? handleOptionalCount : data.title == 'Weight Release Form' ? handleWeightCount : window.innerWidth < 992 ? handleDrawerClose : null} activeClassName="active">
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {data.icon}
                    </ListItemIcon>
                    <ListItemText primary={data.title} />{data.title == 'Confidential Form' ? <span class={confidential == "" ? "d-none" : "icon-button__badge"}>{confidential}</span> : data.title == 'Smoking Form' ? <span class={smoking == "" ? "d-none" : "icon-button__badge"}>{smoking}</span> : data.title == 'Happiness Details Form' ? <span class={happiness == "" ? "d-none" : "icon-button__badge"}>{happiness}</span> : data.title == 'Optional Modules Form' ? <span class={optional == "" ? "d-none" : "icon-button__badge"}>{optional}</span> : data.title == 'Weight Release Form' ? <span class={weight == "" ? "d-none" : "icon-button__badge"}>{weight}</span> :
                      data.title == 'Appointments' ? <span class={appointmentCount == "" ? "d-none" : "icon-button__badge"}>{appointmentCount}</span> : data.title == 'Cancel Request' ? <span class={cancelCount == "" ? "d-none" : "icon-button__badge"}>{cancelCount}</span> :
                        null}

                  </ListItemButton>

                </ListItem>
              </NavLink>

            </>
          ))}


        </List>

      </Drawer>
      <Main open={open} className='admin_main' >
        <DrawerHeader />

        <Outlet />


        {/* <Dashboard /> */}


        <center className='pt-5'>
          <p>
            Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved
          </p>
        </center>
      </Main>

    </Box>

  );
}

