import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTables from '../Datatable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StarBorderSharp } from '@mui/icons-material';
// import { Helmet } from 'react-helmet';

const Clients = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [clients, setClients] = useState([]);
  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleUpdate = () => setShowUpdate(false);
  const handleClose = () => {
    setDeleteShow(false);
    setShowUpdate(false);
    setShow(false);
    setAddImage('');
    reset({
      name: "",
      image: "",
      content: "",
      title: ""
    })
  }
  const [pending, setPending] = useState(true);

  const [data, setData] = useState([]);

  // add
  const [title, setTitle] = useState('');
  const [addImage, setAddImage] = useState('');
  const [content, setContent] = useState('');
  const [name, setName] = useState('');
  const [star, setStar] = useState('');


  // update
  const [Id, setId] = useState('');
  const [updateImg, setUpdateImg] = useState('');
  const [updateContent, setUpdateContent] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [updatedName, setUpdatedName] = useState('');
  const [updatedStar, setUpdatedStar] = useState('');


  const [deleteIds, setDeleteIds] = useState('');

  const getTherapies = () => {
    axios.get(`${api_url}/getclient`).then((response) => {
      setClients(response.data.data);

      console.log(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const handleShow = () => {
    setShow(true);
    // getProductName();

  };
  const handleDeleteShow = (e) => {
    setDeleteShow(true);
    setDeleteIds(e);

  };

  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`${api_url}/singleClient/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }

  useEffect(() => {
    data?.map((data) => {

      setUpdateImg(data.image);
      setUpdateContent(data.content);
      setUpdatedStar(data.star);
      setUpdatedName(data.name);
      setId(data.id);
      setUpdatedTitle(data.title);
    })

  }, [data])



  const notifyStatus = () => toast.success('Client status change Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.post(`${api_url}/clientStatus`, {
      id: id,
      status: status,
    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyStatus();
        window.location.reload(false);
      }
    }).catch((err) => {

      console.log(err);
    })
  }


  const tableData = {
    columns: [
      {
        name: '#',
        // width: '50px',
        cell: (row, index) => index + 1
      },

      {
        name: 'Name',
        selector: 'name',
        width: "150px",
      },

      {
        name: 'Type',
        cell: (row) => <>{row.type == 1 ? 'video' : row.type == 2 ? 'Review' : null}</>,
        width: "150px",
      },

      {
        name: 'Title',
        selector: 'title',
        width: "150px",
      },

      {
        name: 'Star',
        selector: 'star',
        width: "150px",
      },


      // {
      //   name: "Image",
      //   selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/clients/${row.image}`} />,
      //   sortable: true,
      //   width: '150px',
      // },
      {
        name: 'Content',
        selector: 'content',
        width: "300px",
      },

      {
        name: 'Video',
        selector: 'video',
        width: "300px",
      },

      {
        name: "Status",
        selector: row => <>
          <Form.Select aria-label="Default select example"
            value={row.status} id={row.id}
            onChange={(e) => handleStatus(e)}
          >

            <option value="0">Active</option>
            <option value="1">In-Active</option>

          </Form.Select>

        </>,
        width: '200px'

      },

      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
         </div>,
        right: true
      },

    ],

  };


  useEffect(() => {
    getTherapies();
  }, []);

  const notifyUpdate = () => toast.success('Client update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notify = () => toast.success('Client add Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = () => {
    setPending(true)
    axios.post(`${api_url}/addNewClient`, {
      title: title,
      image: '',
      content: content,
      star: star,
      name: name,

    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        handleClose();
        setPending(false);
        notify();
        window.location.reload(false);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    formData.append("file", updatedImage);
    axios.post(`${api_url}/updateClient`, {
      id: Id,
      image: '',
      content: updateContent,
      title: updatedTitle,
      name: updatedName,
      star: updatedStar
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        handleUpdate();
        setPending(false);
        notifyUpdate();
        window.location.reload(false);

      }
    }).catch((err) => {

      console.log(err);
    })


  }

  return (
    <>
      {/* <Helmet>
                <title>LoanWale | Loan</title>
            </Helmet>  */}

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        <ToastContainer />
        <section className="wrapper">

          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Clients</span>
              <section className="card m-3">

                <div className='p-2' >
                  <Button className='Add_btn_' style={{ float: "right" }}
                    onClick={handleShow}
                  >Add Clients</Button>
                </div>


                <div className="main">
                  <DataTables data={clients} columns={tableData.columns} />

                </div>
              </section>

            </div>

          </div>
        </section>
      </section>
      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Clients</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Modal.Body>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Title"
                {...register("title", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.title && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name} placeholder="Enter Name"
                {...register("name", {
                  required: true,
                  onChange: (e) => setName(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Name</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Star</Form.Label>
              <Form.Control
                value={star} placeholder="Enter Star"
                {...register("star", {
                  required: true,
                  onChange: (e) => setStar(e.target.value)
                })}
              />
              {errors.star && <div className='errMsg pt-2'>Please Provide Star</div>}
            </Form.Group>


            {/* <Form.Group
              className="mb-3"

            >

              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                {...register("image", {
                  required: false,
                  onChange: (e) => setAddImage(e.target.files[0])
                })} />
              {errors.image && <div className='errMsg pt-2'>Please Provide Image</div>}

              <div className='text-center p-3'>
                <img src={addImage ? URL.createObjectURL(addImage) : ""} style={{ maxWidth: "300px", maxHeight: "300px" }} />
              </div>

            </Form.Group> */}

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Content</Form.Label>
              <Form.Control as="textarea" rows={3}
                placeholder="Enter Content"
                value={content} maxLength={300}
                {...register("content", {
                  required: "Please Provide Content",

                  onChange: (e) => setContent(e.target.value)
                })}
              />
              {errors.content && <div className='errMsg pt-2'>{errors.content.message}</div>}
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Clients</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>



            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Star</Form.Label>
              <Form.Control
                value={updatedStar}
                onChange={(e) => setUpdatedStar(e.target.value)}
              />
            </Form.Group>


            {/* <Form.Group
              className="mb-3"

            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                onChange={(e) => setUpdatedImage(e.target.files[0])}

              />

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/clients/${updateImg}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

            </div> */}


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Content</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={updateContent}
                onChange={(e) => setUpdateContent(e.target.value)}
              />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



    </>
  )
}

export default Clients