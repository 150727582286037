import React, { useState, useEffect } from 'react';
import { RxTriangleRight } from 'react-icons/rx';
import { BiPhoneCall, BiMailSend } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube } from 'react-icons/fa';
import "./footer.css";
import axios from 'axios';
import { Link } from 'react-router-dom';


const Footer = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);

    const getData = () => {
        axios.get(`${api_url}/adminProfile`).then((response) => {
            console.log(response.data);
            setData(response.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);




    return (
        <>
            <footer className="footer-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        {data?.map((data) => {
                            return (
                                <>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-footer-widget">
                                            <div className="widget-logo">
                                                <Link to="/"><img src={`assets/logo/${data.logo}`} alt="image" width={100} /></Link>
                                            </div>
                                            <p>{data.about}</p>

                                            <div className="widget-share">
                                                <a href={data.facebook} target="_blank">
                                                    <i><FaFacebookF /></i>
                                                </a>
                                                <a href={data.instagram} target="_blank">
                                                    <i><FaInstagram /></i>
                                                </a>
                                                <a href={data.linkedIn} target="_blank">
                                                    <i><FaLinkedinIn /></i>
                                                </a>
                                                <a href={data.twitter} target="_blank">
                                                    <i><FaTwitter /></i>
                                                </a>
                                                <a href={data.youtube} target="_blank">
                                                    <i><FaYoutube /></i>
                                                </a>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-footer-widget">
                                            <h3>Quick Links</h3>

                                            <ul className="quick-links">
                                                <li>
                                                    <Link to="/"><i><RxTriangleRight className="_right_arrow" /></i>Home</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about"><i><RxTriangleRight className="_right_arrow" /></i>About</Link>
                                                </li>
                                                <li>
                                                    <Link to="/the-control-system"><i><RxTriangleRight className="_right_arrow" /></i>The Control System</Link>
                                                </li>
                                                <li>
                                                    <Link to="therapies"><i><RxTriangleRight className="_right_arrow" /></i>Therapies</Link>
                                                </li>
                                                <li>
                                                    <Link to="/the-pathway-still"><i><RxTriangleRight className="_right_arrow" /></i>The Pathway Still</Link>
                                                </li>
                                                <li>
                                                    <Link to="contact"><i><RxTriangleRight className="_right_arrow" /></i>Contact Us</Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-footer-widget">
                                            <h3>Explore</h3>

                                            <ul className="quick-links">
                                                <li>
                                                    <Link to="client-reviews"><i><RxTriangleRight className="_right_arrow" /></i>Client Reviews</Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/concessions"><i><RxTriangleRight className="_right_arrow" /></i>Concessions</Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/blog"><i><RxTriangleRight className="_right_arrow" /></i>Blog</Link>
                                                </li>
                                                <li>
                                                    <Link to="/privacy-policy"><i><RxTriangleRight className="_right_arrow" /></i>Privacy Policy</Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="#"><i><RxTriangleRight className="_right_arrow" /></i>Form</Link>
                                                </li>
                                                <li>
                                                    <Link to="#"><i><RxTriangleRight className="_right_arrow" /></i>Form</Link>
                                                </li>
                                                <li>
                                                    <Link to="#"><i><RxTriangleRight className="_right_arrow" /></i>Form</Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-footer-widget">
                                            <h3>Contact Us</h3>

                                            <ul className="footer-contact-info">
                                                <li>
                                                    <i><BiMailSend /></i>
                                                    <span>Email Address:</span>
                                                    <a href={`mailto:glen@thepathwayhypnotherapyrooms.co.uk`}><span className="__cf_email__" data-cfemail="">glen@thepathwayhypnotherapyrooms.co.uk</span></a>
                                                </li>
                                                <li>
                                                    <i><BiPhoneCall /></i>
                                                    <span>Phone No:</span>
                                                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                                                </li>
                                                <li>
                                                    <i><MdLocationOn /></i>
                                                    <span>Location:</span>
                                                    <a href="https://goo.gl/maps/PKUSRk8zNxwDHfwn7" target="_blank">{data.address}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </footer>

            <div className="copyright-area">
                <div className="container">
                    <div className="copyright-area-content">
                        <p>
                            Copyright &#169; {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer