import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';

const Error = () => {

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])

    return (
        <>
            <section class="error-area ptb-100">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content">
                                {/* <img src="images/images-404.png" alt="error"> */}

                                <h3>Error 404 : page not found</h3>


                                <Link to="/" class="default-btn">Go To Home <i><BsFillHandIndexThumbFill /></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Error