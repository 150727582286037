import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
const About = () => {

    const api_url = process.env.REACT_APP_API_URL;
    
    const [aboutData, setAboutData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [file, setFile] = useState('');
    const [pending, setPending] = useState(true);
    const [title, setUpdatedTitle] = useState('');

    // function handleChange(e) {
    //     setFile(URL.createObjectURL(e.target.files[0]));
    // }

    const notify = () => toast.success("Edit SuccessFully");

    const getData = () => {
        axios.get(`${api_url}/getAdminAbout`).then((response) => {
            setAboutData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        });
    }

    useEffect(() => {
        getData();

    }, [])
    useEffect(() => {

        aboutData?.map((admin) => {
            setUpdatedTitle(admin.title);
        })
    }, [aboutData])


    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const handleUpdate = () => {

        const formData = new FormData();
        formData.append("file", file);

        axios.post('/updateAbout', { description: updatedContent, image: file, title: title }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                window.location.reload(true);
            }

        })
    }

    let validationRules = { Today: { required: true } };
    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <ToastContainer />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="cardSectionBorder">
                                <span className='p-3'>About Us</span>
                                <div className='p-3'>
                                    <TextEditor data={aboutData?.map((data) => data.description).toString()} setUpdatedContent={setUpdatedContent} validationRules={validationRules} />
                                </div>
                                <div className='img_input p-3'>
                                    <Form>
                                        <Form.Group className="col-md-12 pb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                value={title} placeholder="Enter Title"
                                                {...register("name", {
                                                    required: true,
                                                    onChange: (e) => setUpdatedTitle(e.target.value)
                                                })}
                                            />
                                            {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >

                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
                                                </div>

                                                <div className='col-lg-6 text-center'>
                                                    <Form.Label>Image Preview</Form.Label>
                                                    <div></div>
                                                    {file == "" ?
                                                        <>
                                                            {aboutData?.map((img) => {
                                                                return (
                                                                    <img src={`/assets/about/${img.image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <img src={URL.createObjectURL(file)} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />

                                                    }

                                                </div>
                                            </div>


                                        </Form.Group>
                                    </Form>

                                </div>

                                <hr></hr>
                                <div>
                                    <Button className='Add_btn_' onClick={handleUpdate}>Update</Button>
                                </div>


                            </section>

                        </div>
                    </div>
                </section>







            </section>
        </>
    )
}

export default About