import React, { useState, useEffect } from 'react'
import { MdMarkEmailUnread } from 'react-icons/md';
import { BsFillCartCheckFill } from 'react-icons/bs';
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaShare, FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';


const Dashboard = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [appointments, setAppointments] = useState([])
  const [appointmentData, setAppointmentData] = useState([]);
  const [show, setShow] = useState(false);

  const [appointmentCount, setAppointmentCount] = useState('');
  const [enquiryCount, setEnquiryCount] = useState('');
  const [productsCount, setProductsCount] = useState('');
  const [ProductSoldCount, setProductSoldCount] = useState('')
  const [filterBy, setFilterBy] = useState('1');

  const [mySpinner, setMySpinner] = useState(false);
  const handleClose = () => {
    setShow(false);

  }
  const [pending, setPending] = useState(true);
  const [message, setMessage] = useState('');


  const [showReply, setShowReply] = useState(false);
  const [Id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [reply, setReply] = useState('');




  const notify = () => toast.info("Enquiry Reply SuccessFully", { position: "top-center" });

  const handleReplyShow = (id) => {
    setShowReply(true);
    data?.filter((data) => data.id === id).map((data) => {
      setId(data.id);
      setName(data.name);
      setEmail(data.email);
      setSubject(data.subject);
      setReply(data.replies);
    })

  }

  const handleReply = () => {
    setShowReply(false);
    setReply('');
    reset({
      replies: ""
    })
  }

  const getData = () => {
    axios.get(`${api_url}/getEnquiry`).then((response) => {
      setData(response.data.data);
      setNewData(response.data.data);
      setPending(false);
      console.log(response.data.data);

    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

    axios.get(`${api_url}/getcount`).then((response) => {
      // setData(response.data.data);
      // setNewData(response.data.data);
      // setPending(false);
      setAppointmentCount(response.data.Appointment);
      setEnquiryCount(response.data.Enquiry)
      setProductsCount(response.data.Products);
      setProductSoldCount(response.data.Productsold)

    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
    // getcount
    axios.get(`${api_url}/upcomingAppointment/${filterBy}`).then((response) => {
      setAppointments(response.data.data);
      setAppointmentData(response.data.data);
      setPending(false);
      console.log(response.data.data);

    }).catch((err) => {
      setPending(false);
      console.log(err);
    })


    // getAllAppointment

  }

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const handleShow = (msg) => {
    setShow(true);
    setMessage(msg);
  }

  // const handleStatus = (e) => {
  //   let id = e.target.id;
  //   let status = e.target.value;
  //   axios.post('/enquiryStatus', {
  //     id: id,
  //     status: status,
  //   }, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     }
  //   }).then((response) => {
  //     if (response.status === 200) {
  //       window.location.reload(false);
  //       console.log(response.data);
  //       statussuccess();
  //       setMySpinner(false);
  //     }
  //   }).catch((err) => {
  //     setMySpinner(false);
  //     console.log(err);
  //     CustomerExist();
  //   })


  // }

  const successNotify = () => toast.success("Successfully status changed", { position: "top-center" });
  // const replyNotify = () => toast.error("Successfully sent reply", { position: "top-center" });
  // const statussuccess = () => toast.info("Enquiry Status update Successfully", { position: "top-center" });

  const tableData = {
    columns: [
      {
        name: '#',
        cell: (row, index) => index + 1
      },
      {
        name: "Name",
        selector: "name",
        width: '150px',

      },
      {
        name: "Email",
        selector: "email",
        width: '250px',

      },


      {
        name: "Phone",
        selector: "phone",
        width: '150px',

      },
      {
        name: "Subject",
        selector: "subject",

        width: '150px',
      },
      {
        name: "Message",
        selector: "message",

        width: '150px',
      },

      {
        name: "Action",
        selector: row => <Button className='Add_btn_'
          onClick={() => handleReplyShow(row.id)}
        ><FaShare /></Button>,
        right: true

      },

    ],

  };


  const handleStatus = (e) => {

    let id = e.target.id;
    let status = e.target.value;

    axios.post(`${api_url}/appointmentStatus`, {
      id: id,
      status: status,
    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        successNotify();
        window.location.reload(false);
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const AppointmentsTableData = {
    columns: [
      {
        name: '#',
        cell: (row, index) => index + 1
      },

      {
        name: "Unique Id",
        selector: "unique_id",
        width: '200px',
      },

      {
        name: "Name",
        selector: "name",
        width: '150px',

      },

      {
        name: "Service Type",
        selector: (row) => <>{row.service_type == 1 ? 'The Control System Consultation (2hrs)' : row.service_type == 2 ? 'The Control System (1hr)' :
          row.service_type == 3 ? 'Weight Release Programme 1st Session (2hrs)' : row.service_type == 4 ? 'Weight Release Sessions Further Sessions (1hr)' :
            row.service_type == 5 ? 'Free Consultation (15min)' : null}</>,
        width: '250px',

      },


      {
        name: "Email",
        selector: "email",
        width: '250px',

      },

      {
        name: "Phone",
        selector: "phone",
        width: '100px',

      },

      // {
      //   name: "Slot Id",
      //   selector: "slot_id",
      //   width: '150px',
      // },

      {
        name: "Date",
        selector: "dateSlotShow",
        width: '100px',
      },
      {
        name: "Time",
        selector: "timeSlotShow",

        width: '100px',
      },
      // {
      //   name: "Status",
      //   selector: row => <>
      //     <Form.Select aria-label="Default select example"
      //       value={row.status} id={row.id}
      //       onChange={(e) => handleStatus(e)}
      //     >

      //       <option value="1">Confirm</option>
      //       <option value="2">Reschedule</option>
      //       <option value="3">Cancelled</option>
      //       <option value="4">Complete</option>
      //     </Form.Select>

      //   </>,
      //   width: '200px'

      // },


      // {
      //   name: "Action",
      //   selector: row => <Button className='Add_btn_'
      //     onClick={() => handleReplyShow(row.id)}
      //   ><FaShare /></Button>,
      //   right: true

      // },

    ],

  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = () => {
    axios.post(`${api_url}/enquiryReply`, {
      id: Id,
      reply: reply,
      email: email,
      name: name,
      subject: subject,


    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }

    }).then((response) => {
      if (response.status === 200) {
        notify();
        handleReply();


      }
    })


  }

  const filterByStatus = (status) => {

    // setFilterBy
    setPending(true);
    axios.get(`${api_url}/upcomingAppointment/${status}`).then((response) => {
      setAppointments(response.data.data);
      setAppointmentData(response.data.data);
      setPending(false);
      console.log(response.data.data);

    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

    setFilterBy(status);


    // if (status == "") {
    //   setAppointments(appointmentData);
    // } else {
    //   setAppointments(appointmentData?.filter(data => data.status == status));
    // }

  }


  useEffect(() => {
    // setData(newData.filter(data => data.status == 0));

  }, []);


  return (
    <>
      <div className='d-flex justify-content-around align-items-center gap-4 pb-5 flex-wrap'>
        <div className="basic-card basic-card-dark">
          <div className="card-content d-flex justify-content-between align-items-center">
            <div>
              <span className="card-title">{enquiryCount}</span><br></br>
              <span className="card-title">Enquiries</span>
              <p className='card-text'>(Pending)</p>
            </div>
            <div className='card_icon'>
              <MdMarkEmailUnread />
            </div>
          </div>
        </div>

        <div className="basic-card basic-card-dark">
          <div className="card-content d-flex justify-content-between align-items-center">
            <div>
              <span className="card-title">{productsCount}</span><br></br>
              <span className="card-title">Products</span>
              <p className='card-text'>(Active)</p>
            </div>
            <div className='card_icon'>
              <BsFillCartCheckFill />
            </div>
          </div>
        </div>

        <div className="basic-card basic-card-dark">
          <div className="card-content d-flex justify-content-between align-items-center">
            <div>
              <span className="card-title">{appointmentCount}</span><br></br>
              <span className="card-title">Appointments</span>
              <p className='card-text'>(Upcoming)</p>
            </div>
            <div className='card_icon'>
              <MdMarkEmailUnread />
            </div>
          </div>
        </div>

        <div className="basic-card basic-card-dark">
          <div className="card-content d-flex justify-content-between align-items-center">
            <div>
              <span className="card-title">{ProductSoldCount}</span><br></br>
              <span className="card-title">Product Sold</span>
              <p className='card-text'>(Sold)</p>
            </div>
            <div className='card_icon'>
              <BsFillCartCheckFill />
            </div>
          </div>
        </div>
      </div>


      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

      <section id="main-content"
        style={pending ? { opacity: '0.5' } : { opacity: '1' }}
      >
        <ToastContainer />


        <section className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Upcoming Appointments</span>
              <section className="card m-3">

                <div className='d-flex mt-4 align-items-center justify-content-between'>
                  <div className="selectoption d-flex align-items-center">
                    <strong >Status :-</strong>
                    <select className="status select_status filter"
                      value={filterBy}
                      onChange={(e) => filterByStatus(e.target.value)}
                    >
                      <option value="1">Today</option>
                      <option value="2">Weekly</option>

                    </select>
                  </div>

                  <div className='p-2' >
                    <Link to="/admin/appointments" style={{ float: "right" }}><Button className='Add_btn_'
                    // onClick={handleShow}
                    >View All</Button></Link>
                  </div>
                </div>
                <div className="main">
                  <DataTables data={appointments?.slice(0, 5)} columns={AppointmentsTableData.columns} />

                </div>
              </section>
            </div>

          </div>
        </section>

        <section className="wrapper">
          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Enquiry</span>
              <section className="card m-3">

                <div className='d-flex mt-4 align-items-center justify-content-end'>
                  {/* <div className="selectoption d-flex align-items-center">
                    <strong >Status :-</strong>
                    <select className="status select_status filter"
                      onChange={(e) => filterByStatus(e.target.value)}
                    >
                      <option value="0">Pending</option>
                      <option value="1">Completed</option>
                      <option value="2">Rejected</option>
                      <option value="">All</option>
                    </select>
                  </div> */}

                  <div className='p-2' >
                    <Link to="/admin/enquiries" style={{ float: "right" }}><Button className='Add_btn_'
                    // onClick={handleShow}
                    >View All</Button></Link>
                  </div>
                </div>
                <div className="main">
                  <DataTables data={data?.slice(0, 5)} columns={tableData.columns} />
                  {/* </DataTableExtensions> */}
                </div>
              </section>
            </div>

          </div>
        </section>

      </section>


      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
        <Modal.Header className='justify-content-between'>
          <span>Message</span>
          <Button className='Add_btn_' onClick={() => handleClose()}><FaTimes /></Button>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Body>

      </Modal>


      <Modal show={showReply} onHide={handleReply} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Reply Enquiry</Modal.Title>

        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Modal.Body>

            <Form.Group className="mb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name"
                disabled
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Subject</Form.Label>
              <Form.Control type="text" placeholder="Enter Loan Name"
                disabled
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Reply</Form.Label>
              <Form.Control rows={5} as="textarea" placeholder="Type Your Message"
                value={reply}
                {...register("reply", {
                  required: true,
                  onChange: (e) => setReply(e.target.value)
                })}
              />
              {errors.reply && <div className='errMsg'>Please Enter Message</div>}
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleReply}>
              Close
            </Button>
            <Button className='Add_btn_' type='submit'>
              Reply
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    </>
  )
}

export default Dashboard