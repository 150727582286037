import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import HTMLRenderer from 'react-html-renderer';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { Button } from 'react-bootstrap';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';

const SingleTherapies = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();

    const [therapies, setTherapies] = useState([]);
    const [pending, setPending] = useState(false)
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');
    const getData = () => {


        axios.get(`${api_url}/singleTherapies/${id}`).then((response) => {

            setTherapies(response.data.data);
            setSeoContent(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [id])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);

    return (
        <>
            <Helmet>
            <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="blog-details-area " style={{ padding: "45px 0px" }}>
                <div className="container">

                    <div className="page-banner-content">
                        {/* <h2>Therapies</h2> */}
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/therapies">Therapies</Link>
                            </li>
                            <li>
                                {therapies[0]?.title}
                            </li>

                        </ul>
                    </div>
                    <div className="blog-details-desc pt-4">
                        {therapies?.map((therapy) => {
                            return (
                                <div className="article-content therapies-d">

                                    <div className="title-box">
                                        <h1>{therapy.title}</h1>
                                        {/* <div className="entry-meta">
                                        <ul>
                                            <li><i className="flaticon-calendar"></i> <a href="#">September 31, 2022</a></li>
                                            <li><i className="flaticon-user"></i> <a href="#">Angela Hopper</a></li>
                                            <li><i className="flaticon-chat-bubble"></i> <a href="#">03 Comments</a></li>
                                        </ul>
                                    </div> */}
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-6 col-12'>
                                            <div className="article-image pb-4">
                                                <img className='w-100' src={`${api_url}/assets/therapies/${therapy.image}`} alt="image" />
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 col-12'>

                                            <HTMLRenderer html={therapy.content} />

                                            <Button className="contact_btn default-btn"><Link to="/contact"><h2>Contact Us</h2><i><BsFillHandIndexThumbFill /></i></Link></Button>

                                        </div>

                                    </div>





                                    {/* <p>Unfortunately it is also the month where all of our best intentions fall by the wayside with us blaming ourselves for not having any WILLPOWER!</p>
    
                                <p>Well, below I will explain to you how you can achieve at least one of your New Years wishes, so read on and I am very much looking forward to speaking to you once you have finished reading!</p>
                                <p>Have you tried to lose weight, but each time you try, you end up falling back into your usual pattern of turning to food when in times of stress or extreme emotions? If your answer is yes to this question, keep reading the rest of this blog. It will provide you with the answers to these issues that sometimes feel insolvable.</p>
    
                                <p>Another question I have is, have you from a very young age used food as a treat or a way to make you feel better? There is a way to change this subconscious pattern of thoughts, keep reading.</p>
                                <p>Sometimes emotional eating can be linked to moments when you were young, where when you were feeling bad, food was used to bring a moment of temporary pleasure or joy that distracted you from what you were struggling with. By the time you get to the end of this blog, we will provide you with a proven way to change your response to emotional eating.
                                    When you were very young, you had no long term goals or aims, you were only concerned with feeling happy in the HERE and NOW. As an infant your problems were ‘I feel bad…give me something to make me feel better’ and the solution was a toy, a hug, a sweet or as a baby a dummy. Now you are an adult, this strategy of using food to make you feel better no longer works or serves you, as you always end up feeling worse thereafter! Keep reading, as there is a way to change this forever.
                                </p>
                                <p>Unfortunately (as you know now), the solutions of the child no longer solves the problems of the adult. Eating a piece of chocolate provides only moments of pleasure (often replaced by guilt) and the thing that was stressing you out or making you unhappy remains no nearer to a solution. If you want to escape this negative pattern and guilt, the next sentence will give you a way to change your life and achieve a happier future.</p> */}
                                    {/* <ul className="wp-block-gallery columns-3">
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img data-cfsrc="assets/images/blog/blog-4.jpg" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/blog-4.jpg" alt="image" /></noscript>
                                        </figure>
                                    </li>
    
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img data-cfsrc="assets/images/blog/blog-5.jpg" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/blog-5.jpg" alt="image" /></noscript>
                                        </figure>
                                    </li>
    
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img data-cfsrc="assets/images/blog/blog-6.jpg" alt="image" style={{ display: "none", visibility: "hidden" }} /><noscript><img src="images/blog-6.jpg" alt="image" /></noscript>
                                        </figure>
                                    </li>
                                </ul> */}

                                    {/* <h3>The Rise Of Marketing And Why You Need It</h3> */}

                                    {/* <p>If you have been programmed to emotionally eat from a young age, this has become a subconscious negative thought pattern that your mind relies on well into your adult years.  Below we will tell you about the approach we use at The Pathway Hypnotherapy Rooms that will free you of these negative thought patterns.
    
                                    At The Pathway Hypnotherapy Rooms, we use a combination of hypnosis and The CONTROL System to change negative thought patterns into thoughts that support your happiness rather than work against you.  In this process we talk directly to your subconscious or use hypnosis and help you to create new patterns of thoughts that can help you to achieve lasting weight loss and stop the self-sabotaging emotional eating that you currently struggle with.
    
                                    If you would like to achieve lasting weight loss and the size and shape you desire rather than the ongoing battle you are currently experiencing, then call us today – we can assist you in achieving your desired outcomes!’’</p> */}

                                    {/* <blockquote className="wp-block-quote">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </blockquote> */}

                                </div>
                            )
                        })}


                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleTherapies