import { Rating } from '@mui/material'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { AiOutlineShoppingCart, AiTwotoneCustomerService } from 'react-icons/ai';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import RelatedProduct from '../common/RelatedProduct';
import Spinner from 'react-bootstrap/Spinner';
import HTMLRenderer from 'react-html-renderer';
import { Helmet } from "react-helmet";

const Product = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();

    const [addToCart] = useOutletContext();
    const [product, setProduct] = useState([]);
    const [products, setProducts] = useState([]);
    // const [qty, setQty] = useState(1)
    const [pending, setPending] = useState(false)

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');
    const getData = () => {

        axios.get(`${api_url}/getAllProduct`).then((response) => {
            console.log(response.data.data)
            setProducts(response.data.data);
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

        axios.get(`${api_url}/singleProduct/${id}`).then((response) => {
            console.log(response.data.data)
            setProduct(response.data.data);
            setSeoContent(response.data.data);

            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, [id])

    const handleSEO = () => {
        seoContent?.map((seo) => {
    
          setRobot(seo.robot);
          setSeoTitle(seo.seo_title);
          setSeoKeyword(seo.seo_keyword)
          setSeoDes(seo.seo_description);
          setCanonical(seo.canonical)
          setSeoScript(seo.script);
          setFeatureImage(seo.feature_image);
        })
      }
    
      useEffect(() => {
        handleSEO();
      }, [seoContent]);

    const addTo = (id, name, qty, price, image) => {
        addToCart(id, name, qty, price, image)

    }


    const increment = () => {
        // let newQty = qty + 1;
        // let newPrice = parseInt(price)

        // handleCount(id, name, newQty, price)
        // setQty(newQty)
    }

    const decrement = () => {
        // let newQty = qty > 1 ? qty - 1 : 1
        // let newPrice = parseInt(price)

        // handleCount(id, service, newPrice, newCount)
        // setQty(newQty)
    }

    // singleProduct

    console.log()


    return (
        <>

<Helmet>
<link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
      </Helmet>
            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <section className="products-details-area " style={{ padding: '45px 0px' }}>
                <div className="container">

                    <div className="page-banner-content">
                        {/* <h2>Therapies</h2> */}
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            {/* <li>
                                <Link to="/shop">Product</Link>
                            </li> */}
                            <li>
                                {product[0]?.name}
                            </li>

                        </ul>
                    </div>

                    <div className="row m-0 pt-4">
                        {product?.map((product) => {
                            return (
                                <>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="products-details-slides">
                                            <div className="products-feedback">
                                                <div>
                                                    <div className="item">
                                                        <div className="products-image">
                                                            <img src={`/assets/product/${product.image}`} alt="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <div className="products-details-desc">
                                            <h1>{product.name}</h1>

                                            <p>
                                                <HTMLRenderer html={product.content.substring(0, 626)} />
                                            </p>

                                            <div className="price">
                                                <span className="new-price">&#163;{product.price}</span>
                                            </div>

                                            {/* <div className="products-review">
                                                <div className="rating">
                                                    <a href="#" className="rating-count">3 reviews</a>
                                                    <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                                                </div>
                                            </div> */}

                                            <div className="products-add-to-cart">
                                                {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == product.id) === false ?
                                                    <button type="submit" className="default-btn _submit_btn_p"
                                                        // disabled={qty === 0 ? true : false}
                                                        onClick={() => addTo(product.id, product.name, 1, product.price, product.image)}
                                                    >Add to Cart<i><AiOutlineShoppingCart /></i></button>
                                                    : <button type="submit" className="default-btn _submit_btn_p"
                                                        disabled
                                                    >Added to Cart<i><AiOutlineShoppingCart /></i></button>}

                                                {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == product.id) === false ? null :
                                                    <Link to="/checkout" className="optional-btn added_to_cart">Process To Checkout<i><AiOutlineShoppingCart /></i></Link>
                                                }
                                                {/* <div className="input-counter">
                                                    <span className="minus-btn"
                                                        onClick={decrement}
                                                    ><FaMinus /></span>
                                                    <input type="text" value={qty} onChange={(e) => setQty(e.target.value)} />
                                                    <span className="plus-btn"
                                                        onClick={increment}
                                                    ><FaPlus /></span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>

                    <div style={{ paddingTop: '45px' }}>
                        <RelatedProduct data={products.filter(data => data.id != id)} title="More Hypnosis Recordings" />
                    </div>
                    {/* <div className="products-details-tabs">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#description">Description</a></li>
                            <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#reviews">Reviews</a></li>
                        </ul>

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="description" role="tabpanel">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat.</p>
                            </div>

                            <div className="tab-pane fade" id="reviews" role="tabpanel">
                                <div className="products-reviews">
                                    <div className="review-content">
                                        <img src="assets/images/reviews/images-1.jpg" alt="image" />
                                        <h3>Angela Carter</h3>
                                        <span>12 Hours Ago</span>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                                        <div className="rating">
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bx-star'></i>
                                        </div>
                                    </div>

                                    <div className="review-content">
                                        <img src="assets/images/reviews/images-2.jpg" alt="image" />
                                        <h3>Jane Watson</h3>
                                        <span>12 Hours Ago</span>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                                        <div className="rating">
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bxs-star'></i>
                                            <i className='bx bx-star'></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="products-review-form">
                                    <div className="review-form">
                                        <h3>Leave A Rating</h3>

                                        <form>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" id="name" name="name" placeholder="Name" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" id="email" name="email" placeholder="Email" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="review-body" id="review-body" cols="30" rows="6" placeholder="Review" className="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="accept" />
                                                        <label className="form-check-label" htmlFor="accept">I Accept All <a href="terms-of-service.html">Terms & Condition</a></label>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12">
                                                    <button type="submit" className="default-btn">Submit Review <i className="flaticon-right-arrow"></i></button>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="rating">
                                            <i className='bx bx-star'></i>
                                            <i className='bx bx-star'></i>
                                            <i className='bx bx-star'></i>
                                            <i className='bx bx-star'></i>
                                            <i className='bx bx-star'></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                </div>


            </section>
        </>
    )
}

export default Product