import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'
import HTMLRenderer from 'react-html-renderer';
import Spinner from 'react-bootstrap/Spinner';

const Concessions = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [heroData, setHeroData] = useState([]);
  const [banner, setBanner] = useState('');
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false)

  const navigate = useNavigate();
  const getData = () => {

    axios.get(`${api_url}/getBanner/10`).then((response) => {
      console.log(response.data.data);
      setBanner(response.data.data[0].image)
      setHeroData(response.data.data)
    }).catch((err) => {
      console.log(err)
    });

    axios.get(`${api_url}/getprivacy`).then((response) => {
      console.log(response.data.data)
      setData(response.data.data);
      setPending(true)

    }).catch((err) => {
      console.log(err)
    });


  };

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
    if (window.location.hash.includes('##')) {
      navigate('/');
  }
  }, [])


  return (
    <>

      {pending ? null :
        <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
          <Spinner animation="border" variant="dark" className='my_spinner' />
        </div>}

      <div className="page-banner-with-full-image item-bg2" style={{ backgroundImage: `url(/assets/banner/${banner}` }}>
        <div className="container">
          <div className="page-banner-content-two">
            <h2>Concessions </h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Concessions </li>
            </ul>
          </div>
        </div>
      </div>
      {data?.map((data) => {
        return (
          <>
            <div className="blog-details-area" style={{ padding: "45px 0px" }}>
              <div className="container">
                <div className="blog-details-desc">
                  <div className="article-content">
                    <div className="title-box">
                      {/* <h2>{data.title}</h2> */}
                    </div>

                    <div className='row'>
                      {/* <div className='col-6'>
                  <div className="article-image pb-4">
                    <img className='w-100' src={`assets/blogs/${blog.image}`} alt="image" />
                  </div>
                </div> */}
                      <div className='col-12'>
                        <HTMLRenderer html={data.concession} />
                        {/* <p>{blog.content}</p> */}
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </>
        )
      })}





    </>
  )
}

export default Concessions