
import JoditEditor from 'jodit-react';
import { useRef, useState } from 'react';

const TextEditor = ({data, setUpdatedContent}) => {

  

    const editor = useRef(null);
    const config = {
        colorPickerDefaultTab: 'text',
        buttons: [
            
            'bold',
            'italic',
            'underline', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', 'align', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'video',
            'link', '|',
            'hr',
            'source', '|',
        ],
    }

    

    return (
        <>
            <JoditEditor
                ref={editor}
                config={config}
                value={data}
                onChange={newContent => {setUpdatedContent(newContent)}}

            />

            {/* {updatedContent} */}
        </>
    )
}

export default TextEditor