import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTables from '../Datatable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
const Hypnosis = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [therapies, setTherapies] = useState([]);
  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleUpdate = () => setShowUpdate(false);
  const handleClose = () => {
    setDeleteShow(false);
    setShowUpdate(false);
    setShow(false);
    setAddImage('');
    reset({
      name: "",
      image: "",
      content: "",
      title: ""
    })
  }
  const [pending, setPending] = useState(true);

  const [data, setData] = useState([]);

  // add
  const [title, setTitle] = useState('');
  const [addImage, setAddImage] = useState('');
  const [content, setContent] = useState('');


  // update
  const [Id, setId] = useState('');
  const [updateImg, setUpdateImg] = useState('');
  const [updateContent, setUpdateContent] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');



  const [deleteIds, setDeleteIds] = useState('');

  const getTherapies = () => {
    axios.get(`${api_url}/getAllHypnosis`).then((response) => {
      setTherapies(response.data.data);

      console.log(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const handleShow = () => {
    setShow(true);
    // getProductName();

  };
  const handleDeleteShow = (e) => {
    setDeleteShow(true);
    setDeleteIds(e);

  };

  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`${api_url}/singleHypnosis/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }

  useEffect(() => {
    data?.map((data) => {

      setUpdateImg(data.image);
      setUpdateContent(data.content);
      setId(data.id);
      setUpdatedTitle(data.title);
    })

  }, [data])






  const tableData = {
    columns: [
      {
        name: '#',
        // width: '50px',
        cell: (row, index) => index + 1
      },
      {
        name: 'Section',
        selector: 'section',
        width: "150px",
      },


      {
        name: 'Heading',
        selector: 'heading',
        width: "150px",
      },
      // {
      //   name: 'Description1',
      //   selector: 'description1',
      //   width: "150px",
      // },


      // {
      //   name: 'Description2',
      //   selector: 'description2',
      //   width: "150px",
      // },


      {
        name: "Image",
        selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/hypnosis/${row.image}`} alt="image" />,
        sortable: true,
        width: '150px',
      },
      // {
      //   name: 'URL',
      //   selector: 'url',
      //   width: "300px",
      // },

      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Link to={`/admin/updatehypnosis/${row.id}`} style={{ float: "right" }}><Button className='Add_btn_' ><FaEdit /></Button></Link>
        </div>,
        right: true
      },

    ],

  };


  useEffect(() => {
    getTherapies();
  }, []);

  const notifyUpdate = () => toast.info('Hyponsis update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notify = () => toast.info('Hyponsis add Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = () => {
    setPending(true)
    axios.post(`${api_url}/addNewTherapies`, {
      title: title,
      image: addImage,
      content: content,

    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setPending(false);
        notify();
        window.location.reload(false);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    formData.append("file", updatedImage);
    axios.post(`${api_url}/updateTherapies`, {
      id: Id,
      image: updatedImage,
      content: updateContent,
      title: updatedTitle
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }

  return (
    <>
      {/* <Helmet>
                <title>LoanWale | Loan</title>
            </Helmet>  */}

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        <ToastContainer />
        <section className="wrapper">

          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Hypnosis</span>
              <section className="card m-3">

                {/* <div className=' p-2' >
                                    <Link to='/admin/addhypnosis' style={{float:"right"}}><Button className='Add_btn_' >
                                        Add Hyponsis
                                    </Button></Link>
                                </div> */}


                <div className="main">
                  <DataTables data={therapies} columns={tableData.columns} />

                </div>
              </section>

            </div>

          </div>
        </section>
      </section>
      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Hypnosis</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Modal.Body>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Section</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Title"
                {...register("name", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Title"
                {...register("name", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Description1</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={title} placeholder="Enter Title"
                {...register("name", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Description2</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={title} placeholder="Enter Title"
                {...register("name", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>


            <Form.Group
              className="mb-3"

            >

              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                {...register("image", {
                  required: true,
                  onChange: (e) => setAddImage(e.target.files[0])
                })} />
              {errors.image && <div className='errMsg pt-2'>Please Provide Image</div>}

              <div className='text-center p-3'>
                <img src={addImage ? URL.createObjectURL(addImage) : ""} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />
              </div>

            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Content</Form.Label>
              <Form.Control as="textarea" rows={3}
                placeholder="Enter Content"
                value={content} maxLength={300}
                {...register("content", {
                  required: "Please Provide Content",

                  onChange: (e) => setContent(e.target.value)
                })}
              />
              {errors.content && <div className='errMsg pt-2'>{errors.content.message}</div>}
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Hypnosis</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>



            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"

            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                onChange={(e) => setUpdatedImage(e.target.files[0])}

              />

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/hypnosis/${updateImg}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />

            </div>


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Content</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={updateContent}
                onChange={(e) => setUpdateContent(e.target.value)}
              />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



    </>
  )
}

export default Hypnosis