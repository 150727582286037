import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import NoImage from '../../../web/components/assets/Image_not_available.png';
const ViewForm6 = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const { id } = useParams();
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get(`${api_url}/getSingleWeightRelease/${id}`).then((response) => {
            setPending(false);
            setData(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);




    return (
        <>
             <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Weight Release Questionnaire</span>
                            <section className="card m-3">

                                <div className="card-body">
                                    <ToastContainer />
                                    <form className="row g-3" autocomplete='off'>
                                        {data?.map((Data) => {
                                            return (
                                                <>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" disabled className="form-control" value={Data.name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" disabled className="form-control" value={Data.email} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Phone</label>
                                                        <input type="text" disabled className="form-control" value={Data.phone} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Approx Weight</label>
                                                        <input type="text" disabled className="form-control" value={Data.approx_weight} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Goal Weight</label>
                                                        <input type="text" disabled className="form-control" value={Data.goal_weight} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Ideal Weight</label>
                                                        <input type="text" disabled className="form-control" value={Data.ideal_weight} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Changed In Life</label>
                                                        <input type="text" disabled className="form-control" value={Data.what_changed} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Emotions</label>
                                                        <input type="text" disabled className="form-control" value={Data.what_emotions} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Breakfast</label>
                                                        <input type="text" disabled className="form-control" value={Data.breakfast} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Mid Morning</label>
                                                        <input type="text" disabled className="form-control" value={Data.mid_morning} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Lunch</label>
                                                        <input type="text" disabled className="form-control" value={Data.lunch} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Mid-afternoon</label>
                                                        <input type="text" disabled className="form-control" value={Data.mid_morn_after} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Evening meal</label>
                                                        <input type="text" disabled className="form-control" value={Data.evening_meal} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Supper</label>
                                                        <input type="text" disabled className="form-control" value={Data.Supper} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Other</label>
                                                        <input type="text" disabled className="form-control" value={Data.other} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Snack Between Meals</label>
                                                        <input type="text" disabled className="form-control" value={Data.snack} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">During the Night</label>
                                                        <input type="text" disabled className="form-control" value={Data.night_eat} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Over Eat Food</label>
                                                        <input type="text" disabled className="form-control" value={Data.overeat} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Drink Fizzy or Sweetened Drinks</label>
                                                        <input type="text" disabled className="form-control" value={Data.fizzy} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Day Alcohal</label>
                                                        <input type="text" disabled className="form-control" value={Data.alcohol_day} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Week Alcohol</label>
                                                        <input type="text" disabled className="form-control" value={Data.alcohol_week} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Approx Water</label>
                                                        <input type="text" disabled className="form-control" value={Data.approx_water} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Food Shopping in Your Household</label>
                                                        <input type="text" disabled className="form-control" value={Data.food_shopping} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Prepare Foods</label>
                                                        <input type="text" disabled className="form-control" value={Data.cook} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Leave Food</label>
                                                        <input type="text" disabled className="form-control" value={Data.plate} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Finish Food</label>
                                                        <input type="text" disabled className="form-control" value={Data.finish_food} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Enjoy Food List</label>
                                                        <textarea disabled  className="form-control">
                                                        {Data.enjoy_food_list}
                                                        </textarea>
                                                        
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Suggestion List</label>
                                                        <textarea disabled  className="form-control">
                                                        {Data.suggestion_list}
                                                        </textarea>
                                                       
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Suggestion</label>
                                                        <input type="text" disabled className="form-control" value={Data.suggestion} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Other OverWeight</label>
                                                        <input type="text" disabled className="form-control" value={Data.other_overweight} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Forced</label>
                                                        <input type="text" disabled className="form-control" value={Data.forced == 1 ? 'Yes' : Data.forced == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Reward</label>
                                                        <input type="text" disabled className="form-control" value={Data.reward == 1 ? 'Yes' : Data.reward == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Forget</label>
                                                        <input type="text" disabled className="form-control" value={Data.forget == 1 ? 'Yes' : Data.forget == 2 ? 'No' : null}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Feel Hungry as a Child</label>
                                                        <input type="text" disabled className="form-control" value={Data.hungry == 1 ? 'Yes' : Data.hungry == 2 ? 'No' : null}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Eat When You are Not Hungry</label>
                                                        <input type="text" disabled className="form-control" value={Data.not_hungry == 1 ? 'Yes' : Data.not_hungry == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Example</label>
                                                        <input type="text" disabled className="form-control" value={Data.example1} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Eat to Please Someone Else</label>
                                                        <input type="text" disabled className="form-control" value={Data.eat_someone == 1 ? 'Yes' : Data.eat_someone == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Example</label>
                                                        <input type="text" disabled className="form-control" value={Data.example2} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Thinking About the Next Meal</label>
                                                        <input type="text" disabled className="form-control" value={Data.meal == 1 ? 'Yes' : Data.meal == 2 ? 'No' : null}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Problematic Relationships in Your Life at Present</label>
                                                        <input type="text" disabled className="form-control" value={Data.problematic == 1 ? 'Yes' : Data.problematic == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Please State with Whom</label>
                                                        <input type="text" disabled className="form-control" value={Data.whom} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Relationship Improving</label>
                                                        <input type="text" disabled className="form-control" value={Data.relationship} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Sleep</label>
                                                        <input type="text" disabled className="form-control" value={Data.sleep} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Lead</label>
                                                        <input type="text" disabled className="form-control" value={Data.lead == 1 ? 'Yes' : Data.lead == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Sitting</label>
                                                        <input type="text" disabled className="form-control" value={Data.sitting == 1 ? 'Yes' : Data.sitting == 2 ? 'No' : null}/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Sport</label>
                                                        <input type="text" disabled className="form-control" value={Data.sport == 1 ? 'Yes' : Data.sport == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Question</label>
                                                        <input type="text" disabled className="form-control" value={Data.question} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Please Say</label>
                                                        <input type="text" disabled className="form-control" value={Data.please_say} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Convenient Time</label>
                                                        <input type="text" disabled className="form-control" value={Data.convenient} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Drugs</label>
                                                        <input type="text" disabled className="form-control" value={Data.drugs == 1 ? 'Yes' : Data.drugs == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Side Effects</label>
                                                        <input type="text" disabled className="form-control" value={Data.side_effects == 1 ? 'Yes' : Data.side_effects == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Consult</label>
                                                        <input type="text" disabled className="form-control" value={Data.consult == 1 ? 'Yes' : Data.consult == 2 ? 'No' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Date</label>
                                                        <input type="text" disabled className="form-control" value={Data.date} />
                                                    </div>
                                                   
                                                    <div className="col-md-6">
                                                        <label className="form-label">Amount</label>
                                                        <input type="text" disabled className="form-control" value={Data.amount} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Transaction Id</label>
                                                        <input type="text" disabled className="form-control" value={Data.tnx_id} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Refund Status</label>
                                                        <input type="text" disabled className="form-control" value={Data.refund_status == 1 ? 'Initiate' : Data.refund_status == 2 ? 'Complete' : 'Pending'} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Signature</label>
                                                        <img src={Data.client_signature ? `assets/signatures/${Data.client_signature}` : NoImage} alt="image" width={400} height={100}/>
                                                     
                                                    </div>

                                                </>
                                            )
                                        })}

                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default ViewForm6;