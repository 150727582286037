import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
// import Button from '@mui/material/Button';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
const Profile = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [adminProfile, setAdminProfile] = useState([]);
    const [id, setId] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInsta] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [youtube, setYoutube] = useState('');
    const [logo, setLogo] = useState('');
    const [map, setMap] = useState('');
    const [updatelogo, setUpdateLogo] = useState('');
    const [pending, setPending] = useState(true);
    const [password, setPassword] = useState('');
    const [whatsapp_no, setWhatsapp] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [about, setAbout] = useState('');
    const getData = () => {
        axios.get(`${api_url}/adminProfile`).then((response) => {
            setPending(false);
            setAdminProfile(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {

        adminProfile?.map((admin) => {
            setName(admin.name);
            setEmail(admin.email);
            setPhone(admin.phone);
            setAddress(admin.address);
            setLogo(admin.logo);
            setTwitter(admin.twitter);
            setFacebook(admin.facebook);
            setLinkedIn(admin.linkedIn);
            setYoutube(admin.youtube);
            setInsta(admin.instagram);
            setMap(admin.map);
            setWhatsapp(admin.whatsapp_no);
            setId(admin.id);
            setPinterest(admin.pinterest);
            setAbout(admin.about);

        })
    }, [adminProfile]);

    const notify = () => toast.success("Profile Update SuccessFully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);
        axios.post(`${api_url}/updateProfile`, {
            id: id,
            name: name,
            email: email,
            password: password,
            phone: phone,
            address: address,
            logo: updatelogo,
            youtube: youtube,
            twitter: twitter,
            instagram: instagram,
            linkedIn: linkedIn,
            whatsapp_no: whatsapp_no,
            map: map,
            facebook: facebook,
            pinterest: pinterest,
            about: about,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                        <span className='p-3'>Profile</span>
                            <section className="card m-3">
                                {/* <header className="card-header cardHeader_section">
                                    <div className="row">
                                        <div className="col-sm-12 text-center"><b>Profile</b></div>
                                    </div>
                                </header> */}
                                <div className="card-body">
                                    <ToastContainer />
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)} autocomplete='off'>
                                        <div className="col-md-6">
                                            <label className="form-label">Name</label>
                                            <input type="name" className="form-control"
                                                value={name}
                                                {...register("Name", { required: false, onChange: (e) => setName(e.target.value) })} />
                                            {errors.Name && <div className='errMsg'>Please Enter the Your Name</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control"
                                                value={email}
                                                //  onChange={(e) => setDummy(e.target.value)}
                                                {...register("Email", { required: false, onChange: (e) => setEmail(e.target.value) })} />
                                            {errors.Email && <div className='errMsg'>Please Enter the Your Email</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control"
                                                value={phone}
                                                {...register("Phone", { required: false, onChange: (e) => setPhone(e.target.value) })} />
                                            {errors.Phone && <div className='errMsg'>Please Enter the Your Phone Number</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Whatsapp Number</label>
                                            <input type="text" className="form-control"
                                                value={whatsapp_no}
                                                {...register("WPhone", { required: false, onChange: (e) => setWhatsapp(e.target.value) })} />
                                            {errors.WPhone && <div className='errMsg'>Please Enter the Your Whatsapp Phone Number</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Logo</label>
                                            <input type="file" className='form-control'

                                                {...register("Logo", { required: false, onChange: (e) => setUpdateLogo(e.target.files[0]) })}
                                            />

                                            {errors.Logo && <div className='errMsg'>Please Choose Logo</div>}

                                        </div>
                                        <div className="col-md-6">
                                            <div className='text-center p-3'>
                                                <img src={
                                                    updatelogo == "" ?
                                                        `assets/logo/${logo}` :
                                                        URL.createObjectURL(updatelogo)
                                                } width="200px" height="100px" alt="image" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Change Password</label>
                                            <InputGroup>

                                                <Form.Control type={showPassword ? "text" : "password"}
                                                 autocomplete='new-password'
                                                placeholder='Enter Password' value={password} {...register("password", { required: false, onChange: (e) => setPassword(e.target.value) })} />
                                                <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FaEyeSlash /> : <FaEye />}</InputGroup.Text>

                                            </InputGroup>
                                            {/* <input type="text" className="form-control" placeholder="" autoComplete='new password'

                                                {...register("Password", { required: false, onChange: (e) => setPassword(e.target.value) })}
                                            /> */}
                                            {/* {errors.Contact && <div className='errMsg'>Please Enter the Your Contact Number</div>} */}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Twitter</label>
                                            <input type="text" className="form-control" placeholder=""
                                                value={twitter}
                                                {...register("Twitter", { required: false, onChange: (e) => setTwitter(e.target.value) })} />
                                            {errors.Twitter && <div className='errMsg'>Please Enter the Twitter Link</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Facebook</label>
                                            <input type="text" className="form-control" placeholder=""
                                                value={facebook}
                                                {...register("Facebook", { required: false, onChange: (e) => setFacebook(e.target.value) })} />
                                            {errors.Facebook && <div className='errMsg'>Please Enter the Facebook Link</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Youtube</label>
                                            <input type="text" className="form-control" placeholder=""

                                                value={youtube}
                                                {...register("youtube", { required: false, onChange: (e) => setYoutube(e.target.value) })} />
                                            {errors.youtube && <div className='errMsg'>Please Enter the Youtube Link</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Instagram</label>
                                            <input type="text" className="form-control" placeholder=""
                                                value={instagram}
                                                {...register("instragram", { required: false, onChange: (e) => setInsta(e.target.value) })} />
                                            {errors.instragram && <div className='errMsg'>Please Enter the Instagram Link</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Pinterest</label>
                                            <input type="text" className="form-control" placeholder=""
                                                value={pinterest}
                                                {...register("pinterest", { required: false, onChange: (e) => setPinterest(e.target.value) })} />
                                            {errors.pinterest && <div className='errMsg'>Please Enter the Pinterest Link</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label">Linkedin</label>
                                            <input type="text" className="form-control" placeholder=""
                                                value={linkedIn}
                                                {...register("Linkedin", { required: false, onChange: (e) => setLinkedIn(e.target.value) })} />
                                            {errors.Linkedin && <div className='errMsg'>Please Enter the Linkedin Link</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Map</label>
                                            <input type="text" className="form-control" placeholder=""
                                                value={map}
                                                {...register("map", { required: false, onChange: (e) => setMap(e.target.value) })} />
                                            {errors.map && <div className='errMsg'>Please Enter the Map Link</div>}
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">About</label>
                                            <textarea type="text" className="form-control" placeholder=""
                                                value={about}
                                                {...register("about", { required: false, onChange: (e) => setAbout(e.target.value) })} ></textarea>
                                            {errors.about && <div className='errMsg'>Please Enter the About</div>}
                                        </div>

                                        <div className="col-12">
                                            <label className="form-label">Address</label>
                                            <textarea type="text" className="form-control" placeholder=""
                                                value={address}
                                                {...register("Address", { required: false, onChange: (e) => setAddress(e.target.value) })} ></textarea>
                                            {errors.Address && <div className='errMsg'>Please Enter the Your Address</div>}
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label className="form-label">Admin Type</label>
                                            <select id="inputState" className="form-select" >
                                                <option selected>Admin</option>
                                                <option>Super Admin</option>
                                            </select> */}
                                        {/* {errors.Name && <div className='errMsg'>Please Enter the Your First Name</div>} */}
                                        {/* </div> */}
                                        {/* <div className="col-md-6">
                                            <label className="form-label">Status</label>
                                            <select id="inputState" className="form-select" >
                                                <option selected>Active</option>
                                                <option>In-Active</option>
                                            </select> */}
                                        {/* {errors.Name && <div className='errMsg'>Please Enter the Your First Name</div>} */}
                                        {/* </div> */}






                                        <div className="col-12 updateabout" >

                                            <Button variant="contained" type="submit" className='Add_btn_'>
                                                Update
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Profile;