import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaEdit, FaShare, FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SlotsAdmin = () => {

    const api_url = process.env.REACT_APP_API_URL;
    
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [allConsultant, setAllConsultant] = useState([])
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);


    const handleClose = () => {
        setShow(false);

    }

    const handleShow = () => {
        setShow(true);

    }

    const [pending, setPending] = useState(true);
    const [slotDate, setSlotDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [consultantIds, setConsultantIds] = useState('');

    const [updateSlotDate, setUpdateSlotDate] = useState('');
    const [updateSlotTime, setUpdateSlotTime] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [curSlotId, setCurSlotId] = useState('');
    const [service_type, setService_type] = useState('');
    const [consultantId, setConsultantId] = useState('');
    const [slotStatus, setSlotStatus] = useState('')



    const getData = () => {

        axios.get(`${api_url}/allconsultants`).then((response) => {
            setAllConsultant(response.data.data);
            setPending(false);

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
        if (JSON.parse(localStorage.getItem("adminAuth"))[0]?.id != 1) {
            setConsultantId(JSON.parse(localStorage.getItem("adminAuth"))[0]?.id)
        }
    }, []);


    useEffect(() => {
        if (service_type != '') {
            axios.get(`${api_url}/getallSlots/${JSON.parse(localStorage.getItem("adminAuth"))[0]?.id == 1 ? consultantIds : JSON.parse(localStorage.getItem("adminAuth"))[0]?.id}/${service_type}`).then((response) => {
                setData(response.data.data);
                setNewData(response.data.data);
                setPending(false);
                console.log(response.data.data);

            }).catch((err) => {
                setPending(false);
                console.log(err);
            })
        }

    }, [consultantIds, service_type])




    useEffect(() => {
        data?.filter((data) => data.id == updateId).map((data) => {
            setUpdateSlotDate(data.slot_date);
            setUpdateSlotTime(data.slot_time);
            setCurSlotId(data.id);
            setUpdateId(data.all_ids);
            setSlotStatus(data.status);
        })
    }, [updateId])

    const notifyStatus = () => toast.success('Slot status change Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    // const handleStatus = () => {
    //     // let id = e.target.id;
    //     // let status = e.target.value;
    //     axios.post('/slotStatus', {
    //         id: updateId,
    //         status: slotStatus,
    //     }, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     }).then((response) => {
    //         if (response.status === 200) {
    //             notifyStatus();
    //             window.location.reload(false);
    //         }
    //     }).catch((err) => {

    //         console.log(err);
    //     })
    // }


    const handleUpdate = () => {
        setShowUpdate(false);
    }

    const notifyUpdate = () => toast.success('Slot update Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notify = () => toast.success('Slot add Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyErr = (err) => toast.error(`${err}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },

            {
                name: "Slot Id",
                selector: "id",
                width: '150px',
            },
            {
                name: "Consulant Id",
                selector: "consulant_id",
                width: '150px',

            },

            {
                name: "Slot Date",
                selector: "slot_date",
                width: '250px',

            },
            {
                name: "Slot Week",
                selector: "slot_week",
                width: '250px',

            },

            {
                name: "Slot Time",
                selector: "slot_time",
                width: '150px',

            },
            {
                name: "Time Zone",
                selector: "time_zone",
                width: '150px',
            },

            {
                name: "Status",
                selector: row => <span>{row.status == 0 ? "Active" : <span className='bg-success text-white p-2' style={{ borderRadius: '10px' }}>Free Slot</span>}</span>,
                // <>
                //     <Form.Select aria-label="Default select example"
                //         value={row.status} id={row.id}
                //         onChange={(e) => handleStatus(e)}
                //     >

                //         <option value="0">Active</option>
                //         <option value="1">In-Active</option>

                //     </Form.Select>

                // </>,
                width: '200px'

            },

            {
                name: "Action",
                selector: (row) => <Button className='Add_btn_'
                    onClick={() => handleShowUpdate(row.id)}
                ><FaEdit /></Button>,
            }

        ],

    };


    const handleShowUpdate = (id) => {
        setShowUpdate(true);
        setUpdateId(id)
    }


    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true)
        axios.post(`${api_url}/addSlot`, {
            consulant_id: consultantId,
            slot_date: slotDate,
            start_time: startTime,
            end_time: endTime,
            // email: email,
            // phone: phone,
            // address: address,
            // time_zone: timeZone,
            // slot_range: slotRange

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                notify();
                setPending(false);
                window.location.reload(false);

            }
        }).catch((err) => {
            setPending(false);
            handleClose();
            notifyErr(err.response.data.message);
            window.location.reload(false);
            console.log(err);
        })

    }


    const onUpdate = () => {
        setPending(true);
        const formData = new FormData();
        // formData.append("file", updatedImage);
        axios.post(`${api_url}/updateSlot`, {
            id: updateId,
            status: slotStatus

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleUpdate();
                notifyUpdate();
                setPending(false);
                window.location.reload(true);

            }
        }).catch((err) => {

            console.log(err);
        })


    }

    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }

    const handleConsultant = (type) => {
        setService_type(type);
        // getSlots(consultantId, timezone, type);

    }


    return (
        <>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

            <section id="main-content"
                style={pending ? { opacity: '0.5' } : { opacity: '1' }}
            >
                <ToastContainer />
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Slots</span>
                            <section className="card m-3">

                                <div className='d-flex align-content-center justify-content-between'>
                                    <div className='d-flex align-content-center gap-4'>
                                        {JSON.parse(localStorage.getItem("adminAuth"))[0].id == 1 ?
                                            <Form.Group className="p-2">
                                                <Form.Select aria-label="Default select example"
                                                    value={consultantIds}
                                                    onChange={(e) => setConsultantIds(e.target.value)}
                                                >
                                                    <option value="">Select Consultant First</option>
                                                    {allConsultant?.map((data) => {
                                                        return (
                                                            <option key={data.id} value={data.id}>{data.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            : null}
                                        <Form.Group className="p-2">

                                            <Form.Select aria-label="Default select example"
                                                value={service_type}
                                                onChange={(e) => handleConsultant(e.target.value)}
                                            >
                                                <option value="">Select Consultation Type</option>

                                                <option value="5">Free Consultation (15min)</option>
                                                <option value="10">Hypno Gastric Band - (1.30hrs)</option>
                                                <option value="11">Hypno Birthing & Pregnancy- (1.30hrs)</option>
                                                <option value="9">Menopause- (2hrs)</option>
                                                <option value="7">Overcoming Addiction (1hr)</option>
                                                <option value="8">Overcoming Gambling  (1hr)</option>
                                                <option value="6">Smoking Cessation (1hr)</option>
                                                <option value="1">The Control System Consultation (2hrs)</option>
                                                <option value="2">The Control System (1hr)</option>
                                                <option value="3">Weight Release Programme 1st Session (2hrs)</option>
                                                <option value="4">Weight Release Sessions Further Sessions (1hr)</option>

                                            </Form.Select>
                                        </Form.Group>
                                    </div>

                                    {/* <div className="selectoption mt-4">
                        <strong >Status :-</strong>
                        <select className="status select_status filter"
                            onChange={(e) => filterByStatus(e.target.value)}
                        >
                            <option value="0">Pending</option>
                            <option value="1">Completed</option>
                            <option value="2">Rejected</option>
                            <option value="">All</option>
                        </select>
                    </div> */}

                                    <div className='p-2' >
                                        <Button className='Add_btn_' style={{ float: "right" }}
                                            onClick={handleShow}
                                        >Add Slot</Button>
                                    </div>

                                </div>
                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />


                                    {data?.length == 0 ? <p>Info : Please Select Consultant and Consultation Type from above</p> : null}
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div >
                </section >
            </section >



            {/* add */}
            < Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Slot</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>


                        {JSON.parse(localStorage.getItem("adminAuth"))[0]?.id == 1 ?
                            <Form.Group className="col-md-12 pb-3">
                                <Form.Label>Consultant</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    value={consultantId} placeholder="Enter Address"
                                    {...register("consultant", {
                                        required: true,
                                        onChange: (e) => setConsultantId(e.target.value)
                                    })}
                                >
                                    <option value="" selected disabled>Please select consultant</option>
                                    {allConsultant?.map((data) => {
                                        return (
                                            <>
                                                <option value={data.id}>{data.name}</option>
                                            </>
                                        )
                                    })}

                                </Form.Select>
                                {errors.consultant && <div className='errMsg pt-2'>Please select consultant</div>}
                            </Form.Group>
                            : null}
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type='date'
                                value={slotDate} placeholder="Enter Range"
                                {...register("slot_date", {
                                    required: true,
                                    onChange: (e) => setSlotDate(e.target.value)
                                })}
                            />
                            {errors.slot_date && <div className='errMsg pt-2'>Please enter Slot Range</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control type='time'
                                value={startTime} placeholder=""
                                {...register("start_time", {
                                    required: true,
                                    onChange: (e) => setStartTime(e.target.value)
                                })}
                            />
                            {errors.start_time && <div className='errMsg pt-2'>Please enter start Time</div>}
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control type='time'
                                value={endTime} placeholder=""
                                {...register("end_time", {
                                    required: true,
                                    onChange: (e) => setEndTime(e.target.value)
                                })}
                            />
                            {errors.end_time && <div className='errMsg pt-2'>Please enter end Time</div>}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="Add_btn_" type="submit">
                            Add
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >



            {/* update */}
            < Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Update Slot</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>



                        {/* <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={consultantId} placeholder="Enter Address"

                                onChange={(e) => setConsultantId(e.target.value)}

                            >
                                <option>select</option>
                                {allConsultant?.map((data) => {
                                    return (
                                        <>
                                            <option value={data.id}>{data.name}</option>
                                        </>
                                    )
                                })}
                            </Form.Select>

                        </Form.Group> */}
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Id</Form.Label>
                            <Form.Control type='text'
                                value={curSlotId} placeholder="Enter Title"
                                disabled


                            />

                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Date</Form.Label>
                            <Form.Control type='date'
                                value={updateSlotDate} placeholder="Enter Title"
                                disabled


                            />

                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Time</Form.Label>
                            <Form.Control type='time'
                                value={updateSlotTime} placeholder="Enter Name"
                                disabled

                            />

                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Select aria-label="Default select example"
                                value={slotStatus}
                                onChange={(e) => setSlotStatus(e.target.value)}
                            >
                                <option value="0">Active</option>
                                <option value="1">Free Slot</option>
                            </Form.Select>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleUpdate}>
                            Close
                        </Button>
                        <Button className="Add_btn_" onClick={onUpdate}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >

        </>
    )
}

export default SlotsAdmin