import React, { useState, useEffect, useCallback } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Modal from 'react-bootstrap/Modal';
import ShortcutIcons from './components/common/ShortcutIcons';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import cardImg from '../web/components/assets/card-img.webp';


const Frontend = () => {


  const [cart, setCart] = useState([]);
  const [subTotal, setSubTotal] = useState(0);




  const saveCart = (newData) => {

    localStorage.setItem("myCart", JSON.stringify(newData));

    let subt = 0;
    newData?.forEach(element => {
      subt += element.price * element.qty
    });
    setSubTotal(subt)
  }


  useEffect(() => {
    try {
      if (localStorage.getItem("myCart")) {
        let myCart = JSON.parse(localStorage.getItem("myCart"))
        setCart(myCart)
        saveCart(myCart)
      } else {
        localStorage.setItem("myCart", JSON.stringify([]))
      }
    } catch (error) {
      console.error(error);
      localStorage.setItem("myCart", JSON.stringify([]))

    }

  }, [])



  const addToCart = (id, name, qty, price, image) => {
    let updateCart = [...cart];

    let Index = updateCart.findIndex((obj) => obj.id == id);

    if (Index == -1 && qty == 1) {
      updateCart = [...updateCart, { id: id, name: name, qty: qty, price: price, image: image }];
    }
    //  else {
    //   updateCart[Index].qty += qty;
    // }

    let newData = updateCart
    setCart(newData);
    saveCart(newData)
  }

  const removeFromCart = (id) => {
    let updateCart = [...cart];

    let Index = updateCart.findIndex((obj) => obj.id == id);

    // if (updateCart[Index].qty > 1) {
    //   updateCart[Index].qty -= 1;
    // } else {
    updateCart.splice(Index, 1);
    // }

    let newData = updateCart

    console.log(newData)
    setCart(newData);
    saveCart(newData)
  }




  const clearCart = () => {
    setCart([])
    // saveCart([])
    // localStorage.setItem("myCart", JSON.stringify([]))
  }

  const [show, setShow] = useState(localStorage.getItem('popup') ? !localStorage.getItem('popup') : true);
  useEffect(() => {
    if (window.localStorage) {
      if (!localStorage.getItem('popup')) {
        localStorage['popup'] = true;
      }
    }
  }, []);








  return (
    <>

      <Header cart={cart} addToCart={addToCart} removeFromCart={removeFromCart} clearCart={clearCart} subTotal={subTotal} />
      <ShortcutIcons />
      <Outlet context={[addToCart]} />
      <Footer />

      <Modal show={show} onHide={() => setShow(!show)} centered>
        <Modal.Header style={{ position: 'absolute', border: 'none', right: '0' }} closeButton></Modal.Header>
        <Card>
          <CardMedia
            sx={{ height: 240 }}
            image={cardImg}
            title="green iguana"
          />
          <CardContent>
            <div className='text-center'>
              <h1>
                YES, WE ARE OPEN AND HERE FOR YOU
              </h1>
              <p className='p-4'>Transform your life with the guidance of our expert hypnotherapists.</p>
              <Link to="/contact" onClick={() => setShow(!show)} className='contact_icons contact_now'>
                CONTACT US NOW TO SCHEDULE A SESSION
              </Link>

            </div>
          </CardContent>
          <CardActions>

          </CardActions>
        </Card>
      </Modal>

    </>
  )
}

export default Frontend