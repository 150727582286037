import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FaHandPointUp, FaUser, FaPlus } from 'react-icons/fa';
import { MdOutlineMiscellaneousServices } from 'react-icons/md';
import { AiOutlineShoppingCart, AiTwotoneCustomerService } from 'react-icons/ai';
import Slider from "react-slick";
import { Link, useNavigate } from 'react-router-dom';
import bannerNew from '../assets/banner.png'
import axios from 'axios';
import Rating from '@mui/material/Rating';
import { Pagination } from "swiper";
import aboutImg from '../assets/about.png';
import { BsFillHandIndexThumbFill, BsArrowRightShort } from 'react-icons/bs';
import { useOutletContext, useParams } from 'react-router-dom';
import RelatedProduct from '../common/RelatedProduct';
import HTMLRenderer from 'react-html-renderer'
import ClientSlider from '../common/ClientSlider';
import Spinner from 'react-bootstrap/Spinner';
import AnxietyUKApprovedTherapist from '../assets/AnxietyUKApprovedTherapist.png'
import CMA from '../assets/CMA.png';
import ControlSystems from '../assets/ControlSystems.png';
import MHS from '../assets/mhs.png';
import ThePathwaySTILL from '../assets/ThePathwaySTILL.png';
import Untitleddesign from '../assets/Untitleddesign.png';
import videoH from '../assets/stream.mp4';
import { Helmet } from "react-helmet";



const Home = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [blogs, setBlogs] = useState([]);
    const [about, setAbout] = useState([]);
    const [heroData, setHeroData] = useState([]);
    const [therapies, setTherapies] = useState([]);
    const [banner, setBanner] = useState('');
    const [hypnosis, setHypnosis] = useState([]);
    const [clients, setClients] = useState([]);
    const [product, setProduct] = useState([]);
    const [pending, setPending] = useState(false)

    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const [featureImage, setFeatureImage] = useState('');

    const getData = () => {
        axios.get(`${api_url}/getBanner/1`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].image)
            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/1`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
        }).catch((err) => {
            console.log(err);
        })

        axios.get(`${api_url}/gethometherapies`).then((response) => {
            console.log(response.data.data)
            setTherapies(response.data.data);
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getAdminAbout`).then((response) => {
            console.log(response.data.data)
            setAbout(response.data.data);
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getHypnosis`).then((response) => {
            console.log(response.data.data)
            setHypnosis(response.data.data);
        }).catch((err) => {
            console.log(err)
        });


        axios.get(`${api_url}/getAllProduct`).then((response) => {
            console.log(response.data.data)
            setProduct(response.data.data);
        }).catch((err) => {
            console.log(err)
        });

        // axios.get(`${api_url}/getClient`).then((response) => {
        //     console.log(response.data.data)
        //     setClients(response.data.data);
        // }).catch((err) => {
        //     console.log(err)
        // });

        axios.get(`${api_url}/getAllBlog`).then((response) => {
            console.log(response.data.data)
            setBlogs(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true)
        });

    };

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();


        if (window.location.hash.includes('##')) {
            navigate('/');
        }

    }, [])

    const handleSEO = () => {
        seoContent?.map((seo) => {

            setRobot(seo.robot);
            setSeoTitle(seo.seo_title);
            setSeoKeyword(seo.seo_keyword)
            setSeoDes(seo.seo_description);
            setCanonical(seo.canonical)
            setSeoScript(seo.script);
            setFeatureImage(seo.feature_image);
        })
    }

    useEffect(() => {
        handleSEO();
    }, [seoContent]);



    const [addToCart] = useOutletContext();



    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500
    };

    return (
        <>


            <Helmet>
                <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
            </Helmet>


            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="main-banner-area">
                <div className="main-banner-item" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                    <div className="container">
                        <div className='row m-0'>
                            <div className="col-lg-9 col-md-12 col-12 main-banner-content">
                                {heroData?.map((data) => {
                                    return (
                                        <>
                                            <h1>
                                                {data.title}
                                            </h1>
                                            <p>
                                                {data.content}
                                            </p>
                                        </>
                                    )
                                })}
                                <div className="banner-btn">
                                    {/* <Link to="/appointment" className="banner_btn default-btn">Book Online Session <i><BsFillHandIndexThumbFill /></i></Link> */}
                                    <Link to="https://calendly.com/glen-russell/initial-telephone-call" target='_blank' className="banner_btn default-btn">Book Online Session <i><BsFillHandIndexThumbFill /></i></Link>
                                    <Link to="/shop" className="optional-btn">Shop<i><AiTwotoneCustomerService /></i></Link>
                                    
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-md-6 col-12'>
                                <img src={aboutImg} />
                            </div> */}
                        </div>



                    </div>
                </div>


            </div>


            {/* therapies card */}
            {/* <section className="features-area pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        {therapies?.map((data, index) => {
                            return (

                                <div key={index} className="col-lg-4 col-md-6">
                                    <Link to={`/therapies/${data.id}`}>
                                        <div className="single-features">
                                            <div className="features-title">
                                                <i><img src={`assets/therapies/${data.image}`} /></i>
                                                <h3><a to="services-details.">{data.title.substring(0, 30)}...</a></h3>
                                              
                                            </div>

                                            <p>
                                            <HTMLRenderer html={data.content.substring(0, 200)} />...</p>
                                        </div>
                                    </Link>
                                </div>

                            )
                        })}
                    </div>
                </div>
            </section > */}

            {/* about */}
            {/* <section className="about-area pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        {about?.map((data, index) => {
                            return (
                                <>
                                    <div className="col-lg-6">
                                        <div className="about-main-image">
                                            <img src={`assets/about/${data.image}`} alt="image" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="about-main-content">
                                            <h3>Know About Us</h3>
                                            <div className="about-content-image">
                                                <h4 className="sub-title">{data.title}
                                                </h4>
                                            </div>
                                            <HTMLRenderer html={data.description} />
                                            
                                            <div className="about-btn">
                                                <Link to="about" className="default-btn">Know More<i><FaUser /></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>

            </section> */}


            <section className="philosophy-area ptb-100">
                <div className="container">
                    <div className="d-flex flex-column philosophy-slides owl-carousel owl-theme" style={{ rowGap: "3rem" }}>
                        {hypnosis?.slice(0, 3).map((data, index) => {
                            return (
                                <div key={index} className="philosophy-item">
                                    <div className="row align-items-top" style={data.section % 2 == 0 ? { flexDirection: "row-reverse" } : { flexDirection: 'row' }}>
                                        <div className="col-lg-6">
                                            <div className="philosophy-content">
                                                <h3>{data.heading}</h3>
                                                {/* <h4>Slow Down For A Moment</h4> */}

                                                <HTMLRenderer html={data.description1} />

                                                <div className="philosophy-quote">
                                                    <i className="flaticon-close"></i>
                                                    <HTMLRenderer html={data.description2} />
                                                    {/* <p> <p>{data.description2}</p></p> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="philosophy-image text-center">
                                                <img src={`assets/hypnosis/${data.image}`} width={300} alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>


            </section>


            {/* related product */}
            {/* <div className='ptb-100'>
                <RelatedProduct data={product} title="Our Products" />

                <div className="book-store-btn">
                    <Link to="/shop" className="default-btn">View More<i><BsArrowRightShort fontSize={20} /></i></Link>
                </div>
            </div> */}



            {/* <section className="products-area ptb-100">
                <div className="container-fluid">
                    <div className="section-title">
                        <h2>Our Products</h2>
                    </div>

                    <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        loop={true}
                        autoplay={true}
                        pagination={{
                            clickable: true,
                        }}
                        //  modules={[Pagination]}
                        className="mySwiper text-center"
                    >
                        {product?.map((data) => {
                            return (
                                <SwiperSlide>
                                    <div className="products-item">
                                        <div className="products-image">
                                            <a to="#"><img src={`assets/product/${data.image}`} alt="image" /></a>
                                            {JSON.parse(localStorage.getItem("myCart"))?.some(id => id.id == data.id) === false ?
                                                <div className="action-btn">
                                                    <button onClick={() => addToCart(data.id, data.name, 1, data.price, data.image)} className="default-btn">Add To Cart<i><AiOutlineShoppingCart /></i></button>
                                                </div>
                                                : null}
                                        </div>

                                        <div className="products-content">
                                            <h3>
                                                <a to="#">{data.name}</a>
                                            </h3>
                                            <span>{data.price}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper>

                    <div className="book-store-btn">
                        <a to="#" className="default-btn">Visit Book Store <i><AiOutlineShoppingCart /></i></a>
                    </div>
                </div>


            </section> */}


            <section className="offer-area" style={{ backgroundImage: `url(${bannerNew})` }}>
                <div className="container">
                    <div className="row align-items-center">
                        {hypnosis?.filter(data => data.section == 4).map((data) => {
                            return (
                                <>
                                    <div className="col-lg-6">
                                        <div className="offer-item">
                                            <div className="content">
                                                <h2>Best Hypnotherapist In England</h2>
                                            </div>
                                            <p style={{ color: '#fff' }}><HTMLRenderer html={data.description1} /></p>
                                            <div className='qua-img-d'>
                                                <img src={AnxietyUKApprovedTherapist} width="136px" height="68px" alt="image" />
                                                <img src={CMA} width="136px" height="68px" alt="image" />
                                                <img src={MHS} width="136px" height="68px" alt="image" />
                                                <img src={ControlSystems} width="68px" height="68px" alt="image" />
                                                <img src={ThePathwaySTILL} width="68px" height="68px" alt="image" />
                                                <img src={Untitleddesign} width="68px" height="68px" alt="image" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="offer-image">
                                            {/* <img className='p-5' src={`assets/hypnosis/${data.image}`} alt="image" /> */}
                                            <video className="w-100" src={videoH} controls />
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </section>
            {/* 919E85 */}
            {/* client slider */}
            {/* <ClientSlider clients={clients} /> */}

            {/* <section className="reviews-area ptb-100">
                <div className="container">
                    <div className="clients-slides owl-carousel owl-theme">
                        <div className="section-title">
                            <h2>Client Reviews</h2>
                        </div>

                        <Swiper
                            spaceBetween={30}
                            slidesPerView={2}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper pb-5"
                            breakpoints={{
                                30: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 40,
                                },
                                1024: {
                                  slidesPerView: 2,
                                  spaceBetween: 50,
                                },
                              }}
                        >

                            {clients?.map((data) => {
                                return (
                                    <SwiperSlide>
                                        <div className="clients-item">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="reviews-image">
                                                        <img src={`assets/clients/${data.image}`} alt="image" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="item">
                                                        <div className="title">
                                                            
                                                        </div>

                                                        <div className="single-feedback">
                                                            <h3>{data.title}</h3>
                                                           
                                                            <p>{data.content}</p>
                                                        </div>

                                                        <div className="title-info">
                                                            <h5>{data.name}</h5>
                                                           
                                                            <Rating name="half-rating-read" defaultValue={data.star} precision={0.5} readOnly />
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}

                        </Swiper>


                    </div>
                    <div className="book-store-btn">
                        <Link to="/client-reviews" className="default-btn">View More<i><BsArrowRightShort fontSize={20} /></i></Link>
                    </div>
                </div>
            </section> */}

            {/* blogs */}
            {/* <section className="blog-area pb-70">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Blog</h2>
                    </div>

                    <div className="row justify-content-center">
                        {blogs?.slice(0, 3).map((blog) => {
                            return (
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-blog">
                                        <div className="blog-image">
                                            <Link to={`/blog/${blog.id}`}><img src={`assets/blogs/${blog.image}`} alt="image" /></Link>
                                            
                                            <div className="tag">{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })}th <span>{new Date(blog.created_at).toLocaleDateString('en-En', { month: 'short' })}</span></div>
                                        </div>

                                        <div className="blog-content">
                                            <h3>
                                                <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                                            </h3>
                                            <div className="blog-btn">
                                                <Link to={`/blog/${blog.id}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                    <div className="book-store-btn">
                        <Link to="/blog" className="default-btn">View More<i><BsArrowRightShort fontSize={20} /></i></Link>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Home