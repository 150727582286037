import React from 'react';
import './forms.css';
import logo from '../assets/logo.png';

const ClientContract = () => {
    return (
        <>
            {/* <main> */}
            <article>
                <div className='title text-center'>
                    <img style={{borderRadius: "50%"}} src={'https://tphr.giwdevelopment.com/assets/logo/logo.png'} width={100} height={99} alt="image" />
                    <h1 className='pb-5'>THE PATHWAY HYPNOTHERAPY ROOMS</h1>
                </div>
                <div className='content-d pb-5'>
                    <h2 className='heading'>CLIENT CONTRACT</h2>
                    <p className='content'>Thank you for choosing Glen Russell as your Remedial Hypnotist and STILL Method Coach to help you get clarity and move forward in your personal development and growth. I am excited to work with you to improve the quality of your relationship with yourself and others, and ultimately your life.</p>
                </div>
                <div className='pb-5'>
                    <h2>SERVICES</h2>
                    <ul>
                        <li>
                            Glen Russell (The Hypnotist), is a Clinical Hypnotherapist, CONTROL Practitioner, STILL Method Coach, Anxiety UK Approved Therapist.
                        </li>
                        <li>
                            The CONTROL services to be provided by the Hypnotist to You (the Client) occur in person or online.
                        </li>
                        <li>
                            Therapy or counselling, may address specific personal projects or general conditions in the client’s life or progression. However, participation in the programme does not guarantee specific outcomes, including personal or business success.
                        </li>
                        <li>
                            As with any therapy, results may vary and are not guaranteed.
                        </li>
                        <li>
                            The Hypnotist does not diagnose nor treat disease and is not a physician. Hypnosis sessions are not a substitute for diagnosis or treatment from a qualified medical practitioner. There is not, nor will there be, any type of psychological therapy included within the sessions. If these services are needed, or the need for them is identified at any stage during the programme, it is the client’s responsibility to seek these services from a licensed professional.
                        </li>
                        <li>
                            It is the Hypnotist’s responsibility to report anything deemed as harmful to the Client or another person to the appropriate authorities.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>
                        CLIENT RESPONSIBILITIES
                    </h2>
                    <ul>
                        <li>
                            You agree that your full participation is necessary to achieve your goals. Missing any portion of the sessions or incomplete participation may negatively impact the effectiveness of the programme.
                        </li>
                        <li>
                            You accept and agree that you are one hundred percent (100%) responsible for your own progress and results.
                        </li>
                        <li>
                            The Hypnotist makes no representations or guarantees verbally or in writing regarding any results gained as the result of participation in the programme or the purchase of any books or materials. The Hypnotist makes no guarantee or warranty that the sessions will meet your requirements.
                        </li>
                        <li>
                            You, the client, agrees to disclose any medical conditions, including those related to mental and emotional health and well-being, before the sessions begin.
                        </li>
                    </ul>
                </div>


                <div className='pb-5'>
                    <h2>CHARGING BASIS</h2>
                    <ul>
                        <li>
                            The cost per package is as agreed and outlined in the confirmation email.
                        </li>
                        <li>
                            Full payment is due within 24 hours of receipt of this contract, unless otherwise agreed. Prices cannot be guaranteed and are subject to change beyond this timeframe.
                        </li>
                        <li>
                            All fees due under this Agreement are non-cancellable and the sums paid non- refundable.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>SESSIONS</h2>
                    <ul>
                        <li>
                            The sessions must be used within the timeframe set out (one session per week on a rolling basis unless otherwise stated).
                        </li>
                        <li>
                            The Hypnosis package is NOT for prolonged, on-going use of individual sessions. Part of your progress is taking responsibility for your own time and scheduling and being consistent.
                        </li>
                        <li>
                            If you wish to have individual sessions an ad-hoc price will be given.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>CANCELLATIONS & RESCHEDULING</h2>
                    <ul>
                        <li>
                            Any pre-booked sessions that need to be rescheduled must be done so with at least 24 hours’ notice where possible. Each session may be rescheduled a maximum of 1 time (ONCE), after which the session will be lost and no refund will be provided, unless the Coach deems the change reasonable.
                        </li>
                    </ul>
                </div>

       
                <div className='pb-5'>
                    <h2>ADDITIONAL SERVICES / THERAPIES</h2>
                    <ul>
                        <li>
                            All services outside the scope of this Agreement that are requested by the Client and which Glen Russell agrees to perform will be billed at that service’s rate/price. The Client must discuss this with Glen Russell and upon approval the client will be requested to pay for this service and a new booking for additional therapies will be created. Identical terms and conditions are applicable for any new services.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>NO GUARANTEE</h2>
                    <ul>
                        <li>
                            Glen Russell does not warrant or guarantee any specific level of performance or results. Examples of results obtained for other clients of Glen Russell may be used as a marketing tool and shown to the Client for demonstrative purposes only and should not be construed by the Client as indicating any promised results or level of results.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>INTELLECTUAL PROPERTY</h2>
                    <ul>
                        <li>
                            The coaching, information, content, materials and services (and any intellectual property and other rights relating thereto) are and will remain the property of Glen Russell. In order to honour and protect the Hypnotist’s intellectual property the client agrees not to: disclose, communicate, copy, reproduce, modify, adapt, translate, republish, upload, post, transmit, sublicense, sell, decompile or in any way share information about the Hypnotist’s materials, written articles or methods to any third parties without express written permission. The Hypnotist will also protect any client intellectual property or personal/business information that may be discussed.
                        </li>
                    </ul>
                </div>


                <div className='pb-5'>
                    <h2>EXCLUSIVITY</h2>
                    <ul>
                        <li>
                            Nothing shall prevent the Hypnotist from using techniques, ideas and other know-how gained during the performance of Services under this Agreement in the furtherance of his own business, to the extent that such does not result in the disclosure or abuse of confidential information in breach hereof, or any infringement of any Intellectual Property Rights of the Client.
                        </li>
                    </ul>
                </div>


                <div className='pb-5'>
                    <h2>DATA PROTECTION</h2>
                    <ul>
                        <li>
                            1. The parties mutually acknowledge their respective responsibilities (a) to comply with the provisions of the Data Protection Act 1998 in relation to Personal Data, and (b) to use Personal Data provided by the other so far as necessary for the proper performance of this Agreement and any Schedule hereto, but not further or otherwise.
                        </li>
                        <li>
                            2. Where a party processes Personal Data in connection with this Agreement and any Schedule hereto, that party shall:
                        </li>
                        <li>
                            a. Where the other party is the Data Controller in relation to such Personal Data, act only on instructions from the other party; and
                        </li>
                        <li>
                            b. Where that party is the Data Controller in relation to such Personal Data, comply with the provisions of the DPA, including (without limitation) its obligations to take appropriate technical and organisational measures against unauthorised or unlawful Processing of such Personal Data and against accidental loss or destruction of, or damage to, such Personal Data.
                        </li>
                        <li>
                            3. For the purposes of this clause, the terms “Data Controller”, “Data Processor”, “Personal Data” and “Process” shall have the meanings given to them in the Data Protection Act 1998 (“DPA”).
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>DECLARATION</h2>
                    <ul>
                        <li>
                            I understand that I am working with the Hypnotist on a multi-session package basis at the agreed fee.
                        </li>
                        <li>
                            I have read und understood the above information regarding the services provided and I fully consent to the services offered as described.
                        </li>
                        <li>
                            I give the Hypnotist permission to be honest, direct, supportive and to challenge me.
                        </li>
                        <li>
                            I understand and agree that I am fully responsible for my personal well-being, including my choices and decisions.
                        </li>
                        <li>
                            I understand that I am always free to reject any advice, suggestions or requests made by the Hypnotist.
                        </li>
                        <li>
                            I understand that therapy is not to be used in lieu of professional advice for legal, financial, medical or other matters normally handled by other professionals.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>LAW</h2>
                    <ul>
                        <li>
                            These terms are governed by the laws of England & Wales, whose courts shall have sole jurisdiction in relation to all matters.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>ENTIRE AGREEMENT</h2>
                    <ul>
                        <li>
                            This Agreement is the final, complete and exclusive Agreement of the Parties. No modification of or amendment to this Agreement shall be effective unless in writing and signed by each of the Parties.
                        </li>
                        <li>
                            By their signatures below, the parties hereby understand and agree to all terms and conditions of this Agreement.
                        </li>
                    </ul>
                </div>

                <div className='pb-5'>
                    <h2>PARENT / GUARDIAN</h2>
                    <ul>
                        <li>
                            If the client is under the age of 18, this form is to be read, understood and any doubts clarified with Glen Russell by the parent / guardian of the client, and relevant information should be clearly explained to the client.
                        </li>
                    </ul>
                </div>

                {/*  */}

                <div className="copyright-area" style={{ backgroundColor: 'rgb(32 37 41)' }}>
                    <div className="container">
                        <div className="copyright-area-content">
                            <p style={{ color: '#fff' }}>
                                Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved
                             
                            </p>
                        </div>
                    </div>
                </div>
            </article>

            {/* </main> */}
        </>
    )
}

export default ClientContract