import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import axios from 'axios';
import { BiLoaderCircle } from 'react-icons/bi';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Helmet } from "react-helmet";

const Blog = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [blogs, setBlogs] = useState([]);
    const [heroData, setHeroData] = useState([]);
    const [banner, setBanner] = useState('');
    const [dataPerPage, setDataPerPage] = useState(6);
    const [pending, setPending] = useState(false)
    const [seoContent, setSeoContent] = useState([]);
    const [seoDes, setSeoDes] = useState('');
    const [seoTitle, setSeoTitle] = useState('');
    const [robot, setRobot] = useState('');
    const [seoScript, setSeoScript] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [canonical, setCanonical] = useState('');
    const navigate = useNavigate();
    const [featureImage, setFeatureImage] = useState('');
    const getData = () => {

        axios.get(`${api_url}/getBanner/7`).then((response) => {
            console.log(response.data.data);
            setBanner(response.data.data[0].image)
            setHeroData(response.data.data)
        }).catch((err) => {
            console.log(err)
        });

        axios.get(`${api_url}/getseo/8`).then((response) => {
            setSeoContent(response.data.data);
            console.log(response.data.data);
          }).catch((err) => {
            console.log(err);
          })

        axios.get(`${api_url}/getAllBlog`).then((response) => {
            console.log(response.data.data)
            setBlogs(response.data.data);
            setPending(true)
        }).catch((err) => {
            console.log(err)
            setPending(true);
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
        if (window.location.hash.includes('##')) {
            navigate('/');
        }
    }, []);

    const handleSEO = () => {
        seoContent?.map((seo) => {
    
          setRobot(seo.robot);
          setSeoTitle(seo.seo_title);
          setSeoKeyword(seo.seo_keyword)
          setSeoDes(seo.seo_description);
          setCanonical(seo.canonical)
          setSeoScript(seo.script);
          setFeatureImage(seo.feature_image);
        })
      }
    
      useEffect(() => {
        handleSEO();
      }, [seoContent]);




    return (
        <>
<Helmet>
<link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
      </Helmet>

            {/* <div className="page-banner-area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-image">
                                <img src="https://img1.wsimg.com/isteam/ip/875cd7e4-36b3-4922-ab23-c0b9e38609e3/HYPNO%20ROOM%202.jpg/:/rs=w:1280" alt="image" style={{ display: "block", visibility: "visible" }} />
                     
                                <div className="image-shape">
                                    <img data-cfsrc="assets/images/page-banner/shape.png" alt="image" style={{ display: "none", visibility: "hidden" }} />
                                    <noscript><img src="images/shape.png" alt="image" /></noscript>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="page-banner-content">
                                <h2>Blog (Two in Row)</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>Blog (Two in Row)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                

                <div className="page-banner-shape">
                    <img data-cfsrc="assets/images/page-banner/banner-shape-1.png" alt="image" style={{ display: "none", visibility: "hidden" }} />
                    <noscript><img src="images/banner-shape-1.png" alt="image" /></noscript>
                </div>
            </div> */}

            {pending ? null :
                <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
                    <Spinner animation="border" variant="dark" className='my_spinner' />
                </div>}

            <div className="page-banner-with-full-image item-bg2" style={{ backgroundImage: `url(${api_url}/assets/banner/${banner}` }}>
                <div className="container">
                    <div className="page-banner-content-two">
                        <h2>Blog </h2>
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Blog </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* <section className="blog-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="single-blog-item">
                                <div className="blog-image">
                                    <a href="blog-details.html"><img src="https://img1.wsimg.com/isteam/ip/875cd7e4-36b3-4922-ab23-c0b9e38609e3/HYPNO%20ROOM%202.jpg/:/rs=w:1280" alt="image" style={{ display: "block", visibility: "visible" }} /><noscript><img src="images/blog-1.jpg" alt="image" /></noscript></a>

                                    <div className="tag">13th <span>Jan</span></div>
                                </div>

                                <div className="blog-content">
                                    <div className="meta">
                                        <p>
                                            <i className="flaticon-user"></i>
                                            By <a href="#">Andrew Smith</a>
                                        </p>
                                    </div>

                                    <h3>
                                        <a href="blog-details.html">How To Take A Short Break From The Social Media For A Healthy Life?</a>
                                    </h3>
                                    <div className="blog-btn">
                                        <a href="blog-details.html" className="default-btn">Read More <i className="flaticon-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="single-blog-item">
                                <div className="blog-image">
                                    <a href="blog-details.html"><img src="https://img1.wsimg.com/isteam/ip/875cd7e4-36b3-4922-ab23-c0b9e38609e3/man-feels-hesitant-whether-eat-hamburger-pizza.jpg/:/rs=w:1280" alt="image" style={{ display: "block", visibility: "visible" }} /></a>

                                    <div className="tag">14th <span>Jan</span></div>
                                </div>

                                <div className="blog-content">
                                    <div className="meta">
                                        <p>
                                            <i className="flaticon-user"></i>
                                            By <a href="#">Andrew Smith</a>
                                        </p>
                                    </div>

                                    <h3>
                                        <a href="blog-details.html">How Does A Psychologist Help To Maintain A Good Mental Health</a>
                                    </h3>
                                    <div className="blog-btn">
                                        <a href="blog-details.html" className="default-btn">Read More <i className="flaticon-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                   

                   

                        <div className="col-lg-12 col-md-12">
                            <div className="pagination-area">
                                <a href="#" className="prev page-numbers"><i className="bx bx-chevrons-left"></i></a>
                                <span className="page-numbers current" aria-current="page">1</span>
                                <a href="#" className="page-numbers">2</a>
                                <a href="#" className="page-numbers">3</a>
                                <a href="#" className="page-numbers">4</a>
                                <a href="#" className="next page-numbers"><i className="bx bx-chevrons-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="blog-area" style={{ padding: "45px 0px" }}>
                <div className="container">
                    <div className="section-title">
                        <h1>Our Blog</h1>
                    </div>

                    <Tabs
                        defaultActiveKey="all"
                        id="fill-tab-example"
                        className="mb-5 blog-filter-d"
                        fill
                    >
                        <Tab eventKey="all" title="All">
                            <div className="row justify-content-center">
                                {blogs?.slice(0, dataPerPage).map((blog) => {
                                    return (
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-blog">
                                                <div className="blog-image">
                                                    <Link to={`/blog/${blog.id}/${blog.title.replace(/\s/g, '')}`}><img src={`${api_url}/assets/blogs/${blog.image}`} alt="image" /></Link>
                                                    {/* , month: 'short' */}
                                                    <div className="tag">{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })}th <span>{new Date(blog.created_at).toLocaleDateString('en-En', { month: 'short' })}</span></div>
                                                </div>

                                                <div className="blog-content">
                                                    <h3>
                                                        <Link to={`/blog/${blog.id}/${blog.title.replace(/\s/g, '')}`}>{blog.title}</Link>
                                                    </h3>
                                                    <div className="blog-btn">
                                                        <Link to={`/blog/${blog.id}/${blog.title.replace(/\s/g, '')}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {blogs?.length > dataPerPage ?
                                <div className="book-store-btn">
                                    <Button onClick={() => setDataPerPage(dataPerPage + 3)} className="default-btn">Load More <i><BiLoaderCircle /></i></Button>
                                </div> : null}

                        </Tab>
                        <Tab eventKey="Hypno-Gastric Band Therapy" title="Hypno-Gastric Band Therapy">
                            <div className="row justify-content-center">
                                {blogs?.filter(data => data.category_id === "Hypno-Gastric Band Therapy").slice(0, dataPerPage).map((blog) => {
                                    return (
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-blog">
                                                <div className="blog-image">
                                                    <Link to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`}><img src={`${api_url}/assets/blogs/${blog.image}`} alt="image" /></Link>
                                                    {/* , month: 'short' */}
                                                    <div className="tag">{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })}th <span>{new Date(blog.created_at).toLocaleDateString('en-En', { month: 'short' })}</span></div>
                                                </div>

                                                <div className="blog-content">
                                                    <h3>
                                                        <Link to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`}>{blog.title}</Link>
                                                    </h3>
                                                    <div className="blog-btn">
                                                        <Link to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {blogs?.filter(data => data.category_id === "Hypno-Gastric Band Therapy").length > dataPerPage ?
                        <div className="book-store-btn">
                            <Button onClick={() => setDataPerPage(dataPerPage + 3)} className="default-btn">Load More <i><BiLoaderCircle /></i></Button>
                        </div> : null}
                        </Tab>
                        <Tab eventKey="Hypnotherapy" title="Hypnotherapy">
                            <div className="row justify-content-center">
                                {blogs?.filter(data => data.category_id === "Hypnotherapy").slice(0, dataPerPage).map((blog) => {
                                    return (
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-blog">
                                                <div className="blog-image">
                                                    <Link to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`}><img src={`${api_url}/assets/blogs/${blog.image}`} alt="image" /></Link>
                                                    {/* , month: 'short' */}
                                                    <div className="tag">{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })}th <span>{new Date(blog.created_at).toLocaleDateString('en-En', { month: 'short' })}</span></div>
                                                </div>

                                                <div className="blog-content">
                                                    <h3>
                                                        <Link to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`}>{blog.title}</Link>
                                                    </h3>
                                                    <div className="blog-btn">
                                                        <Link to={`/blog/${blog.id}/${blog.title.replace(/\s+/g, '-')}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {blogs?.filter(data => data.category_id === "Hypnotherapy").length > dataPerPage ?
                        <div className="book-store-btn">
                            <Button onClick={() => setDataPerPage(dataPerPage + 3)} className="default-btn">Load More <i><BiLoaderCircle /></i></Button>
                        </div> : null}
                        </Tab>

                    </Tabs>

                    {/* <div className="row justify-content-center">
                        {blogs?.slice(0, dataPerPage).map((blog) => {
                            return (
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-blog">
                                        <div className="blog-image">
                                            <Link to={`/blog/${blog.id}`}><img src={`assets/blogs/${blog.image}`} alt="image" /></Link>
                                           
                                            <div className="tag">{new Date(blog.created_at).toLocaleDateString('en-En', { day: 'numeric' })}th <span>{new Date(blog.created_at).toLocaleDateString('en-En', { month: 'short' })}</span></div>
                                        </div>

                                        <div className="blog-content">
                                            <h3>
                                                <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                                            </h3>
                                            <div className="blog-btn">
                                                <Link to={`/blog/${blog.id}`} className="default-btn">Read More <i><FaPlus /></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}

                    {/* {blogs?.length > dataPerPage ?
                        <div className="book-store-btn">
                            <Button onClick={() => setDataPerPage(dataPerPage + 3)} className="default-btn">Load More <i><BiLoaderCircle /></i></Button>
                        </div> : null} */}

                </div>
            </section>

        </>
    )
}

export default Blog