import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bannerNew from '../assets/banner.png'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import HTMLRenderer from 'react-html-renderer'
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";
import { Button } from 'react-bootstrap';
import { BsFillHandIndexThumbFill } from 'react-icons/bs';

const ThePathwayControlSystem = () => {

  const api_url = process.env.REACT_APP_API_URL;

  const [controlSystem, setControlSystem] = useState([]);
  const [controlIssues, setControlIssues] = useState([])
  // const [testimonial, setTestimonial] = useState([])
  const [banner, setBanner] = useState('')
  const [pending, setPending] = useState(false)

  const [seoContent, setSeoContent] = useState([]);
  const [seoDes, setSeoDes] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [robot, setRobot] = useState('');
  const [seoScript, setSeoScript] = useState('');
  const [seoKeyword, setSeoKeyword] = useState('');
  const [canonical, setCanonical] = useState('');
  const navigate = useNavigate();
  const [featureImage, setFeatureImage] = useState('');
  const getData = () => {
    axios.get(`${api_url}/getBanner/4`).then((response) => {

      setBanner(response.data.data[0].image)
      // setHeroData(response.data.data)
    }).catch((err) => {
      console.log(err)
    });

    axios.get(`${api_url}/getControlsystem`).then((response) => {

      setControlSystem(response.data.data);
      console.log(response.data.data)
      setPending(true)
    }).catch((err) => {
      console.log(err)
    });

    axios.get(`${api_url}/getseo/3`).then((response) => {
      setSeoContent(response.data.data);
      console.log(response.data.data);
    }).catch((err) => {
      console.log(err);
    })


    axios.get(`${api_url}/getControlIssues`).then((response) => {

      setControlIssues(response.data.data);
    }).catch((err) => {
      console.log(err)
    });

    // axios.get('/getTestimonial').then((response) => {

    //   setTestimonial(response.data.data);
    // }).catch((err) => {
    //   console.log(err)
    // });


    // axios.get('/getAllProduct').then((response) => {
    //     console.log(response.data.data)
    //     setProduct(response.data.data);
    // }).catch((err) => {
    //     console.log(err)
    // });

    // axios.get('/getClient').then((response) => {
    //     console.log(response.data.data)
    //     setClients(response.data.data);
    // }).catch((err) => {
    //     console.log(err)
    // });

    // axios.get('/getAllBlog').then((response) => {
    //     console.log(response.data.data)
    //     setBlogs(response.data.data);
    // }).catch((err) => {
    //     console.log(err)
    // });

  };

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
    if (window.location.hash.includes('##')) {
      navigate('/');
    }
  }, [])

  const handleSEO = () => {
    seoContent?.map((seo) => {

      setRobot(seo.robot);
      setSeoTitle(seo.seo_title);
      setSeoKeyword(seo.seo_keyword)
      setSeoDes(seo.seo_description);
      setCanonical(seo.canonical)
      setSeoScript(seo.script);
      setFeatureImage(seo.feature_image);
    })
  }

  useEffect(() => {
    handleSEO();
  }, [seoContent]);




  return (
    <>
      <Helmet>
      <link rel="canonical" href={canonical?.replace(/\s/g, '').toLowerCase()} />
                <title>{seoTitle}</title>
                <meta name="description" content={seoDes} />
                <meta property="og:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDes} />
                <meta property="og:image" content={featureImage} />
                <meta name="robots" content={robot} />
                <meta name="twitter:url" content={canonical?.replace(/\s/g, '').toLowerCase()} />
                <meta name="twitter:title" content={seoTitle} />
                <meta name="twitter:description" content={seoDes} />
                <meta name="twitter:image" content={featureImage}></meta>
                <script>{seoScript}</script>
      </Helmet>


      {pending ? null :
        <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '9999' }}>
          <Spinner animation="border" variant="dark" className='my_spinner' />
        </div>}


      <div className="page-banner-with-full-image item-bg4" style={{ backgroundImage: `url(/assets/banner/${banner}` }}>
        <div className="container">
          <div className="page-banner-content-two">
            <h2>The Control System</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>The Control System</li>
            </ul>
          </div>
        </div>
      </div>


      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            {controlSystem?.map((data, index) => {

              return (
                <>
                  <div className="col-lg-6">
                    <div className="about-main-image text-sm-center text-md-center text-lg-center">
                      <img src={`assets/controlsystem/${data.image}`} alt="image" />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="about-main-content">
                      {/* <h3>
                        The CONTROL System UK
                      </h3> */}
                      <p>
                        <HTMLRenderer html={data.content} />
                        {/* {data.content} */}
                      </p>
                      <div className="about-btn">
                        <Button className="contact_btn default-btn"><Link to="/contact"><h2>Contact Us</h2><i><BsFillHandIndexThumbFill /></i></Link></Button>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}

          </div>
        </div>

      </section>



      <section className="offer-area"
        style={{ backgroundImage: `url(${bannerNew})` }}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            {controlSystem?.map((data, index) => {

              return (
                <>
                  {/* 12 */}
                  <div className="col-lg-6">
                    {/* m-auto */}
                    <div className="offer-item">
                      <div className="content">
                      </div>
                      {/* <h4 style={{ color: '#fff' }} className="sub-title">What can I use CONTROL for
                      </h4> */}
                      <p style={{ color: '#fff' }}>
                        <HTMLRenderer html={data.content1} />
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="offer-image">
                      {/* <img className='p-5' src={`assets/hypnosis/${data.image}`} alt="image" /> */}
                      <video className="w-100" src={`assets/controlsystem/${data.video}`} controls />
                    </div>
                  </div>

                  {/* <div className="col-lg-6">
                <div className="offer-image">
                  <img className='p-5' src={`assets/hypnosis/`} alt="image" />
                </div>
              </div> */}
                </>
              )
            })}

          </div>
        </div>
      </section>


      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <>
              <div className="col-lg-12">
                <div className="about-main-content">
                  <div className="about-content-image">
                    <h4 className="sub-title">CONTROL has been used by thousands of people to deal with many different issues.
                      Here are just a few:
                    </h4>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className='about-main-content'>
            <div className='issues_ d-grid' style={{ paddingLeft: '35px' }}>

              {controlIssues?.map((data) => {
                return (
                  <div>
                    <li style={{ listStyleType: 'disclosure-closed' }}>
                      {data.issue}
                    </li>
                  </div>
                )
              })}

            </div>
          </div>
        </div>

      </section>


    </>
  )
}

export default ThePathwayControlSystem