import { React, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import axios from 'axios';
import TextEditor from '../Editor/TextEditor';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddHypnosis = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();
    const [section, setSection] = useState('');
    const [heading, setHeading] = useState('');
    const [description1, setDescription1] = useState('');
    const [pending, setPending] = useState('');
    const [url, setURL] = useState('');
    const [description2, setDescription2] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [actualData, setActualData] = useState([]);
    const [actualData1, setActualData1] = useState([]);
    const [addImage, setAddImage] = useState('');
    const notify = () => toast.info('Hypnosis Add Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true)
        axios.post(`${api_url}/addNewHypnosis`, {
            description1: description1,
            description2: description2,
            heading: heading,
            section: section,
            image: addImage,
            url: url

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                setPending(false);
                notify();
                navigate('/admin/hypnosis')

            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }


    const [file, setFile] = useState();


    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    let validationRules = { Today: { required: true } };
    return (

        <>
            <section id="main-content">
                <section className="wrapper">

                    <div className="row">
                        <div className="col-lg-12">
                            <section className="card cardSectionBorder">
                                <ToastContainer />
                                <header className="card-header cardHeader_section">
                                    <div className="row">
                                        <div className="col-sm-12 text-center"><b>Add Hyponsis</b></div>
                                    </div>
                                </header>
                                <div className="card-body">
                                    <Form className='row' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Section</Form.Label>
                                            <Form.Control type="text"  {...register("section", {
                                                required: true,
                                                onChange: (e) => setSection(e.target.value)
                                            })} />
                                            {errors.section && <div className='errMsg pt-2'>Please Enter Section</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Heading</Form.Label>
                                            <Form.Control type="text" {...register("heading", {
                                                required: true,
                                                onChange: (e) => setHeading(e.target.value)
                                            })} />
                                            {errors.heading && <div className='errMsg pt-2'>Please Enter Heading</div>}
                                        </Form.Group>
                                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control type="text" {...register("url", {
                                                required: false,
                                                onChange: (e) => setURL(e.target.value)
                                            })} />
                                            {errors.url && <div className='errMsg pt-2'>Please Enter URL</div>}
                                        </Form.Group>

                                        <Form.Group className="col-md-6 mb-3">
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control type="file" {...register("image", {
                                                required: true,
                                                onChange: (e) => setAddImage(e.target.files[0])
                                            })} />
                                            {errors.image && <div className='errMsg pt-2'>Please Provide Image</div>}

                                            <div className='text-center p-3'>
                                                <img src={addImage ? URL.createObjectURL(addImage) : ""} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />
                                            </div>

                                        </Form.Group>
                                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Description1</Form.Label>
                                            <TextEditor data={actualData.toString()} setUpdatedContent={setDescription1} validationRules={validationRules} />

                                        </Form.Group>

                                        <Form.Group className="col-md-6 mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Description2</Form.Label>
                                            <TextEditor data={actualData1.toString()} setUpdatedContent={setDescription2} validationRules={validationRules} />
                                        </Form.Group>

                                        <div className='mb-3'>
                                            <Button className="Add_btn_" type="submit">
                                                Add
                                            </Button>
                                        </div>
                                    </Form>
                                </div>

                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default AddHypnosis