import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { Button } from '@mui/material';
import axios from 'axios';
import TextEditor from '../Editor/TextEditor';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
const UpdateHypnosis = () => {

  const api_url = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    const [hyponsis ,setHyponsis] =useState([]);
 
    const [pending, setPending] = useState('');
 
    const [addImage, setAddImage] = useState('');
  
    
    const [updatesection, setUpdatedSection] = useState('');
    const [updatedheading, setUpdatedHeading] = useState('');
    const [updatedImage, setUpdatedImage] = useState('');
    const [updateurl, setUpdateURL] = useState('');
    const [updatedescription1, setUpdateDescription1] = useState('');
    const [updatedescription2, setUpdateDescription2] = useState('');
    const notifyUpdate = () => toast.info('Hypnosis Update Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const getHyponsis = () => {
        axios.get(`${api_url}/singleHypnosis/${id}`).then((response) => {
          setHyponsis(response.data.data);
          
          console.log(response.data.data);
          setPending(false);
        }).catch((err) => {
          setPending(false);
          console.log(err);
        })
        
      }
      useEffect(() => {
        getHyponsis();
      }, []);
      useEffect(() => {

        hyponsis?.map((admin) => {
            setUpdatedSection(admin.section);
            setUpdatedHeading(admin.heading);
            setUpdateDescription1(admin.description1);
            setUpdateDescription2(admin.description2);
            setUpdateURL(admin.url);
            setAddImage(admin.image);
            

        })
    }, [hyponsis]);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);
        const formData = new FormData();
        formData.append("file", updatedImage);
        axios.post(`${api_url}/updateHypnosis`, {
          id: id,
          description1: updatedescription1,
          description2: updatedescription2,
          section:updatesection,
          url:updateurl,
          image:updatedImage,
          heading:updatedheading
        }, {
          method: 'POST',
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          if (response.status === 200) {
            notifyUpdate();
            window.location.reload(true);
    
          }
        }).catch((err) => {
    
          console.log(err);
        })
    
    
      }


    const [file, setFile] = useState();


    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    let validationRules = { Today: { required: true } };
    return (

        <>
            <section id="main-content">
                <section className="wrapper">
               
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="card cardSectionBorder">
                            <ToastContainer />
                                <header className="card-header cardHeader_section">
                                    <div className="row">
                                        <div className="col-sm-12 text-center"><b>Update Hyponsis</b></div>
                                    </div>
                                </header>
                                <div className="card-body">
                                    <Form className='row'   onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="col-md-6 mb-3">
                                            <Form.Label>Section</Form.Label>
                                            <Form.Control value={updatesection}  onChange={(e) => setUpdatedSection(e.target.value)}
                                            
                                           />
                                          
                                        </Form.Group>

                                        <Form.Group className="col-md-6 mb-3">
                                            <Form.Label>Heading</Form.Label>
                                            <Form.Control value={updatedheading} 
                                                onChange={(e) => setUpdatedHeading(e.target.value)} 
                                            />
                                          
                                        </Form.Group>
                                        <Form.Group className="col-md-6 mb-3" >
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control  value={updateurl} 
                                                onChange={(e) => setUpdateURL(e.target.value)} 
                                           />
                                           
                                        </Form.Group>
                                        
                                        <Form.Group className="mb-3" >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" onChange={(e) => setUpdatedImage(e.target.files[0])}/>

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/hypnosis/${addImage}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />

            </div>


                                        <Form.Group className="col-md-6 mb-3" >
                                            <Form.Label>Description1</Form.Label>
                                            <TextEditor data={hyponsis.map((data) => data.description1).toString()} setUpdatedContent={setUpdateDescription1} validationRules={validationRules} />

                                        </Form.Group>

                                        <Form.Group className="col-md-6 mb-3" >
                                            <Form.Label>Description2</Form.Label>
                                            <TextEditor data={hyponsis.map((data) => data.description2).toString()} setUpdatedContent={setUpdateDescription2} validationRules={validationRules} />
                                        </Form.Group>

                                        <div className='text-center mb-3'>
                                            <Button className="Add_btn_" type="submit" >
                                                Update
                                            </Button>
                                        </div>
                                    </Form>
                                </div>

                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default UpdateHypnosis