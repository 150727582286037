import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import NoImage from '../../../web/components/assets/Image_not_available.png';
const ViewForm2 = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const { id } = useParams();
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get(`${api_url}/getSingleConfidential/${id}`).then((response) => {
            setPending(false);
            setData(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);




    return (
        <>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Confidential</span>
                            <section className="card m-3">

                                <div className="card-body">
                                    <ToastContainer />
                                    <form className="row g-3" autocomplete='off'>
                                        {data?.map((Data) => {
                                            return (
                                                <>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" disabled className="form-control" value={Data.name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" disabled className="form-control" value={Data.email} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Home Phone</label>
                                                        <input type="text" disabled className="form-control" value={Data.home_phone} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Work Phone</label>
                                                        <input type="text" disabled className="form-control" value={Data.work_phone} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Mobile Phone</label>
                                                        <input type="text" disabled className="form-control" value={Data.mobile_phone} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Address</label>
                                                        <input type="text" disabled className="form-control" value={Data.address} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">DOB</label>
                                                        <input type="text" disabled className="form-control" value={Data.dob} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Gender</label>
                                                        <input type="text" disabled className="form-control" value={Data.gender == 1 ? 'Male' : Data.gender == 2 ? 'Female' : Data.gender == 3 ? 'Other' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Marital Status</label>
                                                        <input type="text" disabled className="form-control" value={Data.marital_status == 1 ? 'Single' : Data.marital_status == 2 ? 'Married' : Data.marital_status == 3 ? 'Widowed' : Data.marital_status == 4 ? 'Divorsed' : null} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Date</label>
                                                        <input type="text" disabled className="form-control" value={Data.date} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Age</label>
                                                        <input type="text" disabled className="form-control" value={Data.age} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Mode Of Contact</label>
                                                        <input type="text" disabled className="form-control" value={Data.mode_of_contact} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Occupation</label>
                                                        <input type="text" disabled className="form-control" value={Data.occuption} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Primary Reason or Desired Outcome</label>
                                                        <input type="text" disabled className="form-control" value={Data.primary_reason} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Common Concerns</label>
                                                        <textarea disabled className="form-control">
                                                            {Data.common_concerns}
                                                        </textarea>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">The Pathway Hypnotherapy Rooms</label>
                                                        <input type="text" disabled className="form-control" value={Data.hypnotherapy_rooms} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Changes</label>
                                                        <input type="text" disabled className="form-control" value={Data.change_msg} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Name</label>
                                                        <input type="text" disabled className="form-control" value={Data.client_name} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Remedial Hypnotist Agreement</label>
                                                        <input type="text" disabled className="form-control" value={Data.hypnotist_agreement} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Help</label>
                                                        <input type="text" disabled className="form-control" value={Data.help} />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label className="form-label">Amount</label>
                                                        <input type="text" disabled className="form-control" value={Data.amount} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Transaction Id</label>
                                                        <input type="text" disabled className="form-control" value={Data.tnx_id} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Refund Status</label>
                                                        <input type="text" disabled className="form-control" value={Data.refund_status == 1 ? 'Initiate' : Data.refund_status == 2 ? 'Complete' : 'Pending'} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Client Signature</label>
                                                        <img src={Data.client_signature ? `assets/signatures/${Data.client_signature}` : NoImage} alt="image" width={400} height={100} />

                                                    </div>

                                                </>
                                            )
                                        })}

                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default ViewForm2;